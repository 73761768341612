<template>
  <div>
    <div class="py-4">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="field in filteredFields"
            :key="field.name"
            cols="12"
            sm="12"
            :md="field.col"
            class="py-1"
          >
            <DialogInput
              :field="field"
              :data="data"
              :detail="detail"
            />
          </v-col>
        </v-row>
        <v-col v-if="warnings.length">
          <div
            v-for="warning in warnings"
            :key="warning"
            class="my-2 d-flex align-center"
          >
            <v-icon
              size="20"
              color="red"
            >
              mdi-alert
            </v-icon>
            <span class="warning-message ml-2">{{ warning }}</span>
          </div>
        </v-col>
      </v-container>
    </div>
  </div>
</template>

<script>
import DialogInput from '../../Forms/Inputs/DialogInput'
import get from 'lodash/get'

export default {
  components: {
    DialogInput
  },
  props: {
    detail: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: false
    }
  },
  computed: {
    filteredFields() {
      return this.detail.fields
        .filter((field) => !field.condition || field.condition(this.data))
    },
    warnings() {
      const warnings = []
      this.detail?.conditionalWarnings?.forEach((warning) => {
        const targetA = get(this.data, warning.targetA)
        const targetB = get(this.data, warning.targetB)
        if (!targetA || !targetB) return
        switch (warning.condition) {
          case 'isNotEqual':
            if (targetA !== targetB) {
              warnings.push(warning.errorMessage)
            }
            break
        }
      })
      return warnings
    },
  },
}
</script>
