<template>
  <div class="details-attachments">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="details-attachments__row"
    >
      <div class="d-flex">
        <div class="details-attachments__icon">
          <Icon name="attachment" />
        </div>
        <div class="details-attachments__content">
          <div class="details-attachments__name">
            {{ item.filename }}
          </div>
          <div class="details-attachments__meta">
            {{ formatValue(item.size, 'fileSize') }}
          </div>
        </div>
      </div>
      <div class="d-flex">
        <v-btn
          class="mx-1"
          icon
          small
          @click="callConfirmDeleteDialog(item)"
        >
          <Icon name="usun" />
        </v-btn>
        <v-btn
          class="mx-1"
          icon
          small
          @click="downloadFile(item.url, item.filename, '_blank')"
        >
          <Icon name="pobierz" />
        </v-btn>
        <v-btn
          class="mx-1"
          icon
          small
          @click="callConfirmSendDialog(item)"
        >
          <v-icon color="#252D3C">
            mdi-email-arrow-right-outline
          </v-icon>
        </v-btn>
      </div>
    </div>
    <ActionButtons
      small
      :actions="actions"
      :item="data"
      class="py-3"
    />
  </div>
</template>

<script>
import ActionButtons from '../../Elements/ActionButtons'
import actions from '../../../const/actions'
import { downloadFile } from '../../../utils'
import { formatValue } from '../../../utils/typesEnum'
import { mapActions } from 'vuex'

export default {
  components: {
    ActionButtons
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    actions () {
      return [
        { ...actions.addOrderFile, id: this.data.id },
        { ...actions.generateOrderKwu },
      ]
    }
  },
  methods: {
    ...mapActions({
      setDialog: 'layout/setDialog',
      sendFile: 'order/sendFile',
    }),
    callConfirmSendDialog (file) {
      this.setDialog({
        data: {
          component: 'SendFileToContacts',
          text: 'Wyślij plik',
        },
        item: this.data,
        file,
      })
    },
    downloadFile (url, filename, target) {
      return downloadFile(url, filename, target)
    },
    formatValue,
    callConfirmDeleteDialog (item) {
      this.setDialog({
        data: { component: 'ConfirmDeleteDialog', text: 'Usuń plik', deleteFileType: 'attachment', tableName: 'orders' },
        item
      })
    }
  }
}
</script>
