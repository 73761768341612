<script>
import mapValues from 'lodash/mapValues'
import groupBy from 'lodash/groupBy'
import { gmapApi } from 'gmap-vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { readJSON, isFilterActive } from '../utils'
import { mapOptions } from '../helpers/mapOptions'

export default {
  data: () => ({
    mapTopHeight: 72,
    mapHeight: window.innerHeight,
    mapOptions,
    zoomFactor: 0.003,
    configured: false,
  }),
  computed: {
    ...mapState({
      department: state => state.core.department,
      areResourcesBeingLoaded: state => state.core.isLoading,
    }),
    ...mapGetters({
      getTableParameters: 'tables/getTableParameters',
    }),
    google: gmapApi,
    isAnyFilterSelected () {
      return this.allFilters?.some(filter => isFilterActive(filter.filterBy))
    },
    isFiltersBarShown () {
      return this.$store.state[this.tableName]?.showFilters
    },
  },
  watch: {
    isAnyFilterSelected () {
      if (!this.firstInit) this.$nextTick(() => { this.resizeMapTop() })
    },
    isFiltersBarShown () {
      if (!this.firstInit) this.$nextTick(() => { this.resizeMapTop() })
    },
  },
  created () {
    this.setTableConfig({ tableName: this.tableName, disableFetch: true })
      .then(() => {
        this.configured = true
        this.resizeMapTop()
      })
  },
  beforeDestroy () {
    this.$refs.mapRef.$mapPromise.then((map) => {
      this.google.maps.event.clearListeners(map, 'zoom_changed')
    })
  },
  methods: {
    ...mapActions({
      setTableConfig: 'tables/setTableConfig',
      setTableFilters: 'tables/setTableFilters',
      setSidebar: 'layout/setSidebar'
    }),
    readJSON (source) {
      return readJSON(source)
    },
    changeFilters ({ filters, tableName }) {
      filters = filters.filter((item) => item.tableName === tableName)
      this.setTableFilters({ filters, tableName })
    },
    resizeMapTop () {
      this.$nextTick(() => {
        this.mapTopHeight = this.$refs.tableTop?.clientHeight
        this.resizeMap()
      })
    },
    resizeMap () {
      const statsHeight = this.$refs.statisticsBar.clientHeight || 0
      this.mapHeight = (window.innerHeight) - this.mapTopHeight - statsHeight
    },
    fitBounds (pins) {
      const markers = []
      for (const pin of pins) {
        markers.push({ lat: pin.order.address.spreadLocation.lat, lng: pin.order.address.spreadLocation.lng })
      }
      if (!markers.length) markers.push(this.department.location)
      const { mapRef } = this.$refs
      mapRef.$mapPromise.then(() => {
        const bounds = new this.google.maps.LatLngBounds()
        for (const m of markers) {
          bounds.extend(new this.google.maps.LatLng(m.lat, m.lng))
        }
        mapRef.fitBounds(bounds)
        if (mapRef.$mapObject.getZoom() > 16) {
          mapRef.$mapObject.setZoom(16)
        }
      })
    },
    generateRadialPositions (count, radius, spreadAngle, startAngle) {
      const span = spreadAngle < 360 ? 1 : 0
      const start = (startAngle * Math.PI) / 180
      const rad = (spreadAngle * Math.PI * 2) / 360 / (count - span)

      return [...Array(count)].map((_, i) => ({
        x: -Math.cos(start + (rad * i)) * radius,
        y: -Math.sin(start + (rad * i)) * radius
      }))
    },
    spreadOverlappingMarkers (markers) {
      const result = []
      const grouped = mapValues(
        groupBy(markers, 'order.address.location.lng', 'order.address.location.lat')
      )

      const groupedMarkers = Object.keys(grouped).map(i => grouped[i])
      groupedMarkers.forEach((group, index) => {
        if (group.length === 1) {
          group[0].order.address.spreadLocation = group[0].order.address.location
          result.push(group[0])
        } else {
          const initialSpots = this.generateRadialPositions(group.length, 0.15, 360, 0)
          group.forEach((marker, i) => {
            const updateLocation = (location, spot) => parseFloat(location) + spot * this.zoomFactor
            const d = marker
            d.order.address.spreadLocation = {
              lat: updateLocation(d.order.address.location.lat, initialSpots[i].x),
              lng: updateLocation(d.order.address.location.lng, initialSpots[i].y)
            }
            d.group = index
            result.push(d)
          })
        }
      })
      return result
    }
  }
}
</script>
