<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Table
      ref="table"
      :table-name="tableName"
      @openDetails="openTableDetails"
      @contextMenu="openContextMenu"
    >
      <template #tableTop>
        <FiltersBar
          ref="tableTop"
          :table-name="tableName"
          search-bar
          date-range-picker
          show-filters-button
          table-filters
        />
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Table from '../../components/Table/Table'
import FiltersBar from '../../components/Filters/FiltersBar'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import webSocketMixin from '../../mixins/webSocketMixin'

export default {
  components: {
    Header,
    Table,
    FiltersBar,
  },
  mixins: [
    defaultTableEventsMixin,
    webSocketMixin
  ],
  data: () => ({
    tableName: 'incidents',
  }),
  channels: {
    IncidentIndexChannel: { received (data) { this.captureChanges(data) } },
  },
  mounted () {
    this.subscribeSocket('IncidentIndexChannel')
  },
  beforeDestroy () {
    this.unsubscribeSocket('IncidentIndexChannel')
  },
  methods: {
    openTableDetails ({ id }) {
      this.$router.push({ name: 'singleIncident', params: { id } })
    },
  }
}
</script>
