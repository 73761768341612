<template>
  <v-menu
    v-model="showMenu"
    :position-x="contextMenu.position.x"
    :position-y="contextMenu.position.y"
    absolute
    offset-y
    :close-on-content-click="false"
  >
    <v-list>
      <v-list-item
        v-for="action in contextMenu.actions"
        :key="action.name"
        :disabled="action.disabled || isDialogAlreadyOpen(action.target)"
        dense
        @click.prevent="performAction(action)"
      >
        <v-list-item-content>
          <v-list-item-title v-text="action.text" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import quickActionsMixin from '../../mixins/quickActionsMixin'

export default {
  mixins: [quickActionsMixin], // triggerAction, isDialogAlreadyOpen
  computed: {
    ...mapState({
      contextMenu: state => state.layout.contextMenu
    }),
    showMenu: {
      get () {
        return this.contextMenu.active
      },
      set (newValue) {
        if (!newValue) this.closeContextMenu()
      }
    }
  },
  methods: {
    ...mapActions({
      closeContextMenu: 'layout/closeContextMenu'
    }),
    performAction (action) {
      this.triggerAction({ action, item: this.contextMenu.item })
      this.showMenu = false
    }
  }
}
</script>
