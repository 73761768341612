<template>
  <div class="details-attachments">
    <div
      v-if="data.bdoFile && data.bdoFile.id"
      class="details-attachments__row"
    >
      <div class="d-flex">
        <div class="details-attachments__icon">
          <Icon name="attachment" />
        </div>
        <div class="details-attachments__content">
          <div class="details-attachments__name">
            {{ data.bdoFile.filename }}
          </div>
          <div class="details-attachments__meta">
            {{ formatValue(data.bdoFile.size, 'fileSize') }}
          </div>
        </div>
      </div>
      <div class="d-flex">
        <v-btn
          v-if="data.bdoCardId"
          class="mx-1"
          icon
          small
          :to="{name: 'singleBdo', params:{id: data.bdoCardId}}"
          title="Przejdź do karty"
        >
          <v-icon color="#252D3C">
            mdi-arrow-right-bold-box-outline
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-1"
          icon
          small
          @click="unassignCard"
        >
          <v-icon color="#252D3C">
            mdi-pin-off-outline
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-1"
          icon
          small
          @click="downloadFile(data.bdoFile.url, data.bdoFile.filename, '_blank')"
        >
          <Icon name="pobierz" />
        </v-btn>
      </div>
    </div>
    <div
      v-else
      class="py-3 px-6 details-attachments__name"
    >
      Brak przypiętej karty
    </div>
  </div>
</template>

<script>
import actions from '../../../const/actions'
import { downloadFile } from '../../../utils'
import { formatValue } from '../../../utils/typesEnum'
import { mapActions } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    actions () {
      return [{ ...actions.addBDOFile, id: this.data.id }]
    }
  },
  methods: {
    ...mapActions({
      unassignKpoCard: 'course/unassignKpoCard',
      setDialog: 'layout/setDialog',
    }),
    unassignCard() {
      if (this.data.bdoCardId) {
        this.unassignKpoCard({ item: this.data })
      } else {
        this.setDialog({
          data: { component: 'ConfirmDeleteDialog', text: 'Usuń plik oświadczenia', tableName: 'orders', deleteFileType: 'kpo', orderId: this.data.id },
          item: this.data.bdoFile
        })
      }
    },
    downloadFile (url, filename, target) {
      return downloadFile(url, filename, target)
    },
    formatValue,
  }
}
</script>
