<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Table
      ref="table"
      :key="tableName"
      :table-name="tableName"
      @openDetails="openTableDetails"
      @contextMenu="openContextMenu"
    >
      <template #tableTop>
        <FiltersBar
          ref="tableTop"
          :table-name="tableName"
          search-bar
          :search-bar-props="{class: 'mr-4'}"
          show-filters-button
          table-filters
        />
      </template>
      <template #stats>
        <TableStatisticsBar
          ref="statistics"
          :table-name="tableName"
        />
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../../components/Layout/Header'
import Table from '../../../components/Table/Table'
import FiltersBar from '../../../components/Filters/FiltersBar'
import TableStatisticsBar from '../../../components/Table/TableStatisticsBar'
import defaultTableEventsMixin from '../../../mixins/defaultTableEventsMixin'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    Header,
    Table,
    FiltersBar,
    TableStatisticsBar,
  },
  mixins: [defaultTableEventsMixin],
  data: () => ({
    tableName: 'containers',
  }),
  computed: {
    ...mapState({
      departmentId: state => state.core.department.id
    }),
    ...mapGetters({
      getFilterValue: 'tables/getFilterValue'
    }),
    containerTypeFilter () {
      return this.getFilterValue(this.tableName, 'containerTypeId')
    }
  },
  watch: {
    containerTypeFilter () {
      this.getCounters()
    }
  },
  beforeRouteEnter (to, from, next) {
    // Containers and ContainerTypes views require global containerTypes
    if (from.name !== 'containerTypes') { next(vm => vm.getContainerTypes()) } else next()
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'containerTypes') this.getContainerTypes({ params: { departmentId: this.departmentId } })
    next()
  },
  methods: {
    ...mapActions({
      getContainerTypes: 'containerTypes/getItems',
      getCounters: 'containers/getCounters',
    }),
  }
}
</script>
