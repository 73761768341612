<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Table
      ref="table"
      :table-name="tableName"
      disable-pagination
      @openDetails="openTableDetails"
      @contextMenu="openContextMenu"
    >
      <template #tableTop>
        <div
          class="filters"
          ref="tableTop"
        >
          <div class="filters__inputs">
            <SearchBar :table-name="tableName" />
          </div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../../components/Layout/Header'
import Table from '../../../components/Table/Table'
import SearchBar from '../../../components/Filters/SearchBar'
import defaultTableEventsMixin from '../../../mixins/defaultTableEventsMixin'

export default {
  components: {
    Header,
    Table,
    SearchBar,
  },
  mixins: [defaultTableEventsMixin],
  data: () => ({
    tableName: 'users',
  })
}
</script>
