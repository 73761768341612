<template>
  <v-switch
    :input-value="isAnyItemSelected"
    @change="toggleAllItemsSelection"
    hide-details
    :disabled="isListEmpty"
    dense
    class="ma-0"
    title="Przełącz wszystkie"
    v-shortkey="toggleAllShortkeys"
    @shortkey.native="toggleAllItemsSelection"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { toggleAllShortkeys } from '../../../const/shortKeys'
import { getExecutiveMultiselectTableName } from '../../../utils'

export default {
  props: {
    tableName: {
      type: String,
      required: true
    }
  },
  data: () => ({
    toggleAllShortkeys
  }),
  computed: {
    isListEmpty () {
      return !this.$store.state[this.tableName].items?.length
    },
    isAnyItemSelected () {
      return !!this.$store.state[this.tableName]?.selectedItems?.length
    },
    executiveTableName () {
      return getExecutiveMultiselectTableName(this.tableName)
    }
  },
  beforeDestroy () {
    if (this.isAnyItemSelected) this.toggleAllItemsSelection()
  },
  methods: {
    ...mapActions({
      toggleAllItemsSelection: function (dispatch) {
        return dispatch(`${this.executiveTableName}/toggleAllItemsSelection`, this.tableName)
      }
    })
  }

}
</script>
