<template>
  <div class="details details--full mt-80px">
    <DetailsToolbar
      :title="getDetailsTitle"
      :blocked="isBlocked"
      :table-name="tableName"
      :prev-route="prevRoute"
    >
      <v-spacer />
      <ActionButtons
        :actions="getDetailsActions"
        :item="orderTemplate"
      />
    </DetailsToolbar>
    <div class="px-4">
      <v-row>
        <v-col
          v-for="column in ['left', 'right', 'bottom']"
          :key="column"
          cols="12"
          :md="detailsColumnSize(column)"
        >
          <div
            v-for="detail in details[column]"
            :key="detail.name"
            class="detail-section__wrapper mb-4"
          >
            <div
              class="detail-section__header"
              v-if="detail.text"
            >
              {{ detail.text }}
            </div>
            <div :class="{'detail-section__content': detail.text}">
              <component
                :is="detail.component || 'DetailsSection'"
                :detail="detail"
                :data="orderTemplate"
                :list="nestedSection(detail.multiple)"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import webSocketMixin from '../../mixins/webSocketMixin'
import navigateBackMixin from '../../mixins/navigateBackMixin'
import singleViewMixin from '../../mixins/singleViewMixin'
import { mapState, mapActions } from 'vuex'

export default {
  mixins: [
    webSocketMixin,
    navigateBackMixin, // prevRoute,
    singleViewMixin, // ActionButtons, DetailsToolbar, DetailsSection, DetailsSectionList, getDetailsActions,
    // getDetailsTitle, isBlocked, details, configured, nestedSection, detailsColumnSize, setDetails
  ],
  data: () => ({
    tableName: 'orderTemplates'
  }),
  provide () {
    return {
      notWebSocketHandled: false
    }
  },
  channels: {
    OrderTemplateShowChannel: { received (data) { this.captureSingleChange(data) } }
  },
  computed: {
    ...mapState({
      orderTemplate: state => state.orderTemplate.entity, // webSocketMixin purpose
      entity: state => state.orderTemplate.entity // singleViewMixin purpose
    }),
  },
  mounted () {
    const { id } = this.$route.params
    this.getSingleOrderTemplate(id)
      .then(() => {
        this.setDetails()
      })
    this.subscribeSocket('OrderTemplateShowChannel', { orderTemplateId: id })
  },
  beforeDestroy () {
    this.unsubscribeSocket('OrderTemplateShowChannel')
    this.clearSingleOrderTemplate()
  },
  methods: {
    ...mapActions({
      clearSingleOrderTemplate: 'orderTemplate/clearSingleOrderTemplate',
      getSingleOrderTemplate: 'orderTemplate/getSingleOrderTemplate'
    }),
  }
}
</script>
