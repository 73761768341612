<template>
  <div class="details-courses my-4">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="details__row--multiple"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="field in detail.fields"
            :key="field.name"
            cols="12"
            sm="12"
            :md="field.col"
            class="py-1"
          >
            <DialogInput
              :field="field"
              :multiple="item"
              :detail="detail"
            />
          </v-col>
          <v-col
            cols="3"
            class="pb-0 pl-6"
          >
            <v-btn
              small
              outlined
              color="primary"
              :disabled="isProcessing"
              :loading="isLoading.includes(item.id)"
              @click="generateKwu({id: item.id})"
            >
              Generuj KWU
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            class="pb-0 pl-6 custom-form"
          >
            <v-text-field
              outlined
              label="Wystaw KWU na osobę zewnętrzną"
              v-model="contactNames[index]"
              validate-on-blur
              hide-details
              dense
              class="hide-margin pa-0"
              placeholder="Wpisz imię i nazwisko"
              :disabled="isLoading.includes(item.id)"
            >
              <template #append>
                <v-btn
                  class="mt-n1"
                  icon
                  small
                  :disabled="!contactNames[index]"
                  :loading="isLoading.includes(item.id)"
                  @click="generateKwu({id: item.id, index})"
                >
                  <v-icon>mdi-file-image-plus-outline</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import DialogInput from '../../Forms/Inputs/DialogInput'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogInput
  },
  props: {
    detail: {
      type: Object,
      required: true
    },
    list: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    isLoading: [],
    contactNames: [],
  }),
  computed: {
    ...mapState({
      isProcessing: state => state.course.isProcessing,
      controlName: state => state.order.entity?.client?.controlName,
    })
  },
  mounted() {
    this.contactNames = new Array(this.list.length).fill(this.controlNames)
  },
  methods: {
    ...mapActions({
      generateKwu: function (dispatch, { id, index }) {
        this.isLoading.push(id)
        dispatch('course/generateKwu', { id, contactName: this.contactNames[index] })
          .then(() => {
            this.contactNames[index] = null
          })
          .finally(() => {
            this.isLoading = this.isLoading.filter(item => item !== id)
          })
      }
    }),
  }
}
</script>

<style lang="scss" scoped>
.v-input.v-text-field.hide-margin::v-deep {
  .v-input__slot {
    margin-bottom:0 !important;
    min-height: 36px;
    height: 36px;
  }
  .v-input__append-inner {
    opacity: 1 !important;
  }
}
</style>
