export default [
  { value: 'departments', text: 'Oddziały' },
  { value: 'drivers', text: 'Kierowcy' },
  { value: 'vehicles', text: 'Pojazdy' },
  { value: 'terminals', text: 'Terminale' },
  { value: 'debrisTypes', text: 'Typy odpadów' },
  { value: 'aggregateTypes', text: 'Typy kruszywa' },
  { value: 'communes', text: 'Gminy' },
  { value: 'businessPlaces', text: 'MPD', noDetails: true },
]
