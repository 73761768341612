<template>
  <div
    :title="printedValue"
    class="w-100"
  >
    <div
      class="details-value"
      :class="['details-value--' + field.type, field.extraClass]"
    >
      <div
        class="input-label px-2"
        v-if="field.text"
      >
        {{ field.text }}
        <v-btn
          icon
          x-small
          color="primary"
          title="Skopiuj wartość"
          :disabled="printedValue === '-'"
          @click.stop="copyValue"
        >
          <v-icon x-small>
            mdi-content-copy
          </v-icon>
        </v-btn>
        <v-btn
          v-if="field.to && value !== '-'"
          icon
          x-small
          color="primary"
          :title="`Przejdź do ${shortenValue}`"
          :disabled="printedValue === '-' || !linkId"
          :to="link"
        >
          <v-icon small>
            mdi-arrow-right-bold-box
          </v-icon>
        </v-btn>
      </div>
      <v-btn
        class="details-value__btn"
        :disabled="disabled"
        text
        @click="update"
      >
        <span
          class="input-value"
          :class="[statusClass]"
        >
          <Icon
            v-if="field.icon"
            :name="field.icon"
            class="mr-2"
          />
          <span v-html="printedValue" />
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>

import get from 'lodash/get'
import { mapState, mapActions } from 'vuex'
import { statusColorName } from '../../../utils/recordStatuses'
import { dateValues, priceValues, booleanValues, percentageValues, dateWithTimeValues, formatValue } from '../../../utils/typesEnum'

export default {
  inject: ['notWebSocketHandled'],
  props: {
    field: {
      type: Object,
      required: true
    },
    detail: {
      type: Object,
      required: true
    },
    multiple: {
      type: Object,
      required: false
    },
    data: {
      type: Object,
      required: false
    }
  },
  computed: {
    ...mapState({
      sidebar: state => state.layout.sidebar
    }),
    entity () {
      return this.multiple || this.data || this.sidebar.data
    },
    value() {
      const parsedValue = []
      if (typeof this.field.value === 'string') {
        return get(this.entity, this.field.value) ?? '-'
      } else if (Array.isArray(this.field.value)) {
        this.field.value.forEach((val) => {
          const value = get(this.entity, val)
          parsedValue.push(value)
        })
      }
      return parsedValue.join(', ') || '-'
    },
    shortenValue () {
      const { printedValue } = this
      return printedValue.length > 30 ? printedValue.substring(0, 30).trim() + '...' : printedValue
    },
    statusClass () {
      return this.field.name === 'courseStatus'
        ? `select-status select-status--${statusColorName[this.value]}` : ''
    },
    editable () {
      const { editable } = this.field || {}
      if (typeof editable === 'object') {
        return get(this.entity, editable.path) === editable.value
      }
      return editable
    },
    disabled () {
      const { editable, field, printedValue } = this
      return (!editable && field.type !== 'textArea') || (!editable && field.type === 'textArea' && printedValue === '-')
    },
    valueType () {
      const { name } = this.field
      if (dateValues.includes(name)) return 'date'
      if (priceValues.includes(name)) return 'price'
      if (booleanValues.includes(name)) return 'boolean'
      if (percentageValues.includes(name)) return 'percentage'
      if (dateWithTimeValues.includes(name)) return 'dateWithTime'
      return name
    },
    printedValue () {
      return this.value === '-' ? '-' : formatValue(this.value, this.valueType)
    },
    linkId () {
      return get(this.entity, this.field.idPath || 'id')
    },
    link () {
      const { to, routeParams } = this.field
      return { name: to, params: { id: this.linkId, ...routeParams } }
    }
  },
  methods: {
    ...mapActions({
      setDialog: 'layout/setDialog'
    }),
    update () {
      const { tableName, endpoint, name, idPath } = this.detail
      this.setDialog({
        data: {
          component: 'UpdateDialog',
          text: this.field.text,
          field: this.field,
          item: this.entity,
          endpoint: endpoint || name,
          tableName: tableName || endpoint || name,
          idPath: idPath || 'id',
          notWebSocketHandled: this.notWebSocketHandled
        }
      })
    },
    copyValue () {
      navigator.clipboard.writeText(this.printedValue)
    },
  }
}
</script>
