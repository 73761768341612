<template>
  <div>
    <Header
      ref="header"
      v-if="tableName"
      :title="$route.meta.title"
      :table-name="tableName"
    >
      <template v-if="tableName !== 'orderSchedules'">
        <v-btn
          v-for="action in multipleActionButtons"
          :key="action.subject"
          v-shortkey="action.shortkey"
          v-bind="getActionButtonProps(action.subject).props"
          v-on="getActionButtonProps(action.subject).events"
        >
          {{ getLabelWithShortkey(action.label, action.shortkey['key']) }}
        </v-btn>
      </template>
    </Header>
    <div class="table-view-wrapper">
      <div>
        <Tabs
          v-if="configured"
          ref="tabs"
          :tabs="tabs"
          persistent
          @goTo="goTo"
        />
        <Table
          v-if="tableName"
          :key="currentTab"
          ref="table"
          :table-name="tableName"
          :set-row-class="highlightRow"
          @selectRow="selectTableRow"
          @showPreview="openPreview"
          @openDetails="openTableDetails"
          @closeDetails="closeTableDetails"
          @contextMenu="openContextMenu"
          @configured="configured = true"
        >
          <template #tableTop>
            <FiltersBar
              ref="tableTop"
              :table-name="tableName"
              search-bar
              :week-days-picker="tableName === 'orderSchedules'"
              :date-range-picker="tableName !== 'orderSchedules'"
              :date-range-picker-props="{hidePresets: sidebar.size > 0}"
              show-filters-button
              table-filters
            />
          </template>
        </Table>
      </div>
      <TableConfirmationBar
        v-if="selectedItems.length"
        :confirm-actions="confirmationBarActions"
        :table-name="tableName"
        ref="confirmation"
      />
    </div>
    <Sidebar v-if="chosenItem">
      <component
        :key="chosenItem.id"
        :is="componentTableDetails"
        :table-name="tableName"
        :data="chosenItem"
        :actions="availableActions"
        @closeDetails="closeTableDetails"
      />
    </Sidebar>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Tabs from '../../components/Layout/Tabs'
import Table from '../../components/Table/Table'
import SingleViewOrder from '../../components/Single/SingleViewOrder'
import SingleView from '../../components/Single/SingleView'
import SinglePreview from '../../components/Single/SinglePreview'
import FiltersBar from '../../components/Filters/FiltersBar'
import Sidebar from '../../components/Layout/Sidebar/Sidebar'
import TableConfirmationBar from '../../components/Table/TableConfirmationBar'
import actions from '../../const/actions'
import { mapState, mapGetters, mapActions } from 'vuex'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import webSocketMixin from '../../mixins/webSocketMixin'
import { getLabelWithShortkey, multiselectShortkeys, exportShortkeys, paymentShortkeys } from '../../const/shortKeys'

const tabs = Object.freeze([
  { value: 'openOrders', tableName: 'orders', text: 'Zlecenia aktywne', filter: { name: 'done', filterBy: false, hide: true } },
  { value: 'ordersCompleted', tableName: 'orders', text: 'Zakończone zlecenia', filter: { name: 'done', filterBy: true, hide: true } },
  { value: 'allOrders', tableName: 'orders', text: 'Wszystkie zlecenia', filter: { name: 'done', filterBy: '', hide: true } },
  { value: 'orderSchedules', tableName: 'orderSchedules', text: 'Harmonogramy' },
])

export default {
  components: {
    Header,
    Tabs,
    Table,
    SingleView,
    SingleViewOrder,
    SinglePreview,
    FiltersBar,
    TableConfirmationBar,
    Sidebar
  },
  mixins: [defaultTableEventsMixin, webSocketMixin],
  data: () => ({
    tableName: 'orders',
    currentTab: 0,
    tabs,
    actions,
    configured: false,
    multiselectSubject: 'generateInvoice',
  }),
  channels: {
    OrderIndexChannel: { received (data) { this.captureChanges(data) } },
    OrderScheduleIndexChannel: { received (data) { this.captureChanges(data) } },
  },
  computed: {
    ...mapState({
      order: state => state.order.entity,
      department: state => state.core.department,
      orderSchedule: state => state.orderSchedule.entity,
      selectedItems: state => state.orders.selectedItems,
      isMultiselectActive: state => state.orders.isMultiselectActive
    }),
    ...mapGetters({
      getFilterValue: 'tables/getFilterValue'
    }),
    clientsFilter () {
      return this.getFilterValue(this.tableName, 'clientIds')
    },
    chosenItem () {
      if (this.order.id && this.tableName !== 'orderSchedules') return this.order
      if (this.orderSchedule.id && this.tableName === 'orderSchedules') return this.orderSchedule
      return {}
    },
    componentTableDetails () {
      if (this.sidebar.size === 1) return 'SinglePreview'
      const component = {
        orders: 'SingleViewOrder',
        orderSchedules: 'SingleView',
        ordersCompleted: 'SingleViewOrder'
      }
      return component[this.tableName]
    },
    multipleActionButtons() {
      return [
        { label: 'Link do płatności', subject: 'generateLink', shortkey: paymentShortkeys },
        { label: 'Faktura zbiorcza', subject: 'generateInvoice', shortkey: multiselectShortkeys },
        { label: 'Exportuj do csv', subject: 'export', shortkey: exportShortkeys },
      ]
    },
    confirmationBarActions () {
      if (this.multiselectSubject === 'generateInvoice') {
        return [actions.generateInvoice]
      } else if (this.multiselectSubject === 'generateLink') {
        return [actions.generatePaymentLink]
      } else if (this.multiselectSubject === 'export') {
        return [actions.exportOrdersToCsv]
      }
      return []
    },
  },
  watch: {
    selectedItems (newValue, oldValue) {
      if (newValue.length !== oldValue.length && (newValue.length === 0 || oldValue.length === 0)) {
        this.$refs.table.resizeTable()
      }
    },
    clientsFilter (clientIds) {
      if (this.tableName === 'orders') {
        const isAddressFilterEnabled = clientIds?.length === 1
        const isAddressFilterActive = !!this.getFilterValue(this.tableName, 'addressIds')?.length
        if (isAddressFilterEnabled) {
          const clientId = clientIds[0].value
          this.getAddresses({ params: { clientId, departmentId: this.department.id, } })
        }
        this.setTableFilters({
          filters: [{ name: 'addressIds', filterBy: '', disabled: !isAddressFilterEnabled, }],
          tableName: this.tableName,
          disableFetch: !isAddressFilterActive
        })
      }
    },
  },
  mounted () {
    this.subscribeSocket('OrderIndexChannel')
  },
  beforeDestroy () {
    this.unsubscribeSocket('OrderIndexChannel')
    this.unsubscribeSocket('OrderScheduleIndexChannel')
  },
  methods: {
    ...mapActions({
      toggleMultiselectStatus: 'orders/toggleMultiselectStatus',
      setSingleOrder: 'order/setSingleOrder',
      getAddresses: 'addresses/getItems',
      getSingleOrder: 'order/getSingleOrder',
      clearSingleOrder: 'order/clearSingleOrder',
      getSingleSchedule: 'orderSchedule/getSingleSchedule',
      clearSingleSchedule: 'orderSchedule/clearSingleSchedule',
      setTableFilters: 'tables/setTableFilters',
      closeSidebar: 'layout/closeSidebar',
    }),
    getLabelWithShortkey,
    toggleMultiselect (subject) {
      const prevSubject = this.multiselectSubject
      if (subject !== this.multiselectSubject) {
        this.multiselectSubject = subject
      }
      if (!this.isMultiselectActive || subject === prevSubject) {
        this.toggleMultiselectStatus(!this.isMultiselectActive)
      }
    },
    goTo (tab) {
      const { value, tableName, filter } = tab
      const prevTableName = this.tableName
      this.currentTab = value
      this.tableName = tableName

      if (filter) {
        this.setTableFilters({ filters: [filter], tableName })
      }
      if (tableName !== prevTableName) {
        if (tableName === 'orderSchedules') {
          this.subscribeSocket('OrderScheduleIndexChannel')
          this.unsubscribeSocket('OrderIndexChannel')
        } else {
          this.subscribeSocket('OrderIndexChannel')
          this.unsubscribeSocket('OrderScheduleIndexChannel')
        }
      }
    },
    selectTableRow (data) {
      if (this.tableName !== 'orderSchedules') this.setSingleOrder(data)
    },
    openPreview (data) {
      this.selectTableRow(data)
      if (this.tableName !== 'orderSchedules') this.setSidebar({ size: 1 })
    },
    openTableDetails (data) {
      if (this.tableName === 'orderSchedules') {
        this.$router.push({ name: 'singleOrderSchedule', params: { id: data.id } })
      } else {
        this.$router.push({ name: 'singleOrder', params: { id: data.id, originTable: 'orders' } })
      }
    },
    closeTableDetails () {
      if (this.tableName === 'orderSchedules') {
        this.clearSingleSchedule()
      }
      this.closeSidebar()
    },
    highlightRow(item) {
      if (this.currentTab === 'orderSchedules') return ''
      if (['Zapłacone', 'Zapłacone online'].includes(item.payment.paymentStatus)) return 'paid-order'
      else if (!item.withoutDocuments) return 'unpaid-order'
    },
    getActionButtonProps(subject) {
      return {
        props: {
          class: ['px-4 ml-6', { 'button-active': this.isMultiselectActive && this.multiselectSubject === subject }],
          color: 'primary',
          outlined: true,
        },
        events: {
          shortkey: () => this.toggleMultiselect(subject),
          click: () => this.toggleMultiselect(subject),
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-view-wrapper::v-deep {
  .paid-order  {
    background-color: #fff20030;
  }
  .unpaid-order  {
    background-color: #3db7ff30;
  }
}
</style>
