<template>
  <div
    class="details details--full"
    :class="{'mt-80px' : !data}"
  >
    <DetailsToolbar
      :title="getDetailsTitle"
      :blocked="isBlocked"
      :table-name="tableName"
      :prev-route="prevRoute"
      :is-empty-order="isEmptyOrder"
      @closeDetails="closeSidebar"
    >
      <v-spacer />
      <ActionButtons
        :actions="actions"
        :item="selectedOrder"
      />
    </DetailsToolbar>
    <Tabs
      v-if="orderData.swapOrderId"
      :tabs="tabs"
      @goTo="changeOrder"
      class="pl-4 pt-2 details-tabs"
      ref="tabs"
    />
    <div class="px-4">
      <v-row>
        <v-col
          v-for="column in ['left', 'right', 'bottom']"
          :key="column"
          cols="12"
          :md="detailsColumnSize(column)"
        >
          <div
            v-for="detail in details[column]"
            :key="detail.name"
            class="detail-section__wrapper mb-5"
          >
            <div class="detail-section__header">
              {{ detail.text }}
            </div>
            <div class="detail-section__content">
              <component
                :is="detail.component || 'DetailsSection'"
                :detail="detail"
                :data="selectedOrder"
                :list="nestedSection(detail.multiple)"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'
import { getBlockerValue, getTableDetails, getTitleFormatted, setTableDetailsView } from '../../utils'
import { disableInactiveActions, filterInactiveActions } from '../../utils/quickActions'
import Tabs from '../Layout/Tabs'
import ActionButtons from '../Elements/ActionButtons'
import DetailsToolbar from './Sections/DetailsToolbar'
import DetailsSection from './Sections/DetailsSection'
import DetailsSectionCourses from './Sections/DetailsSectionCourses'
import DetailsSectionList from './Sections/DetailsSectionList'
import DetailsSectionFiles from './Sections/DetailsSectionFiles'
import DetailsSectionReports from './Sections/DetailsSectionReports'
import DetailsSectionKPO from './Sections/DetailsSectionKPO'
import DetailsSectionOperationHistory from './Sections/DetailsSectionOperationHistory'
import DetailsSectionPaymentByLink from './Sections/DetailsSectionPaymentByLink'
import { mapActions, mapState } from 'vuex'
import webSocketMixin from '../../mixins/webSocketMixin'
import navigateBackMixin from '../../mixins/navigateBackMixin'

export default {
  components: {
    Tabs,
    ActionButtons,
    DetailsToolbar,
    DetailsSection,
    DetailsSectionCourses,
    DetailsSectionList,
    DetailsSectionFiles,
    DetailsSectionReports,
    DetailsSectionKPO,
    DetailsSectionPaymentByLink,
    DetailsSectionOperationHistory
  },
  mixins: [
    webSocketMixin,
    navigateBackMixin // prevRoute
  ],
  provide: {
    notWebSocketHandled: false
  },
  props: {
    tableName: {
      type: String,
      default: 'orders'
    },
    data: {
      type: Object,
      required: false
    },
  },
  channels: {
    OrderShowChannel: { received (data) { this.captureSingleChange(data) } }
  },
  data: () => ({
    details: {},
    currentTab: 0
  }),
  computed: {
    ...mapState({
      order: state => state.order.entity,
      swapOrder: state => state.order.swapOrder,
    }),
    orderData () {
      return this.data?.order || this.order
    },
    selectedOrder () {
      if (this.isEmptyOrder) {
        return this.emptyOrderData
      } else {
        const order = [this.orderData, this.swapOrder].find((order) => this.currentTab === order.id)
        return order || { id: this.currentTab }
      }
    },
    getDetailsTitle () {
      if (this.orderData.id === -1) return 'Zabranie dowolnego kontenera'
      const title = getTableDetails(this.tableName).title
      return getTitleFormatted(title, this.orderData)
    },
    tabs () {
      const swapOrderText = this.orderData.swapOrderId === -1 ? 'Zabranie dowolnego kontenera' : 'Poprzednie zlecenie'
      return [
        { value: this.orderData.id, text: 'Aktualne zlecenie' },
        { value: this.orderData.swapOrderId, text: swapOrderText }
      ]
    },
    isBlocked () {
      return getBlockerValue(this.orderData, 'order')
    },
    isEmptyOrder () {
      return this.currentTab === -1 || !this.data?.order?.id === -1
    },
    emptyOrderData () {
      if (this.$route.name === 'orders') return this.orderData?.swapCourse
      return this.orderData?.swapCourse || this.data
    },
    actions () {
      const { actions } = getTableDetails(this.tableName) || {}
      return filterInactiveActions(actions, this.orderData, this.tableName)
    },
    originTableName () {
      return this.$route.params.originTable || 'courses'
    }
  },
  mounted () {
    const { id: orderId } = this.$route.params
    if (orderId) {
      this.subscribeSocket('OrderShowChannel', { orderId })
      this.getSingleOrder(orderId)
        .then(() => {
          this.currentTab = this.orderData.id
          this.details = setTableDetailsView(this.tableName, this.isEmptyOrder, this.order)
        })
    } else {
      this.currentTab = this.orderData.id
      this.details = setTableDetailsView(this.tableName, this.isEmptyOrder, this.order)
    }
  },
  beforeDestroy () {
    this.clearSingleOrder()
    this.unsubscribeSocket('OrderShowChannel')
  },
  methods: {
    ...mapActions({
      getSwapOrder: 'order/getSwapOrder',
      getSingleOrder: 'order/getSingleOrder',
      clearSingleOrder: 'order/clearSingleOrder',
      closeSidebar: 'layout/closeSidebar',
    }),
    nestedSection (path) {
      return path ? get(this.selectedOrder, path, []) : []
    },
    detailsColumnSize (col) {
      const column = {
        left: '8',
        right: '4',
        bottom: '12'
      }
      return column[col]
    },
    changeOrder (tab) {
      const { value: orderId } = tab
      this.unsubscribeSocket('OrderShowChannel')
      const emptyOrderId = -1
      if (orderId !== emptyOrderId && orderId !== this.order.id) {
        const isSwapOrderRequired = (this.tabs[1].value && !this.swapOrder) ||
          this.swapOrder.id !== this.tabs[1].value

        if (isSwapOrderRequired) this.getSwapOrder(orderId)
        this.subscribeSocket('OrderShowChannel', { orderId })
      }
      this.currentTab = orderId
      this.details = setTableDetailsView(this.tableName, this.isEmptyOrder, this.order)
    },
    filteredActions (actions, status) {
      return disableInactiveActions(actions, status ? 'BlockedClient' : 'UnblockedClient')
    },
  }
}
</script>
