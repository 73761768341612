import isLogisticsApp from './isLogisticsApp'

const clientMenuItems = [
  {
    text: 'Zamówienia',
    link: '/',
    icon: 'zlecenia',
  },
]

const logisticsMenuItems = [
  {
    text: 'Komunikaty',
    link: '/alerts',
    icon: 'komunikaty'
  },
  {
    text: 'Incydenty',
    link: '/incidents',
    icon: 'incydenty'
  },
  {
    text: 'Kursy',
    link: '/',
    icon: 'kursy'
  },
  {
    text: 'Klienci',
    link: '/clients',
    icon: 'klienci',
    nested: [{ name: 'singleClient' }]
  },
  {
    text: 'Mapa',
    name: 'coursesMap',
    link: '/courses-map',
    icon: 'mapa',
  },
  {
    text: 'Mapa',
    name: 'containersMap',
    link: '/containers-map',
    icon: 'mapa',
  },
  {
    text: 'Karty BDO',
    link: '/bdo',
    icon: 'karty_bdo',
    nested: [{ name: 'singleBdo' }]
  },
  {
    text: 'Zadania',
    link: '/tasks',
    icon: 'zadania',
    notificationsEnabled: true,
  },
  {
    text: 'Zlecenia',
    link: '/orders',
    icon: 'zlecenia',
    nested: [{ name: 'singleOrder' }]
  },
  {
    text: 'Zamówienia',
    link: '',
    icon: 'zamowienia',
    children: [
      {
        text: 'Sklep',
        link: '/order-templates',
        nested: [{ name: 'singleOrderTemplate' }],
      },
      {
        text: 'Aplikacja kliencka',
        link: '/client-app-orders',
        nested: [{ name: 'singleClientAppOrder' }],
      },
    ]
  },
  {
    text: 'Faktury',
    link: '/invoices',
    icon: 'faktury',
    nested: [{ name: 'singleInvoice' }]
  },
  {
    text: 'Rozliczenia',
    link: '/cod-deposits',
    icon: 'rozliczenia'
  },
  {
    text: 'Raporty',
    link: '/reports',
    icon: 'raporty',
    children: [
      {
        text: 'Raporty zamówień',
        link: '/reports',
        nested: [{ name: 'reports' }]
      },
      {
        text: 'Raporty klientów',
        link: '/clients-reports',
        nested: [{ name: 'clientsReports' }]
      },
    ]
  },
  {
    text: 'Kontenery',
    link: '',
    icon: 'kontenery',
    children: [
      {
        text: 'Kontenery',
        link: '/containers',
        nested: [{ name: 'containers' }, { name: 'defaultSingleView', tableName: 'containers' }]
      },
      {
        text: 'Typy kontenerów',
        link: '/container-types',
        nested: [{ name: 'containerTypes' }, { name: 'defaultSingleView', tableName: 'containerTypes' }]
      },
      {
        text: 'Historia kontenerów',
        link: '/containers-history',
        nested: [{ name: 'containersHistory' }]
      },
    ]
  },
  {
    text: 'Administracja',
    link: '',
    icon: 'administracja',
    children: [
      {
        text: 'Użytkownicy',
        link: '/users',
        nested: [{ name: 'defaultSingleView', tableName: 'users' }]
      },
      {
        text: 'Usługi',
        link: '/invoice-item-templates',
        nested: [{ name: 'defaultSingleView', tableName: 'invoiceItemTemplates' }]
      },
      {
        text: 'Magazyn',
        link: '/warehouse',
        nested: [
          { name: 'warehouse' },
          { name: 'defaultSingleView', tableName: 'departments' },
          { name: 'defaultSingleView', tableName: 'drivers' },
          { name: 'defaultSingleView', tableName: 'vehicles' },
          { name: 'defaultSingleView', tableName: 'terminals' },
          { name: 'defaultSingleView', tableName: 'debrisTypes' },
          { name: 'defaultSingleView', tableName: 'aggregateTypes' },
          { name: 'defaultSingleView', tableName: 'communes' },
        ]
      },
      {
        text: 'Cennik',
        link: '/pricing',
        nested: [{ name: 'pricing' }]
      },
      {
        text: 'Prospekty klientów',
        link: '/prospects',
        nested: [{ name: 'singleProspect' }]
      }
    ]
  }
]

export default isLogisticsApp ? logisticsMenuItems : clientMenuItems
