<template>
  <div class="contact-details">
    <div class="detail-section__header contact-details__header">
      Historia połączeń klienta
    </div>

    <v-divider />

    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="callEntries"
      class="details-table"
      :items-per-page="itemsPerPage || 150"
      :loading="isProcessing"
      show-expand
      :expanded.sync="expandedCallEntries"
      @click:row="(item, slot) => expandRow(item, slot)"
      @contextmenu:row.prevent="(event, row) => handleContextMenu(event, row.item)"
    >
      <template #[`header.data-table-expand`]>
        <th>
          <v-switch
            :input-value="!!expandedCallEntries.length"
            @change="toggleAllCallEntries"
            hide-details
            dense
            class="ma-0"
            title="Przełącz wszystkie"
          />
        </th>
      </template>
      <template #[`item.notes`]="{ item }">
        <TableColumnInfo :text="item.notes" />
      </template>
      <template #[`item.data-table-expand`]="{ item }">
        <td>
          <v-btn
            v-if="item.courses && item.courses.length"
            icon
            small
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </td>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            class="expanded-table"
            :headers="expandedRowHeaders"
            :items="item.courses"
            :items-per-page="-1"
            fixed-header
            hide-default-footer
          >
            <template #[`item.dueDate`]="{ item }">
              {{ dateStringFormat(item.dueDate) }}
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>

    <Pagination table-name="callEntries" />
  </div>
</template>

<script>
import { clientCallEntries } from '../../../const/views/administration/clients'
import { dateStringFormat } from '../../../utils'
import Pagination from '../../Layout/Pagination'
import TableColumnInfo from '../../Table/Columns/TableColumnInfo'
import actions from '../../../const/actions'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    clientId: {
      type: Number,
      required: true
    }
  },
  components: {
    Pagination,
    TableColumnInfo
  },
  data: () => ({
    ...clientCallEntries, // { headers, expandedRowHeaders }
    expandedCallEntries: [],
  }),
  computed: {
    ...mapState({
      callEntries: state => state.callEntries.items.map(entry => ({
        ...entry,
        callStart: dateStringFormat(entry.callStart, true),
        fullName: entry.contact && `${entry.contact.firstName} ${entry.contact.lastName}`
      })),
      isProcessing: state => state.callEntries.isProcessing,
      itemsPerPage: state => state.tables.callEntries.pagination?.itemsPerPage,
    }),
  },
  created () {
    this.setTablePagination({ pagination: { itemsPerPage: 20 }, tableName: 'callEntries', disableFetch: true })
    const filters = [{ name: 'clientId', filterBy: this.clientId }]
    this.setTableFilters({ filters, tableName: 'callEntries' })

    this.$store.subscribeAction((action) => {
      if (action.type === 'callEntry/createCallEntry') this.getCallEntries()
    })
  },
  methods: {
    ...mapActions({
      setTableFilters: 'tables/setTableFilters',
      getCallEntries: 'callEntries/getItems',
      setTablePagination: 'tables/setTablePagination',
      setContextMenu: 'layout/setContextMenu',
    }),
    dateStringFormat,
    toggleAllCallEntries (expand) {
      this.expandedCallEntries = expand ? this.callEntries.filter(entry => entry.courses?.length) : []
    },
    expandRow (item, slot) {
      if (item.courses?.length) slot.expand(!slot.isExpanded)
    },
    handleContextMenu (e, item) {
      this.setContextMenu({
        actions: [actions.deleteCallEntry],
        item,
        position: { x: e.clientX, y: e.clientY }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-details {
  background-color: transparent;
  &__header {
    background-color: white;
    border-radius: 6px 6px 0 0
  }
}

.details-table-row::v-deep .table__column--row {
  height: 100%;
  padding: 8px 0;
  text-overflow: clip;
  white-space: normal;
  line-height:18px;
}
.contact-details::v-deep .v-data-table__wrapper {
  max-height: calc(100vh - 605px);
  overflow-y: auto;
  border-radius: 0 0 6px 6px;
}
</style>
