import api from '../api/v1'
import { downloadFile } from '../utils'
import { decamelize } from 'humps'
import { Invoice } from '../models'

export default {
  namespaced: true,
  state: {
    processing: false,
    entity: new Invoice()
  },
  mutations: {
    SET_INVOICE_PROCESSING (state, status) {
      state.processing = status
    },
    SET_SINGLE_INVOICE (state, invoice) {
      state.entity = invoice
    }
  },
  actions: {
    setInvoiceProcessing ({ commit }, status) {
      commit('SET_INVOICE_PROCESSING', status)
    },
    setSingleInvoice ({ commit }, invoice) {
      commit('SET_SINGLE_INVOICE', new Invoice(invoice))
    },
    getSingleInvoice ({ commit }, id) {
      commit('SET_INVOICE_PROCESSING', true)
      return api.getInvoice(id)
        .then((res) => {
          commit('SET_SINGLE_INVOICE', new Invoice(res.data))
        })
        .finally(() => {
          commit('SET_INVOICE_PROCESSING', false)
        })
    },
    getInvoicePreview ({ commit }, id) {
      commit('SET_INVOICE_PROCESSING', true)
      return new Promise((resolve) => {
        api.getInvoicePreview(id)
          .then((res) => {
            resolve(res.data)
          })
          .finally(() => {
            commit('SET_INVOICE_PROCESSING', false)
          })
      })
    },
    getCorrectionPreview ({ commit }, { id, params }) {
      commit('SET_INVOICE_PROCESSING', true)
      return new Promise((resolve) => {
        api.getCorrectionPreview({ id, params })
          .then((res) => {
            resolve(res.data)
          })
          .finally(() => {
            commit('SET_INVOICE_PROCESSING', false)
          })
      })
    },
    correctInvoice ({ commit }, { params, id }) {
      commit('SET_INVOICE_PROCESSING', true)
      return api.correctInvoice({ params, id })
        .finally(() => {
          commit('SET_INVOICE_PROCESSING', false)
        })
    },
    clearSingleInvoice ({ commit }) {
      commit('SET_SINGLE_INVOICE', new Invoice())
    },
    // eslint-disable-next-line no-empty-pattern
    downloadInvoice ({}, { item }) {
      const { url, filename } = item.invoicePdf || {}
      downloadFile(url, filename, '_blank')
    },
    exportOrdersSpreadsheet ({ state, rootState, commit }, { headers, orderIds }) {
      commit('SET_INVOICE_PROCESSING', true)

      const orderHeaders = headers.map(header => decamelize(header))
      const departmentId = rootState.core.department.id

      api.exportOrdersSpreadsheet({
        orderHeaders,
        orderIds,
        departmentId,
      }).then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'text/csv' }))
        const name = state.dateRange ? `Zlecenia ${state.dateRange}` : `Zlecenia faktury ${state.entity.invoiceNumber}`
        downloadFile(url, name)
      })
        .finally(() => {
          commit('SET_INVOICE_PROCESSING', false)
        })
    },
  }
}
