import actions from '../actions'

export default {
  header: [],
  table: {
    sorting: {
      sortBy: 'id',
      sortDesc: true
    },
    filters: [],
    parameters: [
      { name: 'id', text: 'Nr pozycji', value: 'id', show: true },
      { name: 'phoneNumber', text: 'Numer telefonu', value: 'phoneNumber', show: true },
      { name: 'lastNoteDate', text: 'Czas ostatniego połączenia', value: 'lastNoteDate', show: true },
      { name: 'lastNoteText', text: 'Ostatnia notatka', value: 'lastNoteText', show: true },
      { name: 'actions', text: '', value: '', show: true },
    ],
    actions: [
      { ...actions.deleteProspect, icon: 'usun' }
    ],
    menu: []
  },
  details: {
    title: 'Prospekt nr {{id}}, {{phoneNumber}}',
    actions: [
      { ...actions.addProspectNote },
      { ...actions.createClientFromProspect },
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        tableName: 'prospects',
        endpoint: 'prospect_notes',
        type: 'left',
        fields: [
          { name: 'callStart', text: 'Czas rozmowy', value: 'callStart', type: 'textField', col: 3 },
          { name: 'user', text: 'Rozmawiał', value: 'user.fullName', type: 'textField', col: 3 },
          { name: 'text', text: 'Treść', value: 'text', type: 'textArea', editable: true, col: 6 },
        ]
      }
    ]
  }
}
