<template>
  <div
    class="details details--full mt-80px"
    v-if="configured"
  >
    <DetailsToolbar
      :title="getDetailsTitle"
      :blocked="isBlocked"
      :table-name="tableName"
      :prev-route="prevRoute"
    >
      <v-spacer />
      <ActionButtons
        :actions="getDetailsActions"
        :item="entity"
      />
    </DetailsToolbar>
    <div class="px-4">
      <v-row>
        <v-col
          v-for="column in ['left', 'right', 'bottom']"
          :key="column"
          cols="12"
          :md="detailsColumnSize(column)"
        >
          <div
            v-for="detail in details[column]"
            :key="detail.name"
            class="detail-section__wrapper mb-4"
          >
            <div
              class="detail-section__header"
              v-if="detail.text"
            >
              {{ detail.text }}
            </div>
            <div :class="{'detail-section__content': detail.text}">
              <component
                :is="detail.component || 'DetailsSection'"
                :detail="detail"
                :data="entity"
                :list="nestedSection(detail.multiple)"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { getTableOptions } from '../../utils'
import DetailsSectionCourses from './Sections/DetailsSectionCourses'
import DetailsSectionCoursesTable from './Sections/DetailsSectionCoursesTable'
import DetailsSectionMessagesHistory from './Sections/DetailsSectionMessagesHistory'
import DetailsSectionInvoiceItems from './Sections/DetailsSectionInvoiceItems'
import DetailsSectionKPOPdf from './Sections/DetailsSectionKPOPdf'
import DetailsDebrisTypesDepartments from './Sections/DetailsDebrisTypesDepartments'
import { mapState, mapActions } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import singleViewMixin from '../../mixins/singleViewMixin'
import navigateBackMixin from '../../mixins/navigateBackMixin'

export default {
  components: {
    DetailsSectionCourses,
    DetailsSectionCoursesTable,
    DetailsSectionMessagesHistory,
    DetailsSectionInvoiceItems,
    DetailsSectionKPOPdf,
    DetailsDebrisTypesDepartments
  },
  provide () {
    return {
      notWebSocketHandled: true
    }
  },
  mixins: [
    navigateBackMixin, // prevRoute,
    singleViewMixin, // ActionButtons, DetailsToolbar, DetailsSection, DetailsSectionList, getDetailsActions,
    // getDetailsTitle, isBlocked, details, configured, nestedSection, detailsColumnSize, setDetails
  ],
  data: () => ({
    prevRoute: null,
  }),
  computed: {
    ...mapState({
      entity: state => state.singleView.entity, // singleViewMixin purpose
    }),
    tableName () {
      return this.$route.params.tableName
    },
  },
  created () {
    const { tableName, id } = this.$route.params
    const { endpoint } = getTableOptions(this.tableName).specialFlag || {}
    this.getSingleViewEntity({ tableName: endpoint || tableName, id })
      .then(() => {
        this.setDetails()
      })
      .catch(() => {
        if (isEmpty(this.entity)) {
          this.$router.back()
        } else {
          this.setDetails()
        }
      })
  },
  beforeDestroy () {
    this.clearSingleView()
  },
  methods: {
    ...mapActions({
      getSingleViewEntity: 'singleView/getSingleViewEntity',
      clearSingleView: 'singleView/clearSingleViewEntity',
    }),
  }
}
</script>
