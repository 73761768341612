import api from '../api/v1'

export default {
  namespaced: true,
  state: {
    processing: false,
    entity: {}
  },
  mutations: {
    SET_BDO_CARD_PROCESSING (state, status) {
      state.processing = status
    },
    SET_SINGLE_BDO_CARD (state, client) {
      state.entity = client
    }
  },
  actions: {
    getSingleBdoCard ({ commit }, id) {
      commit('SET_BDO_CARD_PROCESSING', true)
      return api.getBdoCard(id)
        .then((resp) => {
          commit('SET_SINGLE_BDO_CARD', resp.data)
        })
        .finally(() => {
          commit('SET_BDO_CARD_PROCESSING', false)
        })
    },
    setSingleBdoCard ({ commit }, bdoCard) {
      commit('SET_SINGLE_BDO_CARD', bdoCard)
    },
    clearSingleBdoCard ({ commit }) {
      commit('SET_SINGLE_BDO_CARD', {})
    },
    setBdoCardProcessing ({ commit }, status) {
      commit('SET_BDO_CARD_PROCESSING', status)
    },
    fetchBdoPdf ({ commit }, id) {
      commit('SET_BDO_CARD_PROCESSING', true)
      return new Promise((resolve, reject) => {
        api.fetchBdoPdf(id)
          .then((resp) => { resolve(resp.data) })
          .catch((err) => { reject(err) })
          .finally(() => {
            commit('SET_BDO_CARD_PROCESSING', false)
          })
      })
    },
    fetchBdoPdfPrint ({ commit }, id) {
      commit('SET_BDO_CARD_PROCESSING', true)
      return new Promise((resolve, reject) => {
        api.fetchBdoPdfPrint(id)
          .then((resp) => { resolve(resp.data) })
          .catch((err) => { reject(err) })
          .finally(() => {
            commit('SET_BDO_CARD_PROCESSING', false)
          })
      })
    },
    rejectBdoCard ({ state, commit }, remarks) {
      commit('SET_BDO_CARD_PROCESSING', true)
      return api.rejectBdoCard(state.entity.id, { remarks })
        .finally(() => {
          commit('SET_BDO_CARD_PROCESSING', false)
        })
    },
    confirmBdoCard ({ state, commit }, params) {
      commit('SET_BDO_CARD_PROCESSING', true)
      return api.confirmBdoCard(state.entity.id, params)
        .finally(() => {
          commit('SET_BDO_CARD_PROCESSING', false)
        })
    },
    reviseBdoCard ({ state, commit, dispatch }, params) {
      commit('SET_BDO_CARD_PROCESSING', true)
      return api.reviseBdoCard(state.entity.id, params)
        .then(() => {
          dispatch('snackbar/showSnackbar', {
            message: ['Wprowadzono korektę'],
          }, { root: true })
        })
        .finally(() => {
          commit('SET_BDO_CARD_PROCESSING', false)
        })
    },
    confirmTransport ({ state, commit, dispatch }) {
      commit('SET_BDO_CARD_PROCESSING', true)
      return api.confirmTransport(state.entity.id)
        .then(() => {
          dispatch('snackbar/showSnackbar', {
            message: ['Potwierdzono transport'],
          }, { root: true })
        })
        .finally(() => {
          commit('SET_BDO_CARD_PROCESSING', false)
        })
    },
    generateKpoConfirmation ({ state, commit, dispatch }, params) {
      commit('SET_BDO_CARD_PROCESSING', true)
      return api.generateConfirmation(state.entity.id, params)
        .then(() => {
          dispatch('snackbar/showSnackbar', {
            message: ['Wygenerowano potwierdzenie'],
          }, { root: true })
        })
        .finally(() => {
          commit('SET_BDO_CARD_PROCESSING', false)
        })
    },
    assignOrderToBdoCard ({ state, commit }, { orderId, bdoCardId = state.entity.id }) {
      commit('SET_BDO_CARD_PROCESSING', true)
      return api.assignOrderToBdoCard(bdoCardId, orderId)
        .finally(() => {
          commit('SET_BDO_CARD_PROCESSING', false)
        })
    },
    unassignOrder ({ state, commit }) {
      commit('SET_BDO_CARD_PROCESSING', true)
      return api.unassignOrderFromBdoCard(state.entity.id)
        .finally(() => {
          commit('SET_BDO_CARD_PROCESSING', false)
        })
    },
    assignCourseToBdoCard ({ state, commit }, { courseId, bdoCardId = state.entity.id }) {
      commit('SET_BDO_CARD_PROCESSING', true)
      return api.assignCourseToBdoCard(bdoCardId, courseId)
        .finally(() => {
          commit('SET_BDO_CARD_PROCESSING', false)
        })
    },
    updateTransportTime ({ state, commit }, plannedTransportTime) {
      commit('SET_BDO_CARD_PROCESSING', true)
      return api.updateTransportTime(state.entity.id, plannedTransportTime)
        .finally(() => {
          commit('SET_BDO_CARD_PROCESSING', false)
        })
    },
  }
}
