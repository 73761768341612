var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-details"},[_c('div',{staticClass:"detail-section__header contact-details__header"},[_vm._v(" Historia połączeń klienta ")]),_c('v-divider'),_c('v-data-table',{staticClass:"details-table",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.callEntries,"items-per-page":_vm.itemsPerPage || 150,"loading":_vm.isProcessing,"show-expand":"","expanded":_vm.expandedCallEntries},on:{"update:expanded":function($event){_vm.expandedCallEntries=$event},"click:row":function (item, slot) { return _vm.expandRow(item, slot); },"contextmenu:row":function($event){$event.preventDefault();return (function (event, row) { return _vm.handleContextMenu(event, row.item); }).apply(null, arguments)}},scopedSlots:_vm._u([{key:"header.data-table-expand",fn:function(){return [_c('th',[_c('v-switch',{staticClass:"ma-0",attrs:{"input-value":!!_vm.expandedCallEntries.length,"hide-details":"","dense":"","title":"Przełącz wszystkie"},on:{"change":_vm.toggleAllCallEntries}})],1)]},proxy:true},{key:"item.notes",fn:function(ref){
var item = ref.item;
return [_c('TableColumnInfo',{attrs:{"text":item.notes}})]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.courses && item.courses.length)?_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"expanded-table",attrs:{"headers":_vm.expandedRowHeaders,"items":item.courses,"items-per-page":-1,"fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateStringFormat(item.dueDate))+" ")]}}],null,true)})],1)]}}],null,true)}),_c('Pagination',{attrs:{"table-name":"callEntries"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }