import api from '../api/v1'
import router from '../router'

export default {
  namespaced: true,
  state: {
    isLoadedApp: false,
    isLoading: false,
    department: JSON.parse(localStorage.getItem('EL-Department')) || null,
    filters: {},
    mapMode: 'coursesMap',
    fullViewMode: false,
  },
  mutations: {
    SET_APP_LOADED_STATE (state, loaded = true) {
      state.isLoadedApp = loaded
    },
    LOADING (state, loading) {
      state.isLoading = loading
    },
    SET_MAP_MODE (state, mode) {
      state.mapMode = mode
    },
    SET_DEPARTMENT (state, department) {
      department.location = {
        lat: parseFloat(department.lat),
        lng: parseFloat(department.lng)
      }
      state.department = department
      localStorage.setItem('EL-Department', JSON.stringify(department))
    },
    SET_FILTERS (state, filters) {
      filters.driverStatuses = [
        { name: 'W trakcie trasy', value: false },
        { name: 'Dostępni', value: true }
      ]
      state.filters = filters
    },
    SET_FULL_VIEW_MODE (state) {
      state.fullViewMode = true
    }
  },
  actions: {
    initApp ({ state, dispatch, commit, rootState }) {
      return new Promise((resolve) => {
        dispatch('departments/getItems', { params: {} }, { root: true })
          .then(() => {
            const department = state.department || rootState.departments.items[0]
            commit('SET_DEPARTMENT', department)
            dispatch('fetchDepartmentResources')
            resolve()
          })
      })
    },
    fetchDepartmentResources ({ commit, dispatch, state }) {
      commit('LOADING', true)
      const containerTypesParams = router.currentRoute.name === 'containersMap'
        ? { params: { departmentId: state.department.id, onRoute: true } } : null

      const filters = dispatch('getFilters')
      const businessPlaces = dispatch('businessPlaces/getItems', null, { root: true })
      const communes = dispatch('communes/getItems', null, { root: true })
      const drivers = dispatch('drivers/getItems', null, { root: true })
      const containers = dispatch('containers/getItems', null, { root: true })
      const containerTypes = dispatch('containerTypes/getItems', containerTypesParams, { root: true })
      const debrisTypes = dispatch('debrisTypes/getItems', { params: { aggregate: false } }, { root: true })
      const vehicles = dispatch('vehicles/getItems', null, { root: true })
      const terminals = dispatch('terminals/getItems', null, { root: true })
      const users = dispatch('users/getItems', null, { root: true })
      const middlemen = dispatch('middlemen/getItems', { params: { endpoint: 'clients', filters: { isMiddleman: true } } }, { root: true })

      return Promise.all([businessPlaces, communes, containerTypes, filters, drivers, containers, debrisTypes, vehicles, terminals, users, middlemen])
        .finally(() => {
          commit('SET_APP_LOADED_STATE')
          commit('LOADING', false)
        })
    },
    setLoading ({ commit }, loading) {
      commit('LOADING', loading)
    },
    setMapMode ({ commit }, mode) {
      commit('SET_MAP_MODE', mode)
    },
    setDepartment ({ commit, dispatch }, department) {
      commit('SET_DEPARTMENT', department)
      dispatch('fetchDepartmentResources')
    },
    setClientsOptions ({ state, commit }, params) {
      if (!params) params = { departmentId: state.department.id }
      api.getSimplifiedList('clients', params)
        .then((clients) => {
          const appFilters = {
            ...state.filters,
            clients: clients.data
          }
          commit('SET_FILTERS', appFilters)
        })
    },
    setBdoCardsOptions ({ state, commit, rootGetters }) {
      const { params } = rootGetters['tables/getTableParameters']('bdoCards')
      params.filterNames = ['sender_business_place', 'receiver_business_place', 'commune']
      api.getBdoCardsFilters(params)
        .then((resp) => {
          const appFilters = {
            ...state.filters,
            ...resp.data
          }
          commit('SET_FILTERS', appFilters)
        })
    },
    getFilters ({ commit, dispatch }) {
      return api.getFilters()
        .then((filters) => {
          commit('SET_FILTERS', filters.data)
          dispatch('setClientsOptions')
        })
    },
    setFullViewMode ({ commit }) {
      commit('SET_FULL_VIEW_MODE')
    }
  }
}
