<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Tabs
      v-if="configured"
      ref="tabs"
      :tabs="tabs"
      persistent
      @goTo="goTo"
    />
    <Table
      ref="table"
      :table-name="tableName"
      :key="tableName"
      @openDetails="openSingleBdoCardView"
      @configured="configured = true"
    >
      <template #tableTop>
        <FiltersBar
          ref="tableTop"
          :table-name="tableName"
          search-bar
          :search-bar-props="{class: tableName !== 'bdoCards' && 'mr-4'}"
          :date-range-picker="tableName === 'bdoCards'"
          show-filters-button
          table-filters
        />
      </template>
      <template #stats>
        <div
          v-if="tableName !== 'assigningBdoCards'"
          class="mx-10 d-flex"
        >
          <span class="table-statistics__label">{{ totalWeight.text }}</span>
          <span class="table-statistics__value">{{ totalWeight.value }}</span>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Tabs from '../../components/Layout/Tabs'
import Table from '../../components/Table/Table'
import FiltersBar from '../../components/Filters/FiltersBar'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import webSocketMixin from '../../mixins/webSocketMixin'
import { mapGetters, mapActions } from 'vuex'

const tabs = Object.freeze([
  { value: 'bdoCards', text: 'Wszystkie' },
  { value: 'revisionIndex', text: 'Korekty' },
  { value: 'assigningBdoCards', text: 'Przydzielanie' }
])

export default {
  components: {
    Header,
    Tabs,
    Table,
    FiltersBar,
  },
  mixins: [defaultTableEventsMixin, webSocketMixin],
  channels: {
    BdoCardIndexChannel: { received (data) { this.captureChanges(data) } },
  },
  data: () => ({
    tableName: 'bdoCards',
    configured: false,
    areWatchersActive: false,
    tabs,
  }),
  computed: {
    ...mapGetters({
      getFilterValue: 'tables/getFilterValue'
    }),
    clientFilter () {
      return this.getFilterValue(this.tableName, 'clientId')
    },
    wasteGeneratingFilter () {
      return this.getFilterValue(this.tableName, 'wasteGenerating')
    },

    filtersValues() {
      const { filters } = this.$store.getters['tables/getTableConfig'](this.tableName)
      return filters.map(filter => filter.filterBy)
    },

    cards () {
      return this.$store.state[this.tableName].items
    },
    totalWeight () {
      const value = this.cards.reduce((total, card) => {
        return total + Number(card.reportedWeight)
      }, 0)
      return {
        value: value + ' T',
        text: 'Łączna raportowana waga: '
      }
    }
  },
  watch: {
    filtersValues: {
      deep: true,
      handler () {
        this.setBdoCardsOptions()
      }
    },
    clientFilter (clientId) {
      if (this.areWatchersActive) {
        const clearBusinessPlacesFilter = (filterName) => {
          this.setTableFilters({ filters: [{ name: filterName, filterBy: '', disabled: !clientId }], tableName: this.tableName, disableFetch: !clientId })
        }

        clearBusinessPlacesFilter('senderBusinessPlaceId')
        clearBusinessPlacesFilter('receiverBusinessPlaceId')
      }
    },
    wasteGeneratingFilter (filterValue) {
      if (this.areWatchersActive) {
        this.setTableFilters({
          filters: [{ name: 'communeId', filterBy: '', disabled: !filterValue, }],
          tableName: this.tableName,
          disableFetch: !!filterValue
        })
      }
    }
  },
  mounted () {
    this.subscribeSocket('BdoCardIndexChannel') // todo adjust ws
    this.setClientsOptions({})
  },
  beforeDestroy () {
    this.unsubscribeSocket('BdoCardIndexChannel')
    this.setClientsOptions()
  },
  methods: {
    ...mapActions({
      setClientsOptions: 'core/setClientsOptions',
      setBdoCardsOptions: 'core/setBdoCardsOptions',
      setTableFilters: 'tables/setTableFilters',
    }),
    openSingleBdoCardView ({ id }) {
      this.$router.replace({ name: 'singleBdo', params: { id } })
    },
    goTo (tab) {
      this.areWatchersActive = false
      this.tableName = tab.value
      this.$nextTick(() => {
        this.areWatchersActive = true
      })
    },
  }
}
</script>
