<template>
  <div>
    <Header
      ref="header"
      v-if="tableName"
      :title="$route.meta.title"
      :table-name="tableName"
    >
      <template v-if="tableName === 'courses'">
        <v-btn
          class="px-4 ml-6"
          :class="{
            'button-active':
              isMultiselectActive && multiselectSubject === 'changeDates'
          }"
          color="primary"
          outlined
          v-shortkey="multiselectShortkeys"
          @shortkey="toggleMultiselect('changeDates')"
          @click="toggleMultiselect('changeDates')"
        >
          {{
            getLabelWithShortkey(
              'Zmień daty realizacji',
              multiselectShortkeys.key
            )
          }}
        </v-btn>
        <v-btn
          class="px-4 ml-6"
          :class="{
            'button-active':
              isMultiselectActive && multiselectSubject === 'export'
          }"
          color="primary"
          outlined
          v-shortkey="exportShortkeys"
          @shortkey="toggleMultiselect('export')"
          @click="toggleMultiselect('export')"
        >
          {{ getLabelWithShortkey('Exportuj do csv', exportShortkeys.key) }}
        </v-btn>
      </template>
    </Header>
    <div class="table-view-wrapper">
      <div>
        <Tabs
          v-if="configured"
          ref="tabs"
          :tabs="tabs"
          persistent
          @goTo="goTo"
        />
        <Table
          v-if="tableName"
          ref="table"
          :key="currentTab"
          :table-name="tableName"
          disable-pagination
          :hide-movement-column="currentTab !== 'courses'"
          @selectRow="selectTableRow"
          @showPreview="openPreview"
          @openDetails="openTableDetails"
          @contextMenu="openContextMenu"
          @configured="configured = true"
        >
          <template #tableTop>
            <FiltersBar
              ref="tableTop"
              :table-name="tableName"
              search-bar
              date-range-picker
              :date-range-picker-props="{ hidePresets: sidebar.size > 0 }"
              show-filters-button
              table-filters
            />
          </template>
          <template #stats>
            <TableStatisticsBar
              ref="statistics"
              :table-name="tableName"
              drivers-occupancy
            />
          </template>
        </Table>
      </div>
      <TableConfirmationBar
        v-if="isMultiselectActive"
        :confirm-actions="confirmationBarActions"
        :table-name="tableName"
        ref="confirmation"
      />
    </div>
    <Sidebar>
      <component
        v-if="detailsKey"
        :key="detailsKey"
        :is="componentTableDetails"
        :table-name="tableName"
        :data="chosenItem"
        :actions="availableActions"
      />
    </Sidebar>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Tabs from '../../components/Layout/Tabs'
import Table from '../../components/Table/Table'
import SinglePreview from '../../components/Single/SinglePreview'
import SingleViewOrder from '../../components/Single/SingleViewOrder'
import SinglePreviewRoute from '../../components/Single/SinglePreviewRoute'
import FiltersBar from '../../components/Filters/FiltersBar'
import Sidebar from '../../components/Layout/Sidebar/Sidebar'
import { mapActions, mapState, mapGetters } from 'vuex'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import webSocketMixin from '../../mixins/webSocketMixin'
import TableStatisticsBar from '../../components/Table/TableStatisticsBar'
import TableConfirmationBar from '../../components/Table/TableConfirmationBar'
import actions from '../../const/actions'
import {
  getLabelWithShortkey,
  multiselectShortkeys,
  exportShortkeys
} from '../../const/shortKeys'

const tabs = Object.freeze([
  {
    value: 'courses',
    tableName: 'courses',
    text: 'Wszystkie kursy',
    filters: [
      { name: 'courseStatus', filterBy: '', hide: false },
      { name: 'withoutCards', filterBy: '', hide: true },
    ]
  },
  {
    value: 'coursesToBePlanned',
    tableName: 'courses',
    text: 'Kursy do zaplanowania',
    filters: [
      { name: 'courseStatus', filterBy: 'Zaplanuj', hide: true },
      { name: 'withoutCards', filterBy: '', hide: true },
    ]
  },
  {
    value: 'coursesWithoutCards',
    tableName: 'courses',
    text: 'Kursy bez karty',
    filters: [
      { name: 'withoutCards', filterBy: true, hide: true },
      { name: 'courseStatus', filterBy: '', hide: true },
    ]
  },
  { value: 'routes', tableName: 'routes', text: 'Zaplanowane trasy' },
  {
    value: 'routesCompleted',
    tableName: 'routesCompleted',
    text: 'Wykonane trasy',
  },
  {
    value: 'bdoCardObsolete',
    tableName: 'courses',
    text: 'Ogony',
    filters: [
      { name: 'withoutCards', filterBy: true, hide: true },
      {
        name: 'courseStatus',
        filterBy: 'Wykonany',
        hide: true
      }
    ]
  }
])

export default {
  components: {
    Header,
    Tabs,
    Table,
    SinglePreview,
    SinglePreviewRoute,
    SingleViewOrder,
    FiltersBar,
    Sidebar,
    TableStatisticsBar,
    TableConfirmationBar
  },
  mixins: [defaultTableEventsMixin, webSocketMixin],
  data: () => ({
    tableName: 'courses',
    currentTab: 0,
    chosenItemId: null,
    configured: false,
    actions,
    multiselectShortkeys,
    exportShortkeys,
    tabs,
    multiselectSubject: 'export'
  }),
  channels: {
    CourseIndexChannel: {
      received(data) {
        this.captureChanges(data)
      }
    },
    RouteIndexChannel: {
      received(data) {
        this.captureChanges(data)
      }
    },
    OrderShowChannel: {
      received(data) {
        this.captureSingleChange(data)
      }
    }
  },
  computed: {
    ...mapState({
      course: state => state.course.entity,
      order: state => state.order.entity,
      route: state => state.route.entity,
      department: state => state.core.department,
      swapOrder: state => state.order.swapOrder,
      isMultiselectActive: state => state.courses.isMultiselectActive,
      selectedItems: state => state.courses.selectedItems
    }),
    ...mapGetters({
      getTableParameters: 'tables/getTableParameters'
    }),
    tableDateRange() {
      return this.$store.state[this.tableName].dateRange
    },
    items() {
      return this.$store.state[this.tableName]?.items
    },
    confirmationBarActions() {
      if (this.multiselectSubject === 'changeDates') {
        return [
          actions.editAllDueDates,
          {
            ...actions.editMultipleDueDates,
            disabled: !this.selectedItems.length
          }
        ]
      } else if (this.multiselectSubject === 'export') {
        return [
          actions.exportAllCoursesToCsv,
          {
            ...actions.exportMultipleCoursesToCsv,
            disabled: !this.selectedItems.length
          }
        ]
      }
      return []
    },
    detailsKey() {
      return this.chosenItem?.id
    },
    chosenItem() {
      return this.items?.find(item => item.id === this.chosenItemId)
    },
    componentTableDetails() {
      const component = {
        courses: this.sidebar.size > 1 ? 'SingleViewOrder' : 'SinglePreview',
        routes: 'SinglePreviewRoute',
        routesCompleted: 'SinglePreviewRoute'
      }
      return component?.[this.tableName]
    }
  },
  watch: {
    tableDateRange(newDate) {
      const [channel] = this.activeChannels
      this.unsubscribeSocket(channel)
      this.subscribeSocket(channel, { dueDate: newDate[0] })
    },
    isMultiselectActive() {
      this.$refs.table.resizeTable()
    },
    chosenItem(chosenItem) {
      if (this.sidebar.size === 1 && !chosenItem) this.closeSidebar()
    }
  },
  mounted() {
    this.subscribeSocket('CourseIndexChannel', {
      dueDate: this.tableDateRange?.[0]
    })
  },
  beforeDestroy() {
    this.unsubscribeSocket('CourseIndexChannel')
    this.unsubscribeSocket('RouteIndexChannel')
  },
  methods: {
    ...mapActions({
      toggleMultiselectStatus: 'courses/toggleMultiselectStatus',
      getSingleRoute: 'route/getSingleRoute',
      resetRoutePlanning: 'route/resetRoutePlanning',
      getSingleOrder: 'order/getSingleOrder',
      clearSingleOrder: 'order/clearSingleOrder',
      setGlobalLoader: 'core/setLoading',
      setTableFilters: 'tables/setTableFilters',
      setSidebar: 'layout/setSidebar',
      closeSidebar: 'layout/closeSidebar'
    }),
    getLabelWithShortkey,
    toggleMultiselect(subject) {
      const prevSubject = this.multiselectSubject
      if (subject !== this.multiselectSubject) {
        this.multiselectSubject = subject
      }
      if (!this.isMultiselectActive || subject === prevSubject) {
        this.toggleMultiselectStatus(!this.isMultiselectActive)
      }
    },
    goTo(tab) {
      const { tableName, value, filters } = tab
      this.tableName = tableName
      this.chosenItemId = null
      this.closeSidebar()
      this.currentTab = value
      // TODO if we are about to cache courses, those lines should be moved somewhere else
      if (filters) {
        this.setTableFilters({ filters, tableName })
      }
      if (tableName === 'courses') {
        // TODO handle ws changes in coursesToBePlanned tab
        this.subscribeSocket('CourseIndexChannel', {
          dueDate: this.tableDateRange[0]
        })
        this.unsubscribeSocket('RouteIndexChannel')
      } else {
        this.subscribeSocket('RouteIndexChannel', {
          dueDate: this.tableDateRange[0]
        })
        this.unsubscribeSocket('CourseIndexChannel')
      }
    },
    selectTableRow(data) {
      this.chosenItemId = data.id
      if (this.tableName !== 'courses' && this.route.id !== data.id) {
        this.getSingleRoute(data.id)
      }
    },
    openPreview(data) {
      this.selectTableRow(data)
      this.setSidebar({ size: 1 })
    },
    openTableDetails(data) {
      if (this.tableName === 'courses') {
        const { id } = data.order
        const emptyOrderId = -1
        if (id !== emptyOrderId) {
          this.$router.push({
            name: 'singleOrder',
            params: { id, originTable: 'courses' }
          })
          this.subscribeSocket('OrderShowChannel', { orderId: id })
        } else {
          this.setSidebar({ size: 2 })
        }
      }
    }
  }
}
</script>
