<template>
  <v-card
    class="d-flex flex-column"
    width="300"
    elevation="0"
  >
    <v-card-text>
      <v-row>
        <v-col class="py-0">
          <div class="detail-line">
            <span>Kierowca: </span>
            <b>{{ driver.firstName + ' ' + driver.lastName }}</b>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    driver: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-line  {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  width: 100%;
}
</style>
