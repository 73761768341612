var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports-view details client-details-view mt-2"},[_c('Header',{ref:"header",staticClass:"px-5",attrs:{"title":_vm.$route.meta.title,"buttons":[]}}),_c('FiltersBar',{attrs:{"table-name":_vm.tableName,"search-bar":"","date-range-picker":"","show-filters-button":"","table-filters":""}}),_c('div',{staticClass:"px-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"detail-section__wrapper"},[_c('div',{staticClass:"detail-section__header"},[_vm._v(" Zamówienia firm ")]),_c('div',{staticClass:"detail-section__content"},[_c('v-data-table',{staticClass:"report-viewport",class:{'filters-open': _vm.showFilters},attrs:{"item-class":function () { return 'table__row'; },"headers":_vm.headers,"items":_vm.clients,"items-per-page":-1,"fixed-header":"","hide-default-footer":"","show-expand":"","single-expand":"","item-key":"id","expanded":_vm.expandedClients},on:{"update:expanded":function($event){_vm.expandedClients=$event},"click:row":function (item, slot) { return _vm.expandRow(slot); }},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var tableHeaders = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":tableHeaders.length}},[_c('v-data-table',{staticClass:"expanded-table br-0",attrs:{"headers":_vm.subtableHeaders,"items":item.addressData,"items-per-page":-1,"fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.totalGrossValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(item.totalGrossValue, 'price'))+" ")]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary","small":""},on:{"click":function($event){return _vm.openAddressDetails(item)}}},[_vm._v(" Wyświetl zlecenia ")])]}}],null,true)})],1)]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.addressData && item.addressData.length)?_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()],1)]}},{key:"item.totalGrossValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(item.totalGrossValue, 'price'))+" ")]}},{key:"item.trend",fn:function(ref){
var item = ref.item;
return [_c('v-sparkline',{attrs:{"value":_vm.getTrendValues(item.monthData),"line-width":"2","smooth":"","padding":12,"stroke-linecap":"round","type":"trend","auto-line-width":"","auto-draw":""}})]}}],null,true)})],1)])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"detail-section__wrapper mb-5"},[_c('div',{staticClass:"detail-section__header"},[_vm._v(" Zlecenia z podziałem na miesiące ")]),_c('div',{staticClass:"detail-section__content"},[(_vm.expandedClients.length)?_c('v-data-table',{attrs:{"item-class":function () { return 'table__row'; },"headers":_vm.monthParameters,"items":_vm.monthsData,"items-per-page":-1,"fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.totalGrossValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(item.totalGrossValue, 'price'))+" ")]}}],null,true)}):_c('div',{staticClass:"pa-4"},[_vm._v(" Brak danych do wyświetlenia ")])],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }