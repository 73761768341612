<template>
  <v-toolbar
    absolute
    tile
    width="100%"
    elevation="0"
    class="px-3"
    :height="height"
  >
    <v-btn
      v-if="!isPreview"
      text
      color="primary"
      class="details__back--text btn--text pl-3"
      @click="goBack"
    >
      <v-icon>
        mdi-chevron-left
      </v-icon>
      Wróć
    </v-btn>
    <v-toolbar-title class="ma-0">
      <div>
        {{ title }}
        <div
          v-if="!isPreview && blocked"
          class="block-status"
        >
          Blokada
        </div>
      </div>
    </v-toolbar-title>
    <slot>
      <v-spacer />
      <v-btn
        class="mr-1"
        icon
        @click="goBack"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </slot>
  </v-toolbar>
</template>

<script>
import warehouseTabs from '../../../helpers/warehouseTabs'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    isPreview: {
      type: Boolean,
      required: false
    },
    isEmptyOrder: {
      type: Boolean,
      required: false
    },
    blocked: {
      type: Boolean,
      required: false
    },
    height: {
      type: String,
      default: '80px',
      required: false
    },
    tableName: {
      type: String,
      required: false
    },
    prevRoute: {
      type: Object,
      default: () => {}
    },
  },
  methods: {
    goBack () {
      if (this.isPreview || this.isEmptyOrder) {
        this.$emit('closeDetails')
      } else {
        const prevRouteName = this.prevRoute?.name
        const isWarehouseTable = warehouseTabs.map(tab => tab.value).includes(this.tableName)
        if (prevRouteName === this.tableName) {
          this.$router.push({ name: this.tableName, params: { omitFetch: true } })
        } else if (prevRouteName) {
          this.$router.back()
        } else if (isWarehouseTable) {
          this.$router.push({ name: 'warehouse' })
        } else {
          this.$router.push({ name: this.tableName })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-toolbar__title > div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow:hidden;
}
</style>
