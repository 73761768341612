<template>
  <div
    class="client-table-wrapper"
    :style="`min-height: ${tableMinHeight}`"
  >
    <Table
      v-if="clientFilterSet"
      ref="table"
      :key="tableName"
      :table-name="tableName"
      :top-offset="topSectionHeight"
      class="details-table w-100 px-4"
      @selectRow="selectTableRow"
      @openDetails="openTableDetails"
      @contextMenu="openContextMenu"
    >
      <template #tableTop>
        <FiltersBar
          ref="tableTop"
          :table-name="tableName"
          :table-mode-filter="tableName !== 'clientBDO'"
          :table-mode-filter-props="{class: 'mr-4'}"
          search-bar
          date-range-picker
          :date-range-picker-props="{clearable: true}"
          show-filters-button
          table-filters
        />
      </template>
    </Table>
    <TableConfirmationBar
      v-if="selectedItems.length"
      ref="confirmation"
      :confirm-actions="multiselectActions"
      :table-name="tableName"
    />
  </div>
</template>

<script>
import Table from '../../../components/Table/Table'
import TableConfirmationBar from '../../../components/Table/TableConfirmationBar'
import FiltersBar from '../../../components/Filters/FiltersBar'
import { disableInactiveActions, getContextMenu } from '../../../utils/quickActions'
import { getTableWsData } from '../../../utils'
import { mapActions, mapState } from 'vuex'
import defaultTableEventsMixin from '../../../mixins/defaultTableEventsMixin.vue'
import webSocketMixin from '../../../mixins/webSocketMixin.vue'

export default {
  components: {
    Table,
    TableConfirmationBar,
    FiltersBar,
  },
  props: {
    tableName: {
      type: String,
      required: true
    },
    multiselectActions: {
      type: Array,
      required: false
    },
    topSectionHeight: {
      type: Number,
      default: 500,
    }
  },
  mixins: [
    defaultTableEventsMixin, // availableActions
    webSocketMixin // subscribeSocket, unsubscribeSocket
  ],
  channels: {
    computed: [
      {
        channelName () { return this.indexChannel },
        received (data) { this.captureChanges(data) }
      }
    ]
  },
  data: () => ({
    showFilters: false,
    clientFilterSet: false,
  }),
  computed: {
    ...mapState({
      client: state => state.client.entity,
    }),
    selectedItems () {
      return this.$store.state[this.originTableName]?.selectedItems
    },
    tableMinHeight () {
      return `calc(100vh - ${this.topSectionHeight}px)`
    },
    indexChannel () {
      return getTableWsData(this.tableName)?.indexChannel
    },
    showChannel () {
      return getTableWsData(this.tableName)?.showChannel
    },
    originTableName () {
      const originTables = {
        clientOrders: 'orders',
        clientInvoices: 'invoices',
        clientBDO: 'bdoCards'
      }
      return originTables[this.tableName]
    }
  },
  watch: {
    selectedItems (newValue, oldValue) {
      if (newValue?.length !== oldValue?.length && (newValue?.length === 0 || oldValue?.length === 0)) {
        this.$refs.table?.resizeTable()
      }
    }
  },
  mounted () {
    if (this.client.id) {
      const { tableName } = this
      this.setTableConfig({ tableName, disableFetch: true })
      if (tableName === 'clientBDO') {
        this.setTableFilters({
          filters: [{ name: 'clientId', filterBy: this.client.id }],
          tableName,
          disableFetch: true
        })
      } else {
        this.setTableFilters({
          filters: [{ name: 'clientIds', filterBy: [this.client.id] }],
          tableName,
          disableFetch: true
        })
      }
      this.$nextTick(() => {
        this.subscribeSocket(this.indexChannel)
        this.clientFilterSet = true
      })
    }
  },
  beforeDestroy () {
    this.unsubscribeSocket(this.indexChannel)
  },
  methods: {
    ...mapActions({
      setTableFilters: 'tables/setTableFilters',
      setTableConfig: 'tables/setTableConfig',
      setSingleInvoice: 'invoice/setSingleInvoice',
      setSingleOrder: 'order/setSingleOrder',
      setContextMenu: 'layout/setContextMenu',
    }),
    selectTableRow (data) {
      if (this.tableName === 'clientOrders') {
        this.setSingleOrder(data)
      } else if (this.tableName === 'clientInvoices') {
        this.setSingleInvoice(data)
      } // bdoCards do not have quickActions
    },
    openContextMenu ({ item, position }) {
      let actions = getContextMenu(this.tableName)
      const blockerName = this.originTableName
      actions = disableInactiveActions(actions, item, blockerName)
      this.setContextMenu({ actions, item, position })
    },
    openTableDetails ({ id }) {
      switch (this.tableName) {
        case 'clientOrders':
          this.$router.push({ name: 'singleOrder', params: { id } })
          break
        case 'clientInvoices':
          this.$router.push({ name: 'singleInvoice', params: { id } })
          break
        case 'clientBDO':
          this.$router.push({ name: 'defaultSingleView', params: { tableName: 'bdoCards', id } })
          break
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.client-table-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
