<template>
  <div
    class="details details--full client-details-view"
    v-resize="calculateTopSectionHeight"
  >
    <DetailsToolbar
      ref="header"
      :title="$route.meta.title"
      :blocked="isBlocked"
      :table-name="tableName"
      :is-preview="isFullPanelOpened"
      :prev-route="prevRoute"
    >
      <v-spacer />
      <v-btn
        v-if="currentTab === 0"
        class="px-4 ml-6"
        :class="{ 'button-active': isMultiselectActive && multiselectSubject === 'generate'}"
        color="primary"
        outlined
        v-shortkey="multiselectShortkeys"
        @shortkey="toggleMultiselect('generate')"
        @click="toggleMultiselect('generate')"
      >
        {{ getLabelWithShortkey('Wygeneruj fakturę zbiorczą', multiselectShortkeys.key) }}
      </v-btn>
      <v-btn
        v-else-if="currentTab === 1"
        class="px-4 ml-6"
        :class="{ 'button-active': isMultiselectActive && multiselectSubject === 'download'}"
        color="primary"
        outlined
        v-shortkey="multiselectShortkeys"
        @shortkey="toggleMultiselect('download')"
        @click="toggleMultiselect('download')"
      >
        {{ getLabelWithShortkey('Pobierz faktury', multiselectShortkeys.key) }}
      </v-btn>
      <v-btn
        v-if="tabs[currentTab].multiselect"
        class="px-4 ml-6"
        :class="{ 'button-active': isMultiselectActive && multiselectSubject === 'export'}"
        color="primary"
        outlined
        v-shortkey="exportShortkeys"
        @shortkey="toggleMultiselect('export')"
        @click="toggleMultiselect('export')"
      >
        {{ getLabelWithShortkey('Exportuj do csv', exportShortkeys.key) }}
      </v-btn>
      <ActionButtons
        v-if="getDetailsActions.length"
        :actions="getDetailsActions"
        :item="client"
      />
    </DetailsToolbar>
    <v-container
      fluid
      ref="topSection"
    >
      <v-row
        class="px-2"
        align="stretch"
      >
        <v-col
          v-for="detail in detailsSections"
          :key="detail.name"
          class="h-100"
        >
          <div class="detail-section__wrapper">
            <div
              class="detail-section__header"
              v-if="detail.text"
            >
              {{ detail.text }}
            </div>

            <div :class="{'detail-section__content': detail.text}">
              <DetailsSection
                :detail="detail"
                :data="client"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <Tabs
      :tabs="tabs"
      @goTo="changeTab"
      class="pl-4 pt-2 mb-4 details-tabs"
      ref="tabs"
      persistent
      style="border-bottom: 1px solid #D7E0EB"
    />

    <v-tabs-items
      v-if="client.id"
      v-model="currentTab"
      style="background-color: transparent"
    >
      <v-tab-item>
        <ClientTable
          table-name="clientOrders"
          :multiselect-actions="confirmationBarActions"
          :top-section-height="topSectionHeight"
        />
      </v-tab-item>
      <v-tab-item>
        <ClientTable
          table-name="clientInvoices"
          :multiselect-actions="confirmationBarActions"
          :top-section-height="topSectionHeight"
        />
      </v-tab-item>
      <v-tab-item class="px-4">
        <ClientAddresses :client="client" />
      </v-tab-item>
      <v-tab-item class="px-4">
        <ClientDocuments :client="client" />
      </v-tab-item>
      <v-tab-item>
        <ClientTable
          table-name="clientBDO"
          :top-section-height="topSectionHeight"
        />
      </v-tab-item>
      <v-tab-item class="px-4">
        <ClientCallEntries :client-id="client.id" />
      </v-tab-item>
      <v-tab-item class="px-4">
        <ClientContacts
          :client="client"
          :top-section-height="topSectionHeight"
        />
      </v-tab-item>
      <v-tab-item class="px-4">
        <ClientBlockages :blockages="client.blockages" />
      </v-tab-item>
    </v-tabs-items>

    <CallEntryInput />
  </div>
</template>

<script>
import { getTableDetails, getBlockerValue } from '../../utils'
import actions from '../../const/actions'
import Tabs from '../Layout/Tabs'
import ActionButtons from '../Elements/ActionButtons'
import DetailsToolbar from './Sections/DetailsToolbar'
import DetailsSection from './Sections/DetailsSection'
import ClientAddresses from './Sections/ClientAddresses'
import ClientContacts from './Sections/ClientContacts'
import ClientDocuments from './Sections/ClientDocuments'
import ClientBlockages from './Sections/ClientBlockages'
import ClientCallEntries from './Sections/ClientCallEntries'
import ClientTable from './Sections/ClientTable'
import CallEntryInput from '../Elements/CallEntryInput'
import { mapState, mapActions } from 'vuex'
import { filterInactiveActions } from '../../utils/quickActions'
import { getLabelWithShortkey, multiselectShortkeys, exportShortkeys } from '../../const/shortKeys'
import webSocketMixin from '../../mixins/webSocketMixin.vue'
import navigateBackMixin from '../../mixins/navigateBackMixin'

export default {
  components: {
    Tabs,
    ActionButtons,
    DetailsToolbar,
    DetailsSection,
    ClientAddresses,
    ClientContacts,
    ClientDocuments,
    ClientBlockages,
    ClientCallEntries,
    ClientTable,
    CallEntryInput
  },
  provide () {
    return {
      notWebSocketHandled: false
    }
  },
  mixins: [
    webSocketMixin,
    navigateBackMixin // prevRoute
  ],
  data: () => ({
    tableName: 'clients',
    currentTab: 0,
    actions,
    multiselectShortkeys,
    exportShortkeys,
    multiselectSubject: 'export',
    tabs: [
      { value: 0, text: 'Zlecenia', multiselect: true },
      { value: 1, text: 'Faktury', multiselect: true },
      { value: 2, text: 'Lokalizacje' },
      { value: 3, text: 'Dokumenty' },
      { value: 4, text: 'BDO' },
      { value: 5, text: 'LOG CRM' },
      { value: 6, text: 'Osoby kontaktowe', actions: [] },
      { value: 7, text: 'Historia blokad', actions: [] },
    ],
    topSectionHeight: 350,
  }),
  channels: {
    ClientShowChannel: { received (data) { this.captureSingleChange(data) } }
  },
  computed: {
    ...mapState({
      client: state => state.client.entity,
      department: state => state.core.department,
      isMultiselectActive: function (state) {
        const tableName = this.currentTab === 0 ? 'orders' : 'invoices' // tabs order
        return state[tableName]?.isMultiselectActive
      },
    }),
    getDetailsActions () {
      const sharedActions = [
        { ...actions.blockClient },
        { ...actions.unblockClient },
        { ...actions.addNewPickup, props: { specificClient: true } },
        { ...actions.addNewSwap, props: { specificClient: true } },
        { ...actions.addNewOrder, create: true, props: { specificClient: true } },
      ]
      const tabActions = this.tabs[this.currentTab].actions || []
      const buttons = tabActions.concat(sharedActions)
      return filterInactiveActions(buttons, this.client, this.tableName)
    },
    detailsSections () {
      return getTableDetails(this.tableName).sections
    },
    isBlocked () {
      return getBlockerValue(this.client, this.tableName)
    },
    isFullPanelOpened () {
      return !!this.$route.query.full
    },
    confirmationBarActions () {
      const {
        generateInvoice, downloadMultipleInvoices, exportClientOrdersToCsv, exportClientInvoicesToCsv
      } = actions
      if (this.multiselectSubject === 'generate') {
        return [generateInvoice]
      } else if (this.multiselectSubject === 'download') {
        return [downloadMultipleInvoices]
      } else if (this.multiselectSubject === 'export') {
        if (this.currentTab === 0) {
          return [exportClientOrdersToCsv]
        } else if (this.currentTab === 1) {
          return [exportClientInvoicesToCsv]
        }
      }
      return []
    },
  },
  watch: {
    currentTab (newValue, oldValue) {
      if (this.tabs[oldValue].multiselect) {
        this.toggleMultiselectStatus(false)
      }
    }
  },
  mounted () {
    const { params: { id: clientId }, query: { phoneNumber, callStart } } = this.$route
    if (callStart) this.setupCallEntry({ clientId, phoneNumber, callStart })
    this.getAddresses({ params: { clientId, departmentId: this.department.id, } })
    this.getSingleClient(clientId)
    this.subscribeSocket('ClientShowChannel', { clientId })
    this.calculateTopSectionHeight()
  },
  beforeDestroy () {
    this.clearSingleClient()
    this.unsubscribeSocket('ClientShowChannel')
  },
  methods: {
    ...mapActions({
      getSingleClient: 'client/getSingleClient',
      clearSingleClient: 'client/clearSingleClient',
      clearClientOrders: 'clientOrders/clearTableItems',
      clearClientBDO: 'clientBDO/clearTableItems',
      clearClientInvoices: 'clientInvoices/clearTableItems',
      setupCallEntry: 'callEntry/setupCallEntry',
      getAddresses: 'addresses/getItems',
      toggleMultiselectStatus: function (dispatch, show) {
        const tableName = this.currentTab === 0 ? 'orders' : 'invoices' // tabs order
        return dispatch(`${tableName}/toggleMultiselectStatus`, show)
      },
    }),
    getLabelWithShortkey,
    toggleMultiselect (subject) {
      const prevSubject = this.multiselectSubject
      if (subject !== this.multiselectSubject) {
        this.multiselectSubject = subject
      }
      if (!this.isMultiselectActive || subject === prevSubject) {
        this.toggleMultiselectStatus(!this.isMultiselectActive)
      }
    },
    calculateTopSectionHeight () {
      const { header, topSection, tabs } = this.$refs
      const headerHeight = header.$el.clientHeight || 0
      const tabsHeight = tabs.$el.clientHeight || 0
      const topSectionHeight = topSection.clientHeight || 0
      this.topSectionHeight = headerHeight + tabsHeight + topSectionHeight + 18
      // 18 for tabs margin bottom
    },
    changeTab (tab) {
      this.currentTab = tab.value
    },
  }
}
</script>
