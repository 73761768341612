import actions from '../../actions'
import orders from '../orders/orders'

export default {
  header: [
    { ...actions.checkPrice },
    { ...actions.addNewSwap },
    { ...actions.addNewPickup },
    { ...actions.addNewOrder, create: true }
  ],
  table: {
    isWebSocketHandled: true,
    maximumDateRange: 1,
    sorting: {
      sortBy: 'order.refNumber',
      sortDesc: true
    },
    filters: [
      { name: 'withoutCards', text: '', options: '', filterBy: '', propPath: '', hide: true },
      { name: 'courseStatus', text: 'Status kursu', options: 'courseStatuses', filterBy: 'Zaplanuj', propPath: 'courseStatus', hide: true }, // for courseToBePlanned tab
      { name: 'courseType', text: 'Typ kursu', options: 'courseTypes', filterBy: '', propPath: 'courseType' },
      { name: 'containerTypeIds', text: 'Typ kontenera', options: 'containerTypes', filterBy: '', propPath: 'order.containerType.id', multiple: true },
      { name: 'driverId', text: 'Kierowca', options: 'drivers', filterBy: '', propPath: 'driver.id' },
      { name: 'clientId', text: 'Klient', options: 'clients', filterBy: '', propPath: 'order.client.id' },
      { name: 'paymentType', text: 'Typ płatności', options: 'paymentTypes', filterBy: '', propPath: 'order.payment.paymentType' },
      { name: 'paymentStatus', text: 'Status płatności', options: 'paymentStatuses', filterBy: '', propPath: 'order.payment.paymentStatus' },
      { name: 'bdoCardObsolete', text: '', options: '', filterBy: '', propPath: 'bdoCardObsolete', hide: true }
    ],
    parameters: [
      { name: 'select', text: '', value: 'select', show: true, sortable: false },
      { name: 'moveToRelatedCourse', text: 'Przejdź', show: true, sortable: false },
      { name: 'refNumber', text: 'Nr zlecenia', value: 'order.refNumber', swapValue: true, show: true },
      { name: 'courseStatus', text: 'Status kursu', value: 'courseStatus', show: true },
      { name: 'courseType', text: 'Typ kursu', value: 'courseType', show: true },
      { name: 'containerId', text: 'Kontener', value: 'order.containerWithType', swapValue: true, show: true, cellClass: 'font-weight-bold' },
      { name: 'containerType', text: 'Zamówiony kontener', value: 'order.containerType.name', swapValue: true, show: true, cellClass: 'font-weight-bold' },
      { name: 'debrisType', text: 'Kod odpadu', value: 'order.debrisType.code', swapValue: true, show: true },
      { name: 'aggregateName', text: 'Kruszywo', value: 'order.aggregateType.displayName', swapValue: true, show: true, sortable: false },
      { name: 'bdoNumber', text: 'Nr BDO', value: 'order.client.bdoNumber', swapValue: true, show: true, sortable: false },
      { name: 'kpoNumber', text: 'Nr KPO', value: 'order.bdoNumber', swapValue: true, show: true },
      { name: 'bdoStatus', text: 'Status KPO', value: 'order.bdoStatus', swapValue: true, show: true },
      { name: 'bdoType', text: 'Logika BDO', value: 'order.client.bdoType', show: true },
      { name: 'driverDailySequence', text: 'Nr kursu', value: 'driverDailySequence', show: true },
      { name: 'driver', text: 'Kierowca', value: 'driver.fullName', show: true },
      { name: 'address', text: 'Adres zlecenia', value: 'order.address.formattedAddress', show: true },
      { name: 'commune', text: 'Gmina', value: 'order.address.commune.name', show: true },
      { name: 'clientName', text: 'Klient', value: 'order.client.name', clientLabel: true, show: true },
      { name: 'phoneNumber', text: 'Numer telefonu', value: 'order.address.phoneNumber', show: true },
      { name: 'timeSlot', text: 'Godziny', value: 'timeSlot', show: true },
      { name: 'reportedDebrisType', text: 'Raportowany typ odpadu', value: 'order.reportedDebrisType.displayName', swapValue: true, show: true },
      { name: 'aggregateAmount', text: 'Liczba ton', value: 'order.aggregateAmount', swapValue: true, show: true },
      { name: 'reportedWeight', text: 'Raportowana waga [T]', value: 'order.reportedWeight', swapValue: true, show: true },
      { name: 'totalGrossValue', text: 'Do zapłaty', value: 'order.payment.totalGrossValue', swapValue: true, show: true },
      { name: 'paidValue', text: 'Pobrano', value: 'order.payment.paidValue', swapValue: true, show: true },
      { name: 'paymentType', text: 'Typ płatności', value: 'order.payment.paymentType', swapValue: true, show: true },
      { name: 'paymentStatus', text: 'Status płatności', value: 'order.payment.paymentStatus', swapValue: true, show: true },
      { name: 'blocked', text: 'Blokada', value: 'order.client.blocked', show: true, sortable: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [
      { ...actions.sendCourse, icon: 'wyslij_kurs' },
      { ...actions.cancelCourse, icon: 'cofnij_wyslanie_kursu' }
    ],
    menu: [
      { ...actions.editOrder },
      { ...actions.editDueDate },
      { ...actions.copyOrder },
      { ...actions.sendCourse },
      { ...actions.cancelCourse },
      { ...actions.assignContainer },
      { ...actions.assignOrder },
      { ...actions.unassignOrder },
      { ...actions.assignKpoCardToOrder },
      { ...actions.unassignKpoCard },
      { ...actions.generateKpoCard },
      { ...actions.messageDriver },
      { ...actions.showCourseHistory },
      { ...actions.pickupContainer, idPath: 'item.order.id' },
      { ...actions.swapContainer, idPath: 'item.order.id' },
      { ...actions.editClient, idPath: 'order.client.id' },
      { ...actions.editCourseAddress },
      { ...actions.deleteCourse },
      { ...actions.startCourse },
      { ...actions.finishCourse },
      { ...actions.renewCourse },
    ]
  },
  quickView: {
    title: 'Zlecenie nr {{order.refNumber}}; {{swapCourse.order.refNumber}}',
    text: 'Informacje ogólne',
    fields: [
      { name: 'courseType', text: 'Typ kursu', value: ['courseType', 'swapCourse.courseType'] },
      { name: 'clientName', text: 'Klient', value: 'order.client.name' },
      { name: 'containerType', text: 'Typ kontenera', value: ['order.containerType.name', 'swapCourse.order.containerType.name'] },
      { name: 'containerId', text: 'Nr kontenera', value: 'order.container.id' },
      { name: 'debrisTypeName', text: 'Typ odpadu', value: 'order.debrisType.displayName' },
      { name: 'bdoNumber', text: 'Nr KPO', value: ['order.bdoNumber', 'swapCourse.order.bdoNumber'] },
      { name: 'bdoStatus', text: 'Status KPO', value: ['order.bdoStatus', 'swapCourse.order.bdoStatus'] },
      { name: 'orderAddress', text: 'Adres zlecenia', value: 'order.address.formattedAddress' },
      { name: 'timeSlot', text: 'Przedział godzinowy', value: ['timeSlot', 'swapCourse.timeSlot'] },
      { name: 'driverFullName', text: 'Kierowca', value: 'driver.fullName' },
      { name: 'addressNotes', text: 'Notatka', value: ['userNotes', 'swapCourse.userNotes'] },
      { name: 'paymentType', text: 'Typ płatnosci', value: ['order.payment.paymentType', 'swapCourse.order.payment.paymentType'] },
      { name: 'totalGrossValue', text: 'Kwota płatności', value: ['order.payment.totalGrossValue', 'swapCourse.order.payment.totalGrossValue'] },
      { name: 'paymentStatus', text: 'Status płatności', value: ['order.payment.paymentStatus', 'swapCourse.order.payment.paymentStatus'] }
    ],
    actions: [
      { ...actions.sendCourse, icon: 'wyslij_kurs' },
      { ...actions.cancelCourse, icon: 'cofnij_wyslanie_kursu' }
    ]
  },
  details: orders.details
}
