import { render, staticRenderFns } from "./ClientsReports.vue?vue&type=template&id=688759a3&scoped=true&"
import script from "./ClientsReports.vue?vue&type=script&lang=js&"
export * from "./ClientsReports.vue?vue&type=script&lang=js&"
import style0 from "./ClientsReports.vue?vue&type=style&index=0&id=688759a3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "688759a3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSparkline } from 'vuetify/lib/components/VSparkline';
installComponents(component, {VBtn,VCol,VDataTable,VIcon,VRow,VSparkline})
