import api from '../api/v1'

export default {
  namespaced: true,
  state: {
    processing: false,
    hints: []
  },
  mutations: {
    SET_HINTS_PROCESSING (state, status) {
      state.processing = status
    },
    SET_HINTS (state, hints) {
      state.hints = hints
    },
    ADD_HINT (state, hint) {
      state.hints.unshift(hint)
    },
    UPDATE_HINT (state, hint) {
      const copiedHints = [...state.hints]
      const editingIndex = copiedHints.findIndex(singleHint => singleHint.id === hint.id)
      copiedHints[editingIndex] = hint
      state.hints = copiedHints
    },
    REMOVE_HINT (state, hintId) {
      state.hints = state.hints.filter(hint => hint.id !== hintId)
    },
  },
  actions: {
    getHints ({ commit },) {
      commit('SET_HINTS_PROCESSING', true)
      api.getHints()
        .then((resp) => {
          commit('SET_HINTS', resp.data)
        })
        .finally(() => {
          commit('SET_HINTS_PROCESSING', false)
        })
    },
    createHint ({ commit }, params) {
      commit('SET_HINTS_PROCESSING', true)
      api.createHint(params)
        .then((resp) => {
          commit('ADD_HINT', resp.data)
        })
        .finally(() => {
          commit('SET_HINTS_PROCESSING', false)
        })
    },
    updateHint ({ commit }, { params, id }) {
      commit('SET_HINTS_PROCESSING', true)
      api.updateHint(id, params)
        .then((resp) => {
          commit('UPDATE_HINT', resp.data)
        })
        .finally(() => {
          commit('SET_HINTS_PROCESSING', false)
        })
    },
    deleteHint ({ commit }, id) {
      commit('SET_HINTS_PROCESSING', true)
      api.deleteHint(id)
        .then(() => {
          commit('REMOVE_HINT', id)
        })
        .finally(() => {
          commit('SET_HINTS_PROCESSING', false)
        })
    },
  }
}
