import api from '@/api/v1'

export default {
  namespaced: true,
  state: {
    isProcessing: false
  },
  mutations: {
    SET_PROCESSING (state, status) {
      state.isProcessing = status
    }
  },
  actions: {
    startCourse ({ commit, dispatch }, { item }) {
      const { id } = item
      api.startCourse(id)
        .then(() => {
          dispatch('layout/closeDialog', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Rozpoczęto kurs'],
            type: 'success'
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    finishCourse ({ commit, dispatch }, { item }) {
      const { id } = item
      api.finishCourse(id)
        .then(() => {
          dispatch('layout/closeDialog', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Zakończono kurs'],
            type: 'success'
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    sendCourse ({ commit, dispatch }, params) {
      commit('SET_PROCESSING', true)
      api.sendCourse(params)
        .then(() => {
          dispatch('layout/closeDialog', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Wysłano kurs'],
            type: 'success'
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    cancelCourse ({ commit, dispatch }, id) {
      commit('SET_PROCESSING', true)
      api.cancelCourse(id)
        .then(() => {
          dispatch('layout/closeDialog', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Cofnięto kurs'],
            type: 'success'
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    renewCourse({ commit, dispatch }, { item }) {
      commit('SET_PROCESSING', true)
      api.renewCourse(item.id).then(() => {
        dispatch('snackbar/showSnackbar', {
          message: ['Cofnięto zakończony kurs'],
          type: 'success'
        }, { root: true })
      }).finally(() => {
        commit('SET_PROCESSING', false)
      })
    },
    assignOrder ({ commit }, { id, params }) {
      commit('SET_PROCESSING', true)
      api.assignOrder({ id, params })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    unassignOrder ({ commit, dispatch }, data) {
      const { id } = data?.item?.swapCourse || data?.item

      function unassignOrder () {
        commit('SET_PROCESSING', true)
        api.unassignOrder(id)
          .then(() => {
            dispatch('layout/closeDialog', null, { root: true })
          })
          .finally(() => {
            commit('SET_PROCESSING', false)
          })
      }

      dispatch('layout/setDialog', {
        data: {
          component: 'ConfirmationDialog',
          text: 'Odepnij zlecenie',
          description: 'Czy na pewno chcesz odpiąć zlecenie?',
          callback: unassignOrder
        },
      }, { root: true })
    },
    generateKwu ({ commit, dispatch }, { id, contactName }) {
      commit('SET_PROCESSING', true)
      return api.generateCourseKwu(id, { contactName })
        .then(() => {
          dispatch('snackbar/showSnackbar', {
            message: ['Wygenerowano KWU']
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    unassignKpoCard ({ dispatch }, { item: course }) {
      const { bdoCardId } = course.swapCourse || course
      function unassignCard () {
        api.unassignOrderFromBdoCard(bdoCardId)
          .then(() => {
            dispatch('layout/closeDialog', null, { root: true })
          })
      }
      dispatch('layout/setDialog', {
        data: {
          component: 'ConfirmationDialog',
          text: 'Odepnij kartę',
          description: 'Czy na pewno chcesz odpiąć kartę?',
          tableName: 'orders',
          callback: unassignCard
        },
      }, { root: true })
    },
  }
}
