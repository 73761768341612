<template>
  <div class="details details--full mt-80px">
    <DetailsToolbar
      :title="getDetailsTitle"
      :table-name="tableName"
      :is-preview="isFullPanelOpened"
      :prev-route="prevRoute"
      @closeDetails="$router.push({name: 'prospects', params: { omitFetch: true }})"
    >
      <v-spacer />
      <ActionButtons
        :actions="getDetailsActions"
        :item="prospect"
      />
    </DetailsToolbar>
    <div class="px-4">
      <v-row>
        <v-col cols="12">
          <div class="detail-section__wrapper mb-4">
            <div class="detail-section__header">
              Notatki
            </div>
            <div class="detail-section__content">
              <DetailsSection
                v-for="note in prospect.notes"
                :key="'note'+note.id"
                :detail="config"
                :data="note"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { getTableDetails, getTitleFormatted } from '../../utils'
import { filterInactiveActions } from '../../utils/quickActions'
import ActionButtons from '../Elements/ActionButtons'
import DetailsToolbar from './Sections/DetailsToolbar'
import DetailsSection from './Sections/DetailsSection'
import navigateBackMixin from '../../mixins/navigateBackMixin'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    ActionButtons,
    DetailsToolbar,
    DetailsSection,
  },
  provide () {
    return {
      notWebSocketHandled: true
    }
  },
  mixins: [navigateBackMixin], // prevRoute
  data: () => ({
    config: {},
    tableName: 'prospects',
  }),
  computed: {
    ...mapState({
      prospect: state => state.prospect.entity,
    }),
    getDetailsActions () {
      const { actions } = getTableDetails(this.tableName)
      return filterInactiveActions(actions, this.prospect, this.tableName)
    },
    getDetailsTitle () {
      const title = getTableDetails(this.tableName).title
      return getTitleFormatted(title, this.prospect)
    },
    isFullPanelOpened () {
      return !!this.$route.query.full
    }
  },
  mounted () {
    const prospectId = this.$route.params.id
    this.getSingleProspect(prospectId)
    this.config = getTableDetails(this.tableName).sections[0]

    // subscribe because notes are separate entity to prospects
    this.$store.subscribeAction((action) => { // for editing notes
      if (action.type === 'prospects/editItem') this.getSingleProspect(prospectId)
    })
  },
  beforeDestroy () {
    this.clearSingleProspect()
  },
  methods: {
    ...mapActions({
      getSingleProspect: 'prospect/getSingleProspect',
      clearSingleProspect: 'prospect/clearSingleProspect',
    }),
  }
}
</script>
