<template>
  <v-simple-table class="v-data-table">
    <template #default>
      <thead>
        <tr>
          <th
            v-for="header in tableHeaders"
            :key="header"
            class="text-left"
          >
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="department in value"
          :key="department.departmentId"
        >
          <td>{{ getDepartment(department.departmentId).name }}</td>
          <td
            v-for="kwu in kwuValues"
            :key="kwu"
          >
            <v-checkbox
              :input-value="department[kwu]"
              hide-details
              class="mt-0"
              :disabled="isDefaultCode(department, kwu)"
              :label="isDefaultCode(department, kwu) ? 'Wybrany' : ''"
              @change="toggleKwuTypeValue($event, department, kwu)"
            />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapState } from 'vuex'
import kwuValues from '../../../const/kwuLabels'
const tableHeaders = ['Departament', 'KWU Kontrolna', 'KWU Prywatna', 'KWU Oświadczenie/Zwolniony', 'KWU KPO']

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    debrisCode: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    tableHeaders,
    kwuValues,
  }),
  computed: {
    ...mapState({
      departmentsList: state => state.departments.items
    }),
  },
  methods: {
    getDepartment(departmentId) {
      return this.departmentsList.find(department => department.id === departmentId)
    },
    isDefaultCode(department, kwu) {
      if (!this.debrisCode) return false
      const label = kwu + 'DefaultCode'
      return this.getDepartment(department.departmentId)[label] === this.debrisCode
    },
    toggleKwuTypeValue(value, department, type) {
      const departments = [...this.value]
      const updatedDepartment = {
        ...department,
        [`${type}`]: value
      }
      const departmentIndex = departments.findIndex(dep => dep.departmentId === department.departmentId)

      departments[departmentIndex] = updatedDepartment

      this.$emit('input', departments)
    }
  }
}
</script>
