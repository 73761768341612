import api from '../api/v1'
import humps from 'humps'

const today = new Date().toISOString().substring(0, 10)

export default {
  namespaced: true,
  state: {
    main: {
      courseTypesCount: {
        data: []
      },
      driverCourseTypesCount: {
        data: []
      },
      driverRoutesCount: {
        data: []
      },
      dateLoopsCount: {
        data: []
      },
      driverLoopsCount: {
        data: []
      }
    },
    income: {
      debrisOrders: {
        data: [],
        order: {
          field: 'sequence',
          direction: 'asc'
        }
      },
      aggregateOrders: {
        data: [],
        order: {
          field: 'sequence',
          direction: 'asc'
        }
      }
    },
    revenue: {
      revenue: {
        data: [],
        order: {
          field: 'sequence',
          direction: 'asc'
        }
      }
    },
    dateRange: [today],
    showFilters: false
  },

  mutations: {
    SET_STATISTICS (state, { report, payload }) {
      for (const tableName in payload) {
        state[report][tableName].data = payload[tableName]
      }
    },
    SET_ORDER (state, { report, tableName, order }) {
      state[report][tableName].order = order
    },
    TOGGLE_FILTERS_VISIBILITY (state) {
      state.showFilters = !state.showFilters
    },
    SET_TABLE_DATE (state, date) {
      state.dateRange = [...date].sort()
    },
  },

  actions: {
    storeTableOrder ({ commit }, orderData) {
      commit('SET_ORDER', orderData)
    },
    setTableDate ({ commit, dispatch }, { date, fetch }) {
      commit('SET_TABLE_DATE', date)
      if (fetch) dispatch('fetchReports')
    },
    fetchReports ({ state, rootState, commit, dispatch }, report) {
      dispatch('core/setLoading', true, { root: true })
      const tabs = ['main', 'income', 'revenue']

      const { filters: tableFilters, filtersEnabled } = rootState.tables.reports

      const filters = { daterange: state.dateRange }

      if (filtersEnabled) {
        tableFilters.forEach(filter => {
          if (filter.filterBy) filters[filter.name] = filter.filterBy.value
        })
      }

      const params = {
        departmentId: rootState.core.department.id,
        filters
      }

      api.getReport(params, report)
        .then((response) => {
          commit('SET_STATISTICS', { report, payload: response.data })
        })
        .finally(() => {
          dispatch('core/setLoading', false, { root: true })
        })

      tabs.filter(tab => tab !== report).forEach((tab) => {
        api.getReport(params, tab)
          .then((resp) => {
            commit('SET_STATISTICS', { report: tab, payload: resp.data })
          })
      })
    },
    generateReport ({ state, rootState, dispatch }, { exportType, tabName }) {
      dispatch('core/setLoading', true, { root: true })

      const filters = {
        daterange: state.dateRange
      }
      rootState.tables.reports.filters.forEach(filter => {
        if (filter.filterBy) {
          filters[filter.name] = filter.filterBy.value
        }
      })

      let order = []
      for (const dataset in state[tabName]) {
        if (state[tabName][dataset].order) {
          order.push({
            tableName: humps.decamelize(dataset),
            ...state[tabName][dataset].order
          })
        } else {
          order = undefined
        }
      }

      const params = {
        exportType,
        tabName,
        departmentId: rootState.core.department.id,
        filters,
        order
      }

      api.exportReport(params)
        .then((resp) => {
          const type = resp.headers['content-type']
          const url = window.URL.createObjectURL(new Blob([resp.data], { type }))

          if (process.env.IS_ELECTRON) {
            window.ipcRenderer.send('download-item', { url })

            window.ipcRenderer.on('download-success', () => {
              dispatch('snackbar/showSnackbar', {
                message: ['Pobrano raport'],
                type: 'success'
              }, { root: true })
            })
          } else {
            const link = document.createElement('a')
            link.href = url
            link.download = 'Raport ' + state.dateRange.join(' ')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        })
        .finally(() => {
          dispatch('core/setLoading', false, { root: true })
        })
    },
    toggleFiltersVisibility ({ commit }) {
      commit('TOGGLE_FILTERS_VISIBILITY')
    },
  }
}
