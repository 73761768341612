<template>
  <v-card
    class="d-flex flex-column"
    width="300"
    elevation="0"
  >
    <v-card-text class="pb-0">
      <v-row>
        <v-col class="py-0">
          <div class="detail-line">
            <span>Numer:</span>
            <b>{{ container.refNumber }}</b>
          </div>

          <div class="detail-line">
            <span>Typ:</span>
            <b>{{ container.order.debrisType.displayName }}</b>
          </div>

          <div class="detail-line">
            <span>Adres: </span>
            <b class="text-right">{{ container.order.address.formattedAddress }}</b>
          </div>
          <div class="detail-line">
            <span>Nr zlecenia: </span>
            <b>{{ container.order.refNumber }}</b>
          </div>

          <div class="detail-line">
            <span>Podstawiono: </span>
            <b>{{ dateStringFormat(container.leftAt) }}</b>
          </div>
          <div class="detail-line">
            <span>Klient: </span>
            <b>{{ container.order.client.name }}</b>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-spacer />
    <v-card-actions class="d-flex justify-space-between">
      <v-btn
        v-if="true"
        text
        color="primary"
        :disabled="container.order && !container.order.containerPickable"
        @click="commitAction('pickup')"
      >
        Zabierz kontener
      </v-btn>
      <v-btn
        v-if="true"
        text
        color="primary"
        :disabled="container.order && !container.order.containerPickable"
        @click="commitAction('swap')"
      >
        Wymień kontener
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { dateStringFormat } from '../../../utils'
import actions from '../../../const/actions'

export default {
  props: {
    container: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      setDialog: 'layout/setDialog'
    }),
    dateStringFormat,
    commitAction (action) {
      const isSwap = action === 'swap'
      const containerAction = isSwap ? actions.swapContainer : actions.pickupContainer
      const data = {
        ...containerAction,
        idPath: 'data.order.id',
        order: this.container.order,
      }
      this.setDialog({ data, size: isSwap && 'big' })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-line  {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4px 0;
  width: 100%;
  b{
    text-align: right;
  }
}
.mw-50 {
  max-width: 50%;
}
</style>
