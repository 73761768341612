<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Table
      ref="table"
      :key="tableName"
      :table-name="tableName"
      :overwritten-items="prospectsWithLastNote"
      @openDetails="openSingleProspect"
    >
      <template #tableTop>
        <div
          class="filters"
          ref="tableTop"
        >
          <div class="filters__inputs">
            <SearchBar :table-name="tableName" />
          </div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../../components/Layout/Header'
import Table from '../../../components/Table/Table'
import SearchBar from '../../../components/Filters/SearchBar'
import defaultTableEventsMixin from '../../../mixins/defaultTableEventsMixin'
import { mapState } from 'vuex'

export default {
  components: {
    Header,
    Table,
    SearchBar,
  },
  mixins: [defaultTableEventsMixin],
  data: () => ({
    tableName: 'prospects',
  }),
  computed: {
    ...mapState({
      prospects: state => state.prospects.items
    }),
    prospectsWithLastNote () {
      return this.prospects.map(prospect => {
        const lastNote = [...prospect.notes]?.pop()
        return {
          ...prospect,
          lastNoteDate: lastNote?.callStart,
          lastNoteText: lastNote?.text,
        }
      })
    }
  },
  methods: {
    openSingleProspect ({ id }) {
      this.$router.push({ name: 'singleProspect', params: { id } })
    }
  }
}
</script>
