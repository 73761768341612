<template>
  <div
    v-shortkey="navigateMenuShortkeys"
    @shortkey="changeView"
  >
    <v-list
      nav
      class="menu-pages"
    >
      <template v-for="menuItem in filteredMenuItems">
        <v-hover
          :key="menuItem.text"
          v-slot="{ hover }"
        >
          <v-list-group
            v-if="menuItem.children"
            class="v-list-item--dense"
            :class="{'active' : menuItem.active}"
            v-model="menuItem.active"
            color="#2cb56b"
            append-icon="mdi-chevron-down"
          >
            <template #activator>
              <v-list-item-icon>
                <Icon :name="hover || menuItem.active ? menuItem.icon : menuItem.icon + '_inactive'" />
              </v-list-item-icon>
              <v-list-item-title v-html="getNavText(menuItem)" />
            </template>

            <template v-for="child in menuItem.children">
              <v-list-item
                :key="child.text"
                :class="{'active' : child.active}"
                @click.exact="openPage(child)"
                @click.alt="newWindow(child.link)"
                link
                dense
                :ripple="false"
              >
                <v-list-item-title v-html="getNavText(child)" />
              </v-list-item>
            </template>
          </v-list-group>

          <v-list-item
            v-else
            :class="{'active' : menuItem.active}"
            @click.exact="openPage(menuItem)"
            @click.alt="newWindow(menuItem.link)"
            link
            :ripple="false"
            dense
          >
            <v-list-item-icon>
              <img :src="getNavIcon(menuItem)">
            </v-list-item-icon>
            <v-list-item-title v-html="getNavText(menuItem)" />
          </v-list-item>
        </v-hover>
      </template>
    </v-list>
  </div>
</template>

<script>
import menuItems from '../../const/menuItems'
import { navigateMenuShortkeys, shortkeysNavigateBoundaries } from '../../const/shortKeys'
import { openNewWindow } from '../../utils'
import api from '../../api/v1'
import { mapState } from 'vuex'
import humps from 'humps'

export default {
  data: () => ({
    menuItems,
    navigateMenuShortkeys,
    currentItemIndex: null,
    notifications: false,
  }),
  computed: {
    ...mapState({
      mapMode: state => state.core.mapMode,
      user: state => state.auth.user,
      token: state => state.auth.token,
    }),
    filteredMenuItems () {
      return this.menuItems.filter(option => !option.name || option.name === this.mapMode)
    },
    flattenMenuItems () {
      return this.filteredMenuItems.map(option => {
        return option.children ? [...option.children] : option
      }).flat()
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.setNavActive()
      }
    }
  },
  channels: {
    TaskBannerChannel: {
      received (data) {
        const { tasks } = humps.camelizeKeys(data)
        this.notifications = tasks
      }
    }
  },
  mounted() {
    // check if currentUser has any undone tasks
    api.getItems({
      tableName: 'tasks',
      params: {
        done: false,
        filters: {
          daterange: [new Date().toISOString().substring(0, 10)],
          assignedUserId: this.user.id,
        }
      }
    }).then((res) => {
      this.notifications = !!res.data.length
    })
      .finally(() => {
        this.$cable.connection.connect(`${process.env.VUE_APP_WSS_BASE_URL}?Authorization=${this.token}`)
        this.$cable.subscribe({ channel: 'TaskBannerChannel' })
      })
  },
  methods: {
    openPage (page) {
      if (this.$route.path && this.$route.path !== page.link) {
        this.$router.push(page.link)
      }
    },
    newWindow (path) {
      openNewWindow.call(this, { path })
    },
    setNavActive () {
      const { name, fullPath: path, params } = this.$route

      const isNestedRoute = (nav) => {
        if (!nav.nested?.length) return
        return nav.nested.some(nested => {
          if (nested?.name === 'defaultSingleView') {
            return nested.tableName && params?.tableName === nested.tableName
          }
          return nested?.name === name
        })
      }

      this.menuItems = this.menuItems.map((nav) => (
        nav.children ? {
          ...nav,
          active: !!nav.children.reduce((acc, obj) => (obj.link === path || isNestedRoute(obj)) + acc, 0),
          children: nav.children.map((child) => ({
            ...child,
            active: child.link === path || isNestedRoute(child)
          }))
        } : {
          ...nav,
          active: nav.link === path || isNestedRoute(nav)
        }))
      this.currentItemIndex = this.flattenMenuItems
        .findIndex(option => option.link === path || isNestedRoute(option))
    },
    changeView (event) {
      const { currentItemIndex, flattenMenuItems } = this
      const index = shortkeysNavigateBoundaries(
        event.srcKey,
        currentItemIndex,
        flattenMenuItems,
        ['shiftup'],
        ['shiftdown']
      )
      this.openPage(flattenMenuItems[index])
    },
    getNavIcon (menuItem) {
      return require(`@/assets/icons/${this.hover || menuItem.active ? menuItem.icon : menuItem.icon + '_inactive'}.svg`)
    },
    getNavText(navItem) {
      let { text, notificationsEnabled } = navItem
      if (notificationsEnabled && this.notifications) text += '<span class="ml-1 error--text">  &#9679; </span>'
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
.v-list-item--dense::v-deep > .v-list-group__header {
  min-height: 40px;
}
</style>
