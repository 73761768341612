<template>
  <div
    class="reports-view details client-details-view mt-2"
  >
    <Header
      class="px-5"
      ref="header"
      :title="$route.meta.title"
      :buttons="[]"
    />

    <FiltersBar
      :table-name="tableName"
      search-bar
      date-range-picker
      show-filters-button
      table-filters
    />

    <div class="px-4">
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          <div class="detail-section__wrapper">
            <div class="detail-section__header">
              Zamówienia firm
            </div>
            <div class="detail-section__content">
              <v-data-table
                class="report-viewport"
                :class="{'filters-open': showFilters}"
                :item-class="() => 'table__row'"
                :headers="headers"
                :items="clients"
                :items-per-page="-1"
                fixed-header
                hide-default-footer
                show-expand
                single-expand
                item-key="id"
                :expanded.sync="expandedClients"
                @click:row="(item, slot) => expandRow(slot)"
              >
                <template #expanded-item="{ headers: tableHeaders, item }">
                  <td :colspan="tableHeaders.length">
                    <v-data-table
                      class="expanded-table br-0"
                      :headers="subtableHeaders"
                      :items="item.addressData"
                      :items-per-page="-1"
                      fixed-header
                      hide-default-footer
                    >
                      <template #[`item.totalGrossValue`]="{item}">
                        {{ formatValue(item.totalGrossValue, 'price') }}
                      </template>
                      <template #[`item.details`]="{item}">
                        <v-btn
                          text
                          color="primary"
                          small
                          @click="openAddressDetails(item)"
                        >
                          Wyświetl zlecenia
                        </v-btn>
                      </template>
                    </v-data-table>
                  </td>
                </template>
                <template #[`item.data-table-expand`]="{ item }">
                  <td>
                    <v-btn
                      v-if="item.addressData && item.addressData.length"
                      icon
                      small
                    >
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </td>
                </template>
                <template #[`item.totalGrossValue`]="{item}">
                  {{ formatValue(item.totalGrossValue, 'price') }}
                </template>
                <template #[`item.trend`]="{item}">
                  <v-sparkline
                    :value="getTrendValues(item.monthData)"
                    line-width="2"
                    smooth
                    :padding="12"
                    stroke-linecap="round"
                    type="trend"
                    auto-line-width
                    auto-draw
                  />
                </template>
              </v-data-table>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <div class="detail-section__wrapper mb-5">
            <div class="detail-section__header">
              Zlecenia z podziałem na miesiące
            </div>
            <div class="detail-section__content">
              <v-data-table
                v-if="expandedClients.length"
                :item-class="() => 'table__row'"
                :headers="monthParameters"
                :items="monthsData"
                :items-per-page="-1"
                fixed-header
                hide-default-footer
              >
                <template #[`item.totalGrossValue`]="{item}">
                  {{ formatValue(item.totalGrossValue, 'price') }}
                </template>
              </v-data-table>
              <div
                v-else
                class="pa-4"
              >
                Brak danych do wyświetlenia
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import FiltersBar from '../../components/Filters/FiltersBar'
import { mapState, mapActions } from 'vuex'
import { formatValue } from '../../utils/typesEnum'
import DateTime from 'luxon/src/datetime'

const monthParameters = [
  { text: 'Rok', value: 'year' },
  { text: 'Msc', value: 'month' },
  { text: 'Zlecenia', value: 'ordersCount' },
  { text: 'Kwota', value: 'totalGrossValue' },
]

const subtableHeaders = [
  { text: 'Adres', value: 'name' },
  { text: 'Liczba zleceń', value: 'ordersCount' },
  { text: 'Kwota', value: 'totalGrossValue' },
  { text: '', value: 'details' },
]

export default {
  components: {
    Header,
    FiltersBar
  },
  data: () => ({
    tableName: 'clientsReports',
    expandedClients: [],
    monthParameters,
    subtableHeaders
  }),
  computed: {
    ...mapState({
      isProcessing: state => state.clientsReports.isProcessing,
      clients: state => state.clientsReports.items,
      dateRange: state => state.clientsReports.dateRange,
      showFilters: state => state.clientsReports.showFilters,
    }),
    headers () {
      return this.$store.getters['tables/getTableConfig'](this.tableName)?.parameters || []
    },
    emptyMonthsData() {
      const [start, end] = this.dateRange
      const diffInMonths = Math.ceil(DateTime.fromISO(end || start).diff(DateTime.fromISO(start), 'months').toObject().months)
      return new Array(diffInMonths).fill({}).map((_, index) => {
        const date = new Date(start)
        return {
          year: date.getFullYear(),
          month: date.getMonth() + index + 1, // one for rails month order (january is 1)
          ordersCount: 0,
          totalGrossValue: 0
        }
      })
    },
    monthsData() {
      const [data] = this.expandedClients
      if (!data) return []
      const { monthData } = data
      return this.emptyMonthsData.map((emptyMonth) => {
        const sample = monthData.find(month => month.month === emptyMonth.month && month.year === emptyMonth.year)
        return sample || emptyMonth
      })
    }
  },
  mounted () {
    this.setTableConfig({ tableName: this.tableName })
  },
  methods: {
    ...mapActions({
      fetchReports: 'clientsReports/getItems',
      setTableConfig: 'tables/setTableConfig',
      setClientOrdersFilters: 'tables/setTableFilters',
      setClientOrdersDateRange: 'clientOrders/setTableDate',
    }),
    formatValue,
    expandRow (slot) {
      if (slot.item.addressData?.length) slot.expand(!slot.isExpanded)
    },
    getTrendValues(samples) {
      return this.emptyMonthsData.map((monthData) => {
        const sample = samples.find(samp => samp.month === monthData.month && samp.year === monthData.year)
        return sample?.ordersCount || 0
      })
    },
    openAddressDetails(address) {
      this.setTableConfig({ tableName: 'clientOrders', disableFetch: true })
      this.setClientOrdersDateRange({ date: [...this.dateRange] })
      this.setClientOrdersFilters({ filters: [{ name: 'addressIds', filterBy: [{ name: address.name, value: address.addressId }] }], tableName: 'clientOrders' })
      this.$router.push({ name: 'singleClient', params: { id: address.clientId } })
    }
  }
}
</script>

<style lang="scss" scoped>
.report-viewport::v-deep .v-data-table__wrapper {
  max-height: calc(100vh - 294px)
}
.report-viewport.filters-open::v-deep .v-data-table__wrapper {
  max-height: calc(100vh - 380px)
}
</style>
