import Base from './base'
import api from '../api/v1'
import get from 'lodash/get'
import { downloadFile, getCoursesStatistics } from '../utils'
import { decamelize } from 'humps'

const courseMatchesFiltersCriteria = (item, filters) => {
  return filters.every(filter => {
    const { filterBy, propPath, name } = filter
    const filterValue = filterBy.value ?? filterBy
    if (filterValue === 'Wymiana') return !!item.swapCourse // courseType in courses
    if (name === 'withoutCards') {
      const { order } = item.swapCourse || item
      return !order.bdoNumber === !!filterValue
    }
    return get(item, propPath) === filterValue
  })
}

const base = new Base('courses', courseMatchesFiltersCriteria)

const mutations = {
  SELECT_ALL_ITEMS (state, courses) {
    state.selectedItems = courses
      .map(course => course.courseStatus === 'Zaplanuj' && course.id)
      .filter(course => !!course)
  },
}
const actions = {
  editCourseDueDate ({ commit, dispatch }, { id, dueDate }) {
    commit('SET_PROCESSING', true)
    const params = { dueDate }
    api.updateItem({ tableName: 'courses', id, params })
      .then(() => {
        dispatch('layout/closeDialog', null, { root: true })
        dispatch('snackbar/showSnackbar', {
          message: ['Zmieniono datę realizacji kursu']
        }, { root: true })
      })
      .finally(() => {
        commit('SET_PROCESSING', false)
      })
  },
  editMultipleCoursesDueDate ({ state, commit, dispatch }, { dueDate }) {
    commit('SET_PROCESSING', true)
    const params = { dueDate, courseIds: state.selectedItems }
    api.postponeCourses(params)
      .then(() => {
        commit('courses/REMOVE_ITEMS', state.selectedItems, { root: true })
        dispatch('layout/closeDialog', null, { root: true })
        dispatch('snackbar/showSnackbar', {
          message: ['Zmieniono daty realizacji kursów']
        }, { root: true })
      })
      .finally(() => {
        dispatch('toggleMultiselectStatus', false)
        commit('SET_PROCESSING', false)
      })
  },
  editAllCoursesDueDate ({ state, commit, dispatch }, { dueDate }) {
    commit('SELECT_ALL_ITEMS', state.items)
    dispatch('editMultipleCoursesDueDate', { dueDate })
  },
  exportAllCoursesSpreadsheet ({ state, commit, dispatch }) {
    commit('SELECT_ALL_ITEMS', state.items)
    dispatch('exportCoursesSpreadsheet',)
    commit('UNSELECT_ALL_ITEMS')
  },
  exportCoursesSpreadsheet ({ state, commit, rootState, rootGetters }) {
    commit('SET_PROCESSING', true)
    const courseIds = state.selectedItems

    const { order, filters, departmentId } = rootGetters['tables/getTableParameters']('courses').params

    const courseHeaders = rootState.tables.courses.parameters
      .filter(header => header.show && !['moveToRelatedCourse', 'select', 'actions'].includes(header.name))
      .map(header => decamelize(header.name))
    // todo headers keys must be unified between FE and BE

    api.exportCoursesSpreadsheet({
      departmentId,
      order,
      courseHeaders,
      courseIds,
      filters
    }).then((resp) => {
      const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'text/csv' }))
      downloadFile(url, `Kursy ${state.dateRange}`)
      commit('UNSELECT_ALL_ITEMS')
    })
      .finally(() => {
        commit('courses/SET_PROCESSING', false, { root: true })
      })
  },
  generateKpoCard ({ commit, dispatch }, kpoData) {
    commit('SET_PROCESSING', true)
    api.generateKpoCard(kpoData)
      .then(() => {
        dispatch('snackbar/showSnackbar', {
          message: ['Wygenerowano KPOK']
        }, { root: true })
      })
      .finally(() => {
        commit('SET_PROCESSING', false)
      })
  },
}
const getters = {
  getStatistics: (state) => getCoursesStatistics(state.items)
}

base.mutations = {
  ...base.mutations,
  ...mutations
}
base.actions = {
  ...base.actions,
  ...actions
}
base.getters = {
  ...base.getters,
  ...getters
}

export default base
