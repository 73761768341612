<template>
  <v-row>
    <v-col class="pt-0">
      <div class="contact-details">
        <div class="detail-section__header contact-details__header">
          Lokalizacje
          <div class="d-flex">
            <v-text-field
              v-model="query"
              placeholder="Szukaj adresu"
              outlined
              clearable
              hide-details
              dense
              autocomplete="off"
              class="contact-details__input"
            >
              <template #prepend-inner>
                <Icon
                  name="search"
                  size="small"
                  class="mr-2 mt-1"
                />
              </template>
            </v-text-field>

            <ActionButtons
              v-if="actions.length"
              :actions="actions"
              :item="client"
            />
          </div>
        </div>

        <v-divider />

        <div
          v-shortkey="navigateTableShortkeys"
          @shortkey="navigateTable"
        >
          <v-data-table
            hide-default-footer
            :headers="headers"
            :items="addressesFound"
            :items-per-page="-1"
            :value="selectedAddress"
            class="addresses-table"
          >
            <template #item="{ item, index }">
              <TableRow
                table-name="addresses"
                :item="item"
                :header="headers"
                :actions="filteredRowActions"
                :is-selected="item.id === selectedId"
                @click.native="selectAddress(item, index)"
                @contextmenu.native.prevent="handleAddressContextMenu($event, item)"
              />
            </template>
          </v-data-table>
        </div>
      </div>
    </v-col>
    <v-col
      v-if="isCompanyClient"
      cols="12"
      xl="4"
    >
      <div class="contact-details">
        <div class="detail-section__header contact-details__header">
          Produkty lokalizacji
          <ActionButtons
            v-if="selectedAddress.length"
            :actions="[{...allActions.addNewProduct, shortkey :{key: ['f7']}}]"
            :item="selectedAddress[0]"
          />
        </div>

        <v-divider />

        <v-data-table
          hide-default-footer
          :headers="productHeaders"
          :items="products"
          :items-per-page="-1"
          class="products-table"
        >
          <template #item="{ item }">
            <TableRow
              table-name="products"
              :item="item"
              :header="productHeaders"
              :actions="productActions(item)"
              @contextmenu.native.prevent="handleProductContextMenu($event, item)"
            />
          </template>
        </v-data-table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ActionButtons from '../../Elements/ActionButtons'
import actions from '../../../const/actions'
import { clientAddresses } from '../../../const/views/administration/clients'
import TableRow from '../../Table/TableRow'
import { mapState, mapActions } from 'vuex'
import { navigateTableShortkeys } from '../../../const/shortKeys'

const productHeaders = [
  { name: 'containerType', text: 'Typ kontenera', value: 'containerType.name', sortable: false },
  { name: 'debrisType', text: 'Typ odpadu', value: 'debrisType.displayName', sortable: false },
  { name: 'netValue', text: 'Cena netto', value: 'netValue', sortable: false },
  { name: 'actions', sortable: false },
]

export default {
  components: {
    TableRow,
    ActionButtons
  },
  props: {
    client: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    ...clientAddresses, // { headers, rowActions, contextMenuActions, actions }
    productHeaders,
    allActions: actions,
    selectedId: null,
    selectedAddress: [],
    query: '',
    navigateTableShortkeys
  }),
  computed: {
    ...mapState(({
      addresses: state => state.client.entity.addresses
        .map((address, index) => ({
          ...address,
          paymentDueTime: address.paymentDueDays && (address.paymentDueDays + ' dni'),
          sequence: index + 1
        })),
      clientType: state => state.client.entity.clientType
    })),
    isCompanyClient () {
      return this.client.clientType === 'Firma'
    },
    addressesFound () {
      return this.query
        ? [...this.addresses].filter(address => address.formattedAddress.toLowerCase().includes(this.query.toLowerCase()))
        : this.addresses
    },
    products () {
      return this.addressesFound.find(address => address.id === this.selectedId)?.products || []
    },
    filteredRowActions() {
      return this.rowActions.filter(action => Object.hasOwn(action, 'condition') ? action.condition.every(action => action(this.client)) : true)
    }
  },
  mounted () {
    if (this.addressesFound.length) this.selectAddress(this.addressesFound[0])
  },
  methods: {
    ...mapActions({
      setDialog: 'layout/setDialog',
      setContextMenu: 'layout/setContextMenu',
    }),
    showConfirmationDialog (address) {
      this.setDialog({ data: actions.deleteClientAddress, item: address })
    },
    selectAddress (address) {
      this.selectedAddress = [address]
      this.selectedId = address.id
    },
    navigateTable (event) {
      const { addressesFound } = this
      const [selectedAddress] = this.selectedAddress
      const currentIndex = addressesFound.findIndex(address => address.id === selectedAddress.id)
      if (event.srcKey === 'up' && addressesFound[currentIndex - 1]) {
        this.selectAddress(addressesFound[currentIndex - 1])
      } else if (event.srcKey === 'down' && addressesFound[currentIndex + 1]) {
        this.selectAddress(addressesFound[currentIndex + 1])
      }
    },
    handleAddressContextMenu (e, item) {
      const actions = this.contextMenuActions.filter(action => action.excluded !== this.clientType)
      this.setContextMenu({ actions, item, position: { x: e.clientX, y: e.clientY } })
    },
    handleProductContextMenu (e, item) {
      this.setContextMenu({ actions: this.productActions(item), item, position: { x: e.clientX, y: e.clientY } })
    },
    productActions (product) {
      return [
        { ...actions.editProduct, props: { editingProduct: product } },
        { ...actions.deleteProduct }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.products-table::v-deep td:nth-of-type(2) .table__column--row {
  height: 100%;
  padding: 8px 0;
  text-overflow: clip;
  white-space: normal;
  line-height: 18px;
}
.addresses-table::v-deep tr td:last-of-type > div {
  padding: 0 45px !important;
}
</style>
