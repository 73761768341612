<template>
  <div>
    <ReportTable
      class="full-height mb-10"
      :items="revenue.revenue.data"
      :headers="headers"
      title="Płatności"
      table-name="revenue"
      report="revenue"
    />
  </div>
</template>

<script>
import ReportTable from './Tables/ReportTable'
import { mapState } from 'vuex'

export default {
  components: {
    ReportTable
  },
  data () {
    return {
      headers: [
        { text: 'Lp', value: 'sequence' },
        { text: 'Nr zlecenia', value: 'refNumber' },
        { text: 'Data realizacji', value: 'dueDate' },
        { text: 'Użytkownik', value: 'user' },
        { text: 'Płatność w biurze', value: 'officePayment' },
        { text: 'Klient', value: 'clientName' },
        { text: 'Kierowca', value: 'driver' },
        { text: 'Adres zlecenia', value: 'address' },
        { text: 'Pobrano', value: 'depositedAmount', summarize: true },
        { text: 'Do zapłaty', value: 'totalGrossValue', summarize: true }
      ]
    }
  },
  computed: {
    ...mapState({
      revenue: state => state.reports.revenue
    })
  }
}
</script>
