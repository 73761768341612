import actions from '../actions'

export default {
  header: [],
  table: {
    maximumDateRange: 1096,
    isWebSocketHandled: true,
    sorting: {
      sortBy: 'createdAt',
      sortDesc: true
    },
    filters: [
      { name: 'paid', text: '', options: '', filterBy: '', propPath: 'paid', hide: true },
      { name: 'batchInvoices', text: '', options: '', filterBy: '', propPath: '', hide: true },
      { name: 'clientIds', text: 'Klient', options: 'clients', filterBy: [], propPath: 'clientId', multiple: true },
      { name: 'settlementType', text: 'Typ dokumentu', options: 'settlementTypes', filterBy: '', propPath: 'settlementType' },
      { name: 'paymentTypes', text: 'Typ płatności', options: 'paymentTypes', filterBy: [], propPath: 'paymentType', multiple: true },
      { name: 'paymentStatuses', text: 'Status płatności', options: 'paymentStatuses', filterBy: [], propPath: 'paymentStatus', multiple: true }
    ],
    parameters: [
      { name: 'select', text: '', show: true, value: 'select', sortable: false },
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'invoiceConnectionType', text: 'Tryb', value: 'invoiceConnectionType', show: true },
      { name: 'invoiceNumber', text: 'Nr faktury', value: 'invoiceNumber', show: true },
      { name: 'buyerName', text: 'Nabywca', value: 'buyerName', show: true },
      { name: 'nip', text: 'Nr NIP', value: 'nip', show: true },
      { name: 'issueDate', text: 'Data sprzedaży', value: 'issueDate', show: true },
      { name: 'paymentType', text: 'Typ płatności', value: 'paymentType', show: true },
      { name: 'paidValue', text: 'Zapłacono', value: 'paidValue', show: true },
      { name: 'grossValue', text: 'Kwota brutto', value: 'grossValue', show: true },
      { name: 'paymentStatus', text: 'Status płatności', value: 'paymentStatus', show: true },
      { name: 'paymentDate', text: 'Data płatności', value: 'paymentDate', show: true },
      { name: 'netValue', text: 'Kwota netto', value: 'netValue', show: true },
      { name: 'optimaSynchronization', text: 'Synchronizacja Optima', value: 'optimaSynchronization', show: true, sortable: false },
      { name: 'notes', text: 'Uwagi', value: 'notes', show: true, sortable: false },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [
      { ...actions.deleteInvoice, icon: 'usun' },
      { ...actions.downloadInvoice, icon: 'pobierz' }
    ],
    menu: [
      { ...actions.deleteInvoice },
      { ...actions.downloadInvoice },
      { ...actions.correctInvoice },
      { ...actions.generateInvoice, text: 'Wystaw fakturę do paragonu' }
    ]
  },
  details: {
    title: 'Dokument nr {{invoiceNumber}}',
    actions: [
      { ...actions.generateInvoice, text: 'Wystaw fakturę do paragonu' },
      { ...actions.deleteInvoice, redirect: true },
      { ...actions.correctInvoice },
      { ...actions.downloadInvoice, filled: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        tableName: 'invoices',
        fields: [
          { name: 'invoiceType', text: 'Typ faktury', value: 'settlementType', col: 3, type: 'textField' },
          { name: 'invoiceNumber', text: 'Nr faktury', value: 'invoiceNumber', type: 'textField', col: 3 },
          { name: 'buyerName', text: 'Nabywca', value: 'buyerName', type: 'textField', col: 3 },
          { name: 'address', text: 'Adres', value: 'address', type: 'textField', col: 3 },
          { name: 'city', text: 'Miejscowość', value: 'city', type: 'textField', col: 3 },
          { name: 'postalCode', text: 'Kod pocztowy', value: 'postalCode', type: 'textField', col: 3 },
          { name: 'nip', text: 'Nr NIP', value: 'nip', type: 'textField', col: 9 },
          { name: 'exportDate', text: 'Data eksportu', value: 'exportDate', type: 'date', col: 3 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'date', col: 3 },
          { name: 'issueDate', text: 'Data sprzedaży', value: 'issueDate', type: 'textField', col: 3 },
          { name: 'paymentDate', text: 'Data płatności', value: 'paymentDate', type: 'textField', col: 3 },
          { name: 'paymentDueDate', text: 'Termin płatności', value: 'paymentDueDate', type: 'textField', col: 3 },
          { name: 'netValue', text: 'Wartość netto', value: 'netValue', type: 'textField', col: 3 },
          { name: 'grossValue', text: 'Wartość brutto', value: 'grossValue', type: 'textField', col: 3 },
          { name: 'paidValue', text: 'Zapłacono', value: 'paidValue', type: 'textField', col: 3 },
          { name: 'paymentType', text: 'Typ płatności', value: 'paymentType', type: 'textField', col: 3 },
          { name: 'paymentStatus', text: 'Status płatności', value: 'paymentStatus', type: 'textField', col: 3 },
          { name: 'optimaSynchronization', text: 'Synchronizacja Optima', value: 'optimaSynchronization', type: 'textField', col: 3 },
          { name: 'notes', text: 'Uwagi', value: 'notes', type: 'textField', col: 9 }
        ]
      },
      {
        name: 'items',
        text: 'Składowe faktury',
        type: 'left',
        component: 'DetailsSectionInvoiceItems',
        tableName: 'invoices',
        fields: [
          { name: 'sequence', value: 'sequence', text: 'Lp.' },
          { name: 'name', value: 'name', text: 'Nazwa' },
          { name: 'pkwiu', value: 'pkwiu', text: 'PKWiU' },
          { name: 'quantity', value: 'quantity', text: 'Liczba' },
          { name: 'netValue', value: 'netValue', text: 'Cena netto' },
          { name: 'vatPercentage', value: 'vatPercentage', text: 'Stawka vat' },
          { name: 'vatValue', value: 'vatValue', text: 'Kwota vat' },
          { name: 'grossValue', value: 'grossValue', text: 'Cena brutto' }
        ]
      },
      {
        name: 'orders',
        text: 'Zlecenia',
        type: 'right',
        multiple: 'orders',
        component: 'DetailsSectionOrders',
        fields: [
          { name: 'refNumber', value: 'refNumber', text: 'Numer referencyjny zlecenia', type: 'textField', to: 'singleOrder', col: 6 },
          { name: 'containerType', value: 'containerType.name', text: 'Rozmiar kontenera', type: 'textField', col: 6 },
          { name: 'leaveDate', value: 'leaveDate', text: 'Data podstawienia', type: 'textField', col: 6 },
          { name: 'pickupDate', value: 'pickupDate', text: 'Data zabrania', type: 'textField', col: 6 },
          { name: 'address', value: 'address.formattedAddress', text: 'Lokalizacja', type: 'textField', col: 12 }
        ]
      },
      {
        name: 'documents',
        text: 'Powiązane dokumenty',
        type: 'right',
        fields: [
          { name: 'bill', value: 'bill.invoiceNumber', text: 'Paragon', type: 'textField', to: 'singleInvoice', idPath: 'bill.id', col: 12 },
          { name: 'invoice', value: 'invoice.invoiceNumber', text: 'Faktura imienna', type: 'textField', to: 'singleInvoice', idPath: 'invoice.id', col: 12 }
        ]
      },
      {
        name: 'corrects',
        text: 'Korekty',
        type: 'right',
        multiple: 'correctionInvoices',
        component: 'DetailsSectionList',
        fields: [
          { name: 'invoiceNumber', value: 'invoiceNumber', text: 'Nr korekty', type: 'textField', to: 'singleInvoice', idPath: 'id', col: 6 },
          { name: 'createdAt', value: 'createdAt', text: 'Utworzono', type: 'textField', col: 6 },
          { name: 'netValue', value: 'netValue', text: 'Wartość Netto', type: 'textField', col: 6 },
          { name: 'grossValue', value: 'grossValue', text: 'Wartość Brutto', type: 'textField', col: 6 },
        ]
      },
      {
        name: 'mainInvoice',
        text: 'Główny dokument',
        type: 'right',
        fields: [
          { name: 'invoiceNumber', value: 'mainInvoice.invoiceNumber', text: 'Nr faktury', type: 'textField', to: 'singleInvoice', idPath: 'mainInvoice.id', col: 6 },
          { name: 'createdAt', value: 'mainInvoice.createdAt', text: 'Utworzono', type: 'textField', col: 6 },
          { name: 'netValue', value: 'mainInvoice.netValue', text: 'Wartość Netto', type: 'textField', col: 6 },
          { name: 'grossValue', value: 'mainInvoice.grossValue', text: 'Wartość Brutto', type: 'textField', col: 6 },
        ]
      }
    ]
  }
}
