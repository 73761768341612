<template>
  <div>
    <div
      v-if="showFilters"
      class="filters__options"
    >
      <template v-for="(filter, index) in filters">
        <div
          v-if="filter.options || filter.switch || filter.threeWaySwitch"
          :key="filter.name"
          class="input--select px-3 pt-4"
          :class="{'input--multiselect':filter.multiple, 'input--switch':filter.switch }"
        >
          <template v-if="filter.switch">
            <div class="input-label">
              {{ filter.text }}
            </div>
            <v-switch
              v-model="filter.filterBy"
              :false-value="filter.falseValue !== undefined ? filter.falseValue : false"
              :true-value="{value: filter.trueValue || true, name: filter.text}"
              hide-details
              @change="changeFilters()"
            />
          </template>
          <v-autocomplete
            v-else-if="filter.threeWaySwitch"
            v-model="filter.filterBy"
            :items="[
              {value: true, name: filter.trueLabel || 'Tak'},
              {value: false, name: filter.falseLabel || 'Nie'}
            ]"
            v-bind="autocompleteProps(filter)"
            @change="changeFilters(index)"
          />
          <v-autocomplete
            v-else
            v-model="filter.filterBy"
            :items="filter.options"
            :search-input.sync="searchQuery[index]"
            v-bind="autocompleteProps(filter)"
            @change="changeFilters(index)"
          >
            <template #selection="{ index, item }">
              <span
                v-if="filter.filterBy.length < 2 || !filter.multiple"
                class="input--long mr-1 ml-1"
              >
                {{ item.name }}
              </span>
              <span
                v-else-if="index === 0"
                class="mr-1 ml-1"
              >
                {{ filtersDeclension(filter.filterBy.length) }}
              </span>
            </template>
          </v-autocomplete>
        </div>
      </template>
    </div>
    <div
      v-if="filters.length"
      class="filters__selected-row"
    >
      <v-switch
        v-if="isAnyFilterSelected(tableName)"
        v-model="filtersEnabled"
        hide-details
        class="mt-0"
      />
      <div class="filters__selected">
        <template v-for="filter in filters">
          <template v-if="Array.isArray(filter.filterBy)">
            <v-chip
              v-for="singleFilter in filter.filterBy"
              :key="singleFilter.name"
              :value="singleFilter.value || singleFilter"
              v-bind="chipProps"
              @click:close="unpinMultipleFilter(filter.name, singleFilter)"
            >
              {{ chipLabel(singleFilter) }}
            </v-chip>
          </template>
          <v-chip
            :key="filter.name"
            v-else-if="filter.filterBy"
            :value="filter.filterBy"
            v-bind="chipProps"
            @click:close="filter.filterBy = ''"
            @update:active="changeFilters()"
          >
            {{ chipLabel(filter.filterBy) }}
          </v-chip>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import filtersMixin from '../../mixins/filtersMixin'
import { mapState, mapActions, mapGetters } from 'vuex'
import { declensionName } from '../../utils'

export default {
  mixins: [filtersMixin], // filters
  data: () => ({
    searchQuery: []
  }),
  props: {
    tableName: {
      type: String,
      required: true,
    },
    disableFetch: {
      type: Boolean,
      deafult: false,
    },
  },
  computed: {
    ...mapState({
      showFilters (state) {
        return state[this.tableName].showFilters
      },
      chipProps () {
        return {
          class: ['ma-2', { disabled: !this.filtersEnabled }],
          closeIcon: 'mdi-close',
          color: 'primary',
          close: true,
          outlined: true,
        }
      },
    }),
    ...mapGetters({
      isAnyFilterSelected: 'tables/isAnyFilterSelected',
    }),
    config () {
      return this.$store.getters['tables/getTableConfig'](this.tableName)
    },
    allFilters () {
      return this.config.filters
    },
    filtersEnabled: {
      get () {
        return this.config.filtersEnabled
      },
      set (status) {
        this.setTableFiltersStatus({ status, tableName: this.tableName, noApi: this.disableFetch })
        if (this.disableFetch) this.$emit('fetch')
      },
    },
  },
  methods: {
    ...mapActions({
      setTableFilters: 'tables/setTableFilters',
      setTableFiltersStatus: 'tables/setTableFiltersStatus',
    }),
    changeFilters (index) {
      if (index || index === 0) this.searchQuery[index] = ''
      const { filters, tableName, disableFetch } = this
      this.setTableFilters({ filters, tableName, disableFetch })
      if (!this.isAnyFilterSelected(this.tableName)) this.setTableFiltersStatus({ status: true, tableName: this.tableName, noApi: true })
      if (this.disableFetch) this.$emit('fetch')
    },
    unpinMultipleFilter (filterName, filterBy) {
      const filterIndex = this.filters.findIndex((filter) => filter.name === filterName)
      const getFilterName = filter => filter.name || filter
      this.filters[filterIndex].filterBy = this.filters[filterIndex].filterBy.filter(
        (filter) => getFilterName(filter) !== getFilterName(filterBy)
      )
      this.changeFilters()
    },
    filtersDeclension (counter) {
      return declensionName(counter, 'element', 'elementy', 'elementów')
    },
    chipLabel (filterBy, maxLength = 50) {
      const label = filterBy.name || filterBy
      if (label?.length > maxLength) return label.substring(0, maxLength).trim() + '...'
      return label
    },
    autocompleteProps (filter) {
      return {
        outlined: true,
        hideDetails: true,
        itemText: 'name',
        placeholder: 'Wybierz',
        appendIcon: 'mdi-chevron-down',
        returnObject: true,
        menuProps: { maxWidth: 'unset' },
        dense: true,
        clearable: true,
        disabled: filter.disabled,
        filter: filter.specialFilter,
        label: filter.text,
        multiple: filter.multiple
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.filters__options::v-deep .v-list-item__title {
  max-width: 450px;
}
::v-deep .v-list-item__content > .v-list-item__title {
  white-space: wrap !important;
  text-overflow: clip !important;
  white-space: break-spaces !important;
}
</style>
