<template>
  <div>
    <ReportTable
      class="mb-10"
      :items="income.debrisOrders.data"
      :headers="headers"
      title="Transport odpadów"
      table-name="debrisOrders"
      report="income"
    />

    <ReportTable
      class="mb-4"
      :items="income.aggregateOrders.data"
      :headers="headers"
      title="Transport kruszywa"
      table-name="aggregateOrders"
      report="income"
    />
  </div>
</template>

<script>
import ReportTable from './Tables/ReportTable'
import { mapState } from 'vuex'

export default {
  components: {
    ReportTable
  },
  data () {
    return {
      headers: [
        { text: 'Lp', value: 'sequence' },
        { text: 'Nr zlecenia', value: 'refNumber' },
        { text: 'Klient', value: 'client' },
        { text: 'Adres zlecenia', value: 'address' },
        { text: 'Pobrał', value: 'chargedBy' },
        { text: 'Rola', value: 'chargedByType' },
        { text: 'Typ płatności', value: 'paymentType' },
        { text: 'Cena netto', value: 'netValue', summarize: true },
        { text: 'Cena brutto', value: 'totalGrossValue', summarize: true }
      ]
    }
  },
  computed: {
    ...mapState({
      income: state => state.reports.income
    })
  }
}
</script>
