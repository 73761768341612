<template>
  <hsc-window-style-white>
    <hsc-window
      :title="modalTitle"
      position-hint="center"
      :close-button="true"
      :is-open.sync="showModal"
      class="floating-popup moving-popup"
      :style="`z-index: ${dialogZIndex}`"
      @move-start="updateDialogIndex"
    >
      <v-card
        min-width="400px"
        max-width="500px"
        color="white"
        light
      >
        <v-card-text>
          <template v-if="clientsRelatedToPhoneNumber.length">
            <div
              v-shortkey="selectActiveClientShortkeys"
              @shortkey="manageActiveClient"
            >
              <v-btn
                v-for="(client, index) in clientsRelatedToPhoneNumber"
                :ref="`button${index}`"
                :key="client.id"
                class="client-button mb-4"
                :class="{'not-selected' : index !== activeIndex}"
                color="primary"
                max-width="100%"
                dark
                block
                @click="openClientPanel(client.id)"
              >
                {{ client.name }}
              </v-btn>
            </div>
          </template>

          <template v-else-if="prospectId">
            <v-btn
              class="client-button mb-4"
              color="primary"
              max-width="100%"
              dark
              block
              @click="openProspect"
              v-shortkey="{enter: ['enter']}"
              @shortkey="openProspect"
            >
              Pokaż historię połączeń
            </v-btn>
          </template>

          <template v-else>
            <v-radio-group
              v-model="isProspectMode"
              class="pt-0 mt-0"
              row
            >
              <v-radio
                label="Prospekt"
                :value="true"
              />
              <v-radio
                label="Istniejacy klient"
                :value="false"
              />
            </v-radio-group>

            <v-form
              v-if="isProspectMode"
              @submit.prevent="addProspect"
              class="custom-form"
            >
              <v-textarea
                v-model="prospectNotes"
                class="my-2"
                placeholder="Wpisz notatkę do rozmowy"
                label="Notatka"
                outlined
                :rules="[rules.required]"
                :rows="3"
                hide-details
                auto-grow
                light
              />

              <v-btn
                color="primary"
                class="base-hover d-block ml-auto"
                type="submit"
                :loading="prospectProcessing"
              >
                Stwórz prospekt
              </v-btn>
            </v-form>

            <v-form
              v-else
              @submit.prevent="addToClient"
              class="custom-form pt-2"
            >
              <v-autocomplete
                outlined
                :items="clientsFromQuery"
                :rules="[rules.required]"
                label="Szukaj klienta"
                v-model="chosenClient"
                item-text="name"
                return-object
                hide-details
                :loading="clientProcessing"
                placeholder="Szukaj klienta"
                :search-input.sync="clientQuery"
                :filter="() => clientsFromQuery"
                @keydown="searchForClientsWithQuery"
                dense
              />

              <v-input
                :value="chosenClient.id"
                :rules="[rules.required]"
                hide-details
                class="validated-label mb-6"
              >
                <div class="mr-1">
                  Wybrany klient:
                </div>
                <span class="font-weight-medium">{{ chosenClient.name || '-' }}</span>
              </v-input>

              <v-text-field
                v-model="contactName"
                placeholder="Wpisz imię i nazwisko kontaktu"
                label="Imię i nazwisko kontaktu"
                class="mb-4"
                outlined
                hide-details
              />

              <v-btn
                color="primary"
                class="base-hover d-block ml-auto"
                type="submit"
                :disabled="!chosenClient.id"
                :loading="clientProcessing"
              >
                Dodaj do klienta
              </v-btn>
            </v-form>
          </template>
        </v-card-text>
      </v-card>
    </hsc-window>
  </hsc-window-style-white>
</template>

<script>
import { openNewWindow } from '../../utils'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import { selectActiveClientShortkeys, shortkeysNavigateBoundaries } from '../../const/shortKeys'

export default {
  data: () => ({
    clientsRelatedToPhoneNumber: [],
    prospectId: null,
    activeIndex: 0,
    prospectNotes: null,
    callStart: null,
    isProspectMode: true,
    clientQuery: '',
    clientsFromQuery: [],
    chosenClient: {},
    contactName: '',
    selectActiveClientShortkeys,
    rules,
    dialogZIndex: 10,
  }),
  channels: {
    CRMCallNotifyChannel: { received (data) { this.findClientsRaletedToPhoneNumber(data) } }
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      prospectProcessing: state => state.prospects.isProcessing,
      clientProcessing: state => state.client.isProcessing,
      incomingPhoneNumber: state => state.clientSearchDialog.incomingPhoneNumber,
    }),
    showModal: {
      get () {
        return this.$store.state.clientSearchDialog.isActiveModal
      },
      set (showModal) {
        this.setModalActivity(showModal)
        if (!showModal) {
          this.clientsRelatedToPhoneNumber = []
          this.prospectId = null
          this.prospectNotes = null
          this.clientQuery = ''
          this.clientsFromQuery = []
          this.chosenClient = {}
          this.contactName = ''
        } else {
          this.updateDialogIndex()
        }
      }
    },
    modalTitle () {
      return this.clientsRelatedToPhoneNumber.length
        ? `Klienci powiązani z numerem ${this.incomingPhoneNumber}`
        : `Prospekt klienta ${this.incomingPhoneNumber}`
    }
  },
  mounted () {
    this.$cable.connection.connect(`${process.env.VUE_APP_WSS_BASE_URL}?Authorization=${this.token}`)
    this.$cable.subscribe({ channel: 'CRMCallNotifyChannel' })
  },
  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/showSnackbar',
      createProspect: 'prospects/addNewItem',
      getClientsByQuery: 'client/getClientsByQuery',
      addNewContact: 'clients/addNewItem',
      setIncomingPhoneNumber: 'clientSearchDialog/setIncomingPhoneNumber',
      setModalActivity: 'clientSearchDialog/setModalActivity',
      updateDialogIndex: function (dispatch) {
        this.dialogZIndex = this.$store.state.layout.dialogTopIndex
        return dispatch('layout/updateDialogIndex')
      },
    }),
    searchForClientsWithQuery: debounce(function () {
      if (this.clientQuery && this.clientQuery.length > 2) {
        this.getClientsByQuery(this.clientQuery)
          .then((clients) => {
            this.clientsFromQuery = clients
          })
      }
    }, 500),
    findClientsRaletedToPhoneNumber (data) {
      this.callStart = new Date().toISOString()
      const { phone_number: phoneNumber, clients, prospect_id: prospectId } = data
      this.setIncomingPhoneNumber(phoneNumber)
      this.prospectId = prospectId
      this.clientsRelatedToPhoneNumber = clients
      this.showModal = true
      this.activeIndex = 0
    },
    openClientPanel (id) {
      const path = `/clients/${id}`
      const query = {
        phoneNumber: this.incomingPhoneNumber,
        callStart: this.callStart
      }
      openNewWindow.call(this, { path, query })
    },
    openProspect () {
      const path = `/prospects/${this.prospectId}`
      openNewWindow.call(this, { path })
    },
    manageActiveClient (event) {
      if (event.srcKey === 'enter') {
        this.openClientPanel(this.clientsRelatedToPhoneNumber[this.activeIndex].id)
      } else {
        const { activeIndex, clientsRelatedToPhoneNumber } = this
        this.activeIndex = shortkeysNavigateBoundaries(
          event.srcKey,
          activeIndex,
          clientsRelatedToPhoneNumber,
          ['up'],
          ['down']
        )
      }
    },
    addProspect () {
      const params = {
        notes: this.prospectNotes,
        callStart: this.callStart,
        phoneNumber: this.incomingPhoneNumber,
      }
      this.createProspect({ params })
        .then(() => {
          this.showModal = false
        })
    },
    addToClient () {
      const names = this.contactName.split(' ')
      const params = {
        clientId: this.chosenClient.id,
        phoneNumber: this.incomingPhoneNumber,
        role: 'Inny',
        lastName: names.pop() || null,
        firstName: names.join(' ') || null,
      }
      this.addNewContact({ params, endpoint: 'contacts' })
        .then(() => {
          this.openClientPanel(this.chosenClient.id)
          this.showModal = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.client-button::v-deep .v-btn__content {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  display: block;
  text-align: center;
}

.not-selected {
  opacity: 0.7
}
</style>
