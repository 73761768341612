import actions from '../../actions'
const addressName = address => address.name || address.formattedAddress

const isTransport = order => order?.courses?.[0]?.courseType === 'Transport'
const isNotTransport = order => order?.courses?.[0]?.courseType !== 'Transport'

export default {
  header: [
    { ...actions.checkPrice },
    { ...actions.addNewSwap },
    { ...actions.addNewPickup },
    { ...actions.addNewOrder, create: true }
  ],
  table: {
    maximumDateRange: 1096,
    isWebSocketHandled: true,
    sorting: {
      sortBy: 'refNumber',
      sortDesc: true
    },
    filters: [
      { name: 'clientIds', text: 'Klient', options: 'clients', filterBy: [], propPath: 'client.id', multiple: true },
      { name: 'addressIds', text: 'Lokalizacja', options: 'addresses', filterBy: '', nameGetter: addressName, propPath: 'address.id', multiple: true, disabled: true },
      { name: 'courseTypes', text: 'Typ kursu', options: 'courseTypes', filterBy: [], checkCourseType: true, multiple: true },
      { name: 'paymentTypes', text: 'Typ płatności', options: 'paymentTypes', filterBy: [], propPath: 'payment.paymentType', multiple: true },
      { name: 'settlementTypes', text: 'Typ dokumentu', options: 'settlementTypes', filterBy: [], propPath: 'payment.settlementType', multiple: true },
      { name: 'paymentStatuses', text: 'Status płatności', options: 'paymentStatuses', filterBy: [], propPath: 'payment.paymentStatus', multiple: true },
      { name: 'origins', text: 'Pochodzenie', options: 'orderOrigins', filterBy: [], propPath: 'order.origin', multiple: true },
      { name: 'withoutInvoices', text: 'Bez dokumentu', filterBy: '', options: '', falseValue: '', propPath: 'withoutDocuments', switch: true },
      { name: 'done', filterBy: '', propPath: 'done', hide: true }
    ],
    parameters: [
      { name: 'select', text: '', show: true, value: 'select', sortable: false },
      { name: 'refNumber', text: 'Nr zlecenia', value: 'refNumber', show: true },
      { name: 'clientName', text: 'Klient', value: 'client.name', clientLabel: true, show: true },
      { name: 'bdoNumber', text: 'Nr BDO', value: 'client.bdoNumber', show: true },
      { name: 'origin', text: 'Pochodzenie', value: 'origin', show: true },
      { name: 'leaveDate', text: 'Data podstawienia', value: 'leaveDate', show: true, isDateField: true },
      { name: 'pickupDate', text: 'Data zabrania', value: 'pickupDate', show: true, isDateField: true },
      { name: 'dueDate', text: 'Data transportu', value: 'dueDate', show: true, isDateField: true },
      { name: 'container', text: 'Kontener', value: 'containerWithType', show: true },
      { name: 'debrisType', text: 'Typ kruszywa', value: 'debrisType.displayName', show: true },
      { name: 'aggregateAmount', text: 'Liczba ton', value: 'aggregateAmount', show: true },
      { name: 'containerType', text: 'Zamówiony kontener', value: 'containerType.name', show: true },
      { name: 'address', text: 'Lokalizacja', value: 'address.formattedAddress', show: true },
      { name: 'paymentType', text: 'Typ płatności', value: 'payment.paymentType', show: true },
      { name: 'settlementType', text: 'Typ dokumentu', value: 'payment.settlementType', show: true },
      { name: 'totalGrossValue', text: 'Do zapłaty', value: 'payment.totalGrossValue', show: true },
      { name: 'paidValue', text: 'Pobrano', value: 'payment.paidValue', show: true },
      { name: 'paymentStatus', text: 'Status płatności', value: 'payment.paymentStatus', show: true },
      { name: 'blocked', text: 'Blokada', value: 'client.blocked', show: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [
      { ...actions.blockClient, icon: 'blockClient' },
      { ...actions.unblockClient, icon: 'unblockClient' },
      { ...actions.generateInvoice, icon: 'wygeneruj_fakture' }
    ],
    menu: [
      { ...actions.deleteOrder },
      { ...actions.generateInvoice },
      { ...actions.generatePaymentLink },
      { ...actions.generateCustomServiceInvoice },
      { ...actions.blockClient },
      { ...actions.unblockClient },
      { ...actions.editClient, idPath: 'client.id' }
    ]
  },
  quickView: {
    title: 'Zlecenie nr {{refNumber}}',
    text: 'Informacje ogólne',
    fields: [
      { name: 'courseType', text: 'Typ kursu', value: ['courses[0].courseType', 'courses[1].courseType'] },
      { name: 'clientName', text: 'Klient', value: 'client.name' },
      { name: 'containerType', text: 'Zamówiony typ kontenera', value: 'containerType.name' },
      { name: 'containerId', text: 'Nr kontenera', value: 'container.id' },
      { name: 'debrisTypeName', text: 'Typ odpadu', value: 'debrisType.displayName' },
      { name: 'bdoNumber', text: 'Nr KPO', value: 'bdoNumber' },
      { name: 'bdoStatus', text: 'Status KPO', value: 'bdoStatus' },
      { name: 'orderAddress', text: 'Adres zlecenia', value: 'address.formattedAddress' },
      { name: 'timeSlot', text: 'Przedział godzinowy', value: ['courses[0].timeSlot', 'courses[1].timeSlot'] },
      { name: 'driverFullName', text: 'Kierowca', value: ['courses[0].driver.fullName', 'courses[1].driver.fullName'] },
      { name: 'addressNotes', text: 'Notatka do kursu', value: ['courses[0].userNotes', 'courses[1].userNotes'] },
      { name: 'paymentType', text: 'Typ płatnosci', value: 'payment.paymentType' },
      { name: 'totalGrossValue', text: 'Kwota płatności', value: 'payment.totalGrossValue' },
      { name: 'paymentStatus', text: 'Status płatności', value: 'payment.paymentStatus' },
      { name: 'aggregateAmount', text: 'Liczba ton', value: 'order.aggregateAmount' }
    ],
    actions: []
  },
  details: {
    title: 'Zlecenie nr {{refNumber}}; {{swapOrderRefNumber}}',
    actions: [
      { ...actions.deleteOrder, redirect: true },
      { ...actions.blockClient },
      { ...actions.unblockClient },
      { ...actions.generateInvoice, filled: true },
      { ...actions.generateCustomServiceInvoice, filled: true }
    ],
    sections: [
      {
        conditionalFields: {
          reportedDebrisTypeName: { name: 'reportedDebrisTypeName', value: 'reportedDebrisType.displayName', col: 3 },
          reportedDebrisTypeId: { name: 'reportedDebrisTypeId', value: 'reportedDebrisType.code', type: 'selectDebrisType', col: 3, editable: true, rules: ['required'] },
        },
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'orders',
        conditionalWarnings: [
          {
            name: 'compareContainers',
            condition: 'isNotEqual',
            targetA: 'containerType.id',
            targetB: 'container.containerType.id',
            errorMessage: 'Wybrany kontener jest inny niż zamówiony'
          },
        ],
        get fields() {
          return [
            { name: 'orderId', text: 'Nr zlecenia', value: 'refNumber', type: 'textField', col: 3 },
            { name: 'createdAt', text: 'Data dodania zlecenia', value: 'createdAt', type: 'textField', col: 3 },
            { name: 'bdoNumber', text: 'Nr KPO/KPOK', value: 'bdoNumber', type: 'textField', col: 3, editable: true, rules: ['required'] },
            { name: 'bdoStatus', text: 'Status KPO/KPOK', value: 'bdoStatus', type: 'textField', col: 3 },
            { name: 'debrisTypeName', text: 'Typ odpadu', value: 'debrisType.displayName', type: 'textField', condition: isNotTransport, col: 3 },
            { name: 'debrisTypeId', text: 'Kod odpadu', value: 'debrisType.code', type: 'selectDebrisType', editable: true, rules: ['required'], condition: isNotTransport, col: 3 },
            { name: 'aggregateTypeId', text: 'Typ kruszywa', value: 'aggregateType.displayName', type: 'selectDebrisType', editable: true, rules: ['required'], condition: isTransport, col: 3 },
            { name: 'containerId', text: 'Nr kontenera', value: 'container.refNumberWithType', type: 'containerId', to: 'defaultSingleView', idPath: 'container.id', routeParams: { tableName: 'containers' }, col: 3, editable: true },
            { name: 'containerTypeId', text: 'Zamówiony typ kontenera', value: 'containerType.name', type: 'selectFromItems', options: 'containerTypes', editable: true, col: 3 },
            { ...this.conditionalFields.reportedDebrisTypeName, text: 'Raportowany typ odpadu', condition: isNotTransport },
            { ...this.conditionalFields.reportedDebrisTypeName, text: 'Raportowany typ kruszywa', condition: isTransport },
            { ...this.conditionalFields.reportedDebrisTypeId, text: 'Raportowany kod odpadu', condition: isNotTransport },
            { name: 'reportedWeight', text: 'Raportowana waga [T]', value: 'reportedWeight', col: 3, type: 'textField', editable: true, rules: ['required', 'number', { name: 'lowerThan', arg: 1001 }] },
            { name: 'phoneNumber', text: 'Dodatkowy numer telefonu', value: 'phoneNumber', type: 'textField', col: 3, editable: true, rules: ['phoneNumber'] },
            { name: 'aggregateAmount', text: 'Liczba ton kruszywa [T]', value: 'aggregateAmount', type: 'textField', col: 3, editable: true, condition: isTransport, rules: ['integer', { name: 'lowerThan', arg: 1001 }] },
            { name: 'userNotes', text: 'Notatka ogólna', value: 'userNotes', type: 'textArea', editable: true, col: 6 },
            { name: 'containerNotes', text: 'Notatka do kontenera', value: 'containerNotes', type: 'textArea', editable: true, col: 6 },
          ]
        }
      },
      {
        name: 'address',
        text: 'Szczegóły adresu',
        type: 'left',
        endpoint: 'orders',
        fields: [
          { name: 'department', text: 'Oddział', value: 'address.department.name', type: 'textField', col: 3 },
          { name: 'commune', text: 'Gmina', value: 'address.commune.name', type: 'textField', col: 3 },
          { name: 'phoneNumber', text: 'Numer telefonu', value: 'address.phoneNumber', type: 'textField', col: 3 },
          { name: 'email', text: 'Adres email', value: 'address.email', type: 'textField', col: 3 },
          { name: 'orderAddress', text: 'Adres zlecenia', value: 'address.formattedAddress', type: 'textField', col: 6 },
          { name: 'addressName', text: 'Nazwa lokalizacji', value: 'address.name', type: 'textField', col: 6 },
          { name: 'addressNotes', text: 'Notatka do lokalizacji', value: 'address.notes', type: 'textArea', col: 12 },
          { name: 'addressRemarks', text: 'Uwagi dla spedytora', value: 'address.remarks', type: 'textArea', col: 12 }
        ]
      },
      {
        name: 'courses',
        text: 'Szczegóły kursów',
        multiple: 'courses',
        type: 'left',
        endpoint: 'courses',
        component: 'DetailsSectionCourses',
        fields: [
          { name: 'courseType', text: 'Typ kursu', value: 'courseType', type: 'textField', col: 3 },
          { name: 'courseId', text: 'Nr kursu', value: 'id', type: 'textField', col: 3 },
          { name: 'courseStatus', text: 'Status kursu', value: 'courseStatus', type: 'textField', extraClass: 'course-status', col: 3 },
          { name: 'dueDate', text: 'Data realizacji', value: 'dueDate', type: 'date', col: 3, editable: { path: 'courseStatus', value: 'Zaplanuj' }, rules: ['required'] },
          { name: 'timeSlot', text: 'Przedział godzinowy', value: 'timeSlot', type: 'timeSlot', col: 3, editable: true, rules: ['required'] },
          { name: 'driverFullName', text: 'Kierowca', value: 'driver.fullName', type: 'textField', col: 3 },
          { name: 'userNotes', text: 'Notatka do kursu', value: 'userNotes', type: 'textArea', col: 12, editable: true },
          { name: 'driverNotes', text: 'Notatka kierowcy', value: 'driverNotes', type: 'textArea', col: 12 }
        ]
      },
      {
        conditionalFields: {
          discountFields: { name: 'discount', value: 'payment.discount', type: 'textField', col: 3, editable: true, rules: ['required', 'money', { name: 'lowerThan', arg: 10000001 }], unit: ' [zł]' },
          discountJustification: { name: 'discountJustification', value: 'payment.discountJustification', type: 'textField', col: 3, editable: true, },
        },
        name: 'payment',
        text: 'Szczegóły płatności',
        type: 'left',
        endpoint: 'payments',
        idPath: 'payment.id',
        tableName: 'orders',
        get fields() {
          return [
            { name: 'paymentType', text: 'Typ płatności', value: 'payment.paymentType', type: 'paymentType', col: 3, editable: true, rules: ['required'] },
            { name: 'paymentDueDays', text: 'Temin przelewu', value: 'payment.paymentDueDays', type: 'paymentType', col: 3, editable: true },
            { name: 'settlementType', text: 'Typ dokumentu', value: 'payment.settlementType', type: 'selectFromFilters', options: 'settlementTypes', col: 3, editable: true, rules: ['required'] },
            { name: 'paymentStatus', text: 'Status płatności', value: 'payment.paymentStatus', type: 'selectFromFilters', options: 'paymentStatuses', col: 3, editable: true, rules: ['required'] },
            { name: 'unitDebrisNetValue', text: 'Cena netto kruszywa za tonę', type: 'unitPrice', value: 'payment.unitDebrisNetValue', editable: true, col: 3, condition: isTransport },
            { name: 'unitDebrisGrossValue', text: 'Cena brutto kruszywa za tonę', type: 'unitPrice', value: 'payment.unitDebrisGrossValue', editable: true, col: 3, condition: isTransport },
            { name: 'debrisNetValue', text: 'Wartość kontenera netto', value: 'payment.debrisNetValue', type: 'netGrossPrice', priceLabel: 'debris', editable: true, col: 3 },
            { name: 'debrisGrossValue', text: 'Wartość kontenera brutto', value: 'payment.debrisGrossValue', type: 'netGrossPrice', priceLabel: 'debris', editable: true, gross: true, col: 3 },
            { name: 'transportNetValue', text: 'Cena netto transportu', value: 'payment.transportNetValue', type: 'netGrossPrice', priceLabel: 'transport', editable: true, col: 3 },
            { name: 'transportGrossValue', text: 'Cena brutto transportu', value: 'payment.transportGrossValue', type: 'netGrossPrice', priceLabel: 'transport', editable: true, gross: true, col: 3 },
            { name: 'additionalNetValue', text: 'Wartość netto usług', value: 'payment.additionalNetValue', type: 'netGrossPrice', priceLabel: 'additional', editable: true, col: 3 },
            { name: 'additionalGrossValue', text: 'Wartość brutto usług', value: 'payment.additionalGrossValue', type: 'netGrossPrice', priceLabel: 'additional', editable: true, gross: true, col: 3 },
            { name: 'vatPercentage', text: 'Vat', value: 'payment.vatPercentage', type: 'textField', col: 3, editable: true, rules: ['required', 'number', { name: 'lowerThan', arg: 101 }], unit: ' [%]' },
            { ...this.conditionalFields.discountFields, text: 'Rabat', condition: isNotTransport },
            { ...this.conditionalFields.discountJustification, text: 'Uzasadnienie rabatu', condition: isNotTransport },
            { name: 'totalGrossValue', text: 'Do zapłaty', value: 'payment.totalGrossValue', type: 'textField', col: 3 },
            { name: 'paidValue', text: 'Pobrano', value: 'payment.paidValue', type: 'paidValue', col: 3, editable: true }
          ]
        }
      },
      {
        name: 'paymentByLink',
        text: 'Przelewy24',
        type: 'left',
        component: 'DetailsSectionPaymentByLink',
        fields: [
          { name: 'paymentId', value: 'payment.payByLinkSessionId', text: 'ID płatności', col: 12 },
          { name: 'paymentToken', value: 'payment.payByLinkToken', text: 'Token płatności', col: 12 },
        ]
      },
      {
        name: 'customServices',
        text: 'Usługi',
        type: 'left',
        multiple: 'customServices',
        condition: order => order?.customServices?.length,
        component: 'DetailsSectionList',
        fields: [
          { name: 'name', value: 'name', text: 'Nazwa usługi', type: 'textField', col: 6 },
          { name: 'netValue', value: 'netValue', text: 'Wartość netto', type: 'textField', col: 2 },
          { name: 'grossValue', value: 'grossValue', text: 'Wartość brutto', type: 'textField', col: 2 },
          { name: 'quantity', value: 'quantity', text: 'Ilość', type: 'textField', col: 2 }
        ]
      },
      {
        name: 'client',
        text: 'Dane klienta',
        type: 'right',
        endpoint: 'client',
        fields: [
          { name: 'clientName', text: 'Nazwa klienta', value: 'client.name', type: 'textField', icon: 'client', extraClass: 'client-name', to: 'singleClient', idPath: 'client.id', col: 12 },
          { name: 'department', text: 'Oddział', value: 'address.department.name', type: 'textField', col: 6 },
          { name: 'bdoType', text: 'Logika BDO', value: 'client.bdoType', type: 'textField', col: 6 },
          { name: 'formattedAddress', text: 'Adres lokalizacji', value: 'address.formattedAddress', type: 'textField', col: 6 },
          { name: 'email', text: 'Email', value: 'client.email', type: 'textField', col: 6 },
          { name: 'phoneNumber', text: 'Telefon', value: 'client.phoneNumber', type: 'textField', col: 6 },
          { name: 'bdoNumber', text: 'Nr BDO', value: 'client.bdoNumber', type: 'textField', col: 6 },
          { name: 'notes', text: 'Notatka do klienta', value: 'client.notes', type: 'textField', col: 12 }
        ]
      },
      {
        name: 'middleman',
        condition: order => order?.middleman,
        text: 'Dane pośrednika',
        type: 'right',
        fields: [
          { name: 'clientName', text: 'Nazwa pośrednika', value: 'middleman.name', type: 'textField', icon: 'client', extraClass: 'client-name', to: 'singleClient', idPath: 'middleman.id', col: 12 },
          { name: 'bdoType', text: 'Logika BDO', value: 'middleman.bdoType', type: 'textField', col: 6 },
          { name: 'email', text: 'Email', value: 'middleman.email', type: 'textField', col: 6 },
          { name: 'phoneNumber', text: 'Telefon', value: 'middleman.phoneNumber', type: 'textField', col: 6 },
          { name: 'bdoNumber', text: 'Nr BDO', value: 'middleman.bdoNumber', type: 'textField', col: 6 },
          { name: 'notes', text: 'Notatka do pośrednika', value: 'middleman.notes', type: 'textField', col: 12 }
        ]
      },
      {
        name: 'invoices',
        text: 'Faktury',
        type: 'right',
        multiple: 'invoices',
        component: 'DetailsSectionList',
        fields: [
          { name: 'invoiceNumber', value: 'invoiceNumber', text: 'Numer faktury', type: 'textField', to: 'singleInvoice', col: 4 },
          { name: 'paymentDueDate', value: 'paymentDueDate', text: 'Termin płatności', type: 'textField', col: 4 },
          { name: 'paid', value: 'paid', text: 'Zapłacono', type: 'textField', col: 4 }
        ]
      },
      { name: 'files', text: 'Załączniki', multiple: 'files', type: 'right', component: 'DetailsSectionFiles' },
      { name: 'reports', text: 'Raporty', multiple: 'reports', type: 'right', component: 'DetailsSectionReports' },
      { name: 'kpo', text: 'Karta KPO/KPOK', multiple: 'bdoFile', type: 'right', component: 'DetailsSectionKPO' },
      { name: 'operationHistory', text: 'Historia operacji', multiple: 'history', type: 'right', component: 'DetailsSectionOperationHistory' }
    ],
    emptyOrderSections: [
      {
        name: 'emptyOrder',
        text: 'Informacje ogólne',
        type: 'left',
        fields: [
          { name: 'containerType', text: 'Zamówiony typ kontenera', value: 'order.containerWithType', type: 'textField', col: 3 },
          { name: 'honeNumber', text: 'Telefon', value: 'order.address.phoneNumber', type: 'textField', col: 3 },
          { name: 'formattedAddress', text: 'Adres lokalizacji', value: 'order.address.formattedAddress', type: 'textField', col: 6 }
        ]
      },
      {
        name: 'course',
        text: 'Szczegóły kursu',
        type: 'left',
        fields: [
          { name: 'courseType', text: 'Typ kursu', value: 'courseType', type: 'textField', col: 3 },
          { name: 'courseId', text: 'Nr kursu', value: 'id', type: 'textField', col: 3 },
          { name: 'courseStatus', text: 'Status kursu', value: 'courseStatus', type: 'textField', extraClass: 'course-status', col: 3 },
          { name: 'dueDate', text: 'Data realizacji', value: 'dueDate', type: 'date', col: 3 },
          { name: 'timeSlot', text: 'Przedział godzinowy', value: 'timeSlot', type: 'timeSlot', col: 3 },
          { name: 'driverFullName', text: 'Kierowca', value: 'driver.fullName', type: 'textField', col: 3 },
          { name: 'userNotes', text: 'Notatka do kursu', value: 'userNotes', type: 'textArea', col: 3 },
          { name: 'driverNotes', text: 'Notatka kierowcy', value: 'driverNotes', type: 'textArea', col: 3 }
        ]
      },
      {
        name: 'client',
        text: 'Dane klienta',
        type: 'right',
        fields: [
          { name: 'clientName', text: '', value: 'order.client.name', type: 'textField', icon: 'client', extraClass: 'client-name', col: 12 },
          { name: 'bdoType', text: 'Logika BDO', value: 'order.client.bdoType', type: 'textField', col: 6 },
          { name: 'bdoNumber', text: 'Nr BDO', value: 'order.client.bdoNumber', type: 'textField', col: 6 },
          { name: 'settlementType', text: 'Typ dokumentu', value: 'client.settlementType', type: 'textField', col: 6 },
          { name: 'blocked', text: 'Zablokowany', value: 'order.client.blocked', type: 'textField', col: 6 }
        ]
      }
    ]
  }
}
