<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      drivers: state => state.drivers.items,
    }),
    parsedDrivers () {
      return this.drivers.map(driver => ({
        text: driver.fullName,
        value: driver.id,
        disabled: this.hasVehicleAndTerminal(driver)
      }))
    },
  },
  methods: {
    hasVehicleAndTerminal (driver) {
      const { vehicle, terminal } = driver
      const lacks = []
      if (!vehicle) lacks.push(' pojazdu')
      if (!terminal) lacks.push(' terminala')
      return lacks.length && `Brak ${lacks.join(' i')}`
    },
  }
}
</script>
