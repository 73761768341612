<template>
  <div class="reports-view">
    <Header
      ref="header"
      :title="$route.meta.title"
      :buttons="[]"
    >
      <v-btn
        v-if="reportName !== 'main'"
        class="px-4"
        color="primary"
        outlined
        :loading="isLoading"
        v-shortkey="exportShortkeys"
        @shortkey.native="exportReport('csv')"
        @click="exportReport('csv')"
      >
        {{ getLabelWithShortkey('Eksportuj raport', exportShortkeys.key) }}
      </v-btn>
      <v-btn
        class="px-4 ml-6"
        color="primary"
        :loading="isLoading"
        outlined
        v-shortkey="printShortkeys"
        @shortkey.native="exportReport('pdf')"
        @click="exportReport('pdf')"
      >
        {{ getLabelWithShortkey('Drukuj raport', printShortkeys.key) }}
      </v-btn>
    </Header>

    <Tabs
      ref="tabs"
      :tabs="tabs"
      persistent
      @goTo="goTo"
    />

    <div
      class="filters"
      ref="tableTop"
    >
      <div class="filters__inputs d-flex justify-end">
        <DateRangePicker
          :table-name="tableName"
          disable-fetch
        />
        <ShowFiltersButton :table-name="tableName" />
      </div>

      <TableFilters
        :table-name="tableName"
        disable-fetch
        @fetch="fetchReports(reportName)"
      />
    </div>

    <div class="px-4">
      <component :is="reportComponent" />
    </div>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Tabs from '../../components/Layout/Tabs'
import DateRangePicker from '../../components/Filters/DateRangePicker'
import TableFilters from '../../components/Table/TableFilters'
import ShowFiltersButton from '../../components/Filters/ShowFiltersButton'
import MainReport from '../../components/Reports/MainReport'
import IncomeReport from '../../components/Reports/IncomeReport'
import RevenueReport from '../../components/Reports/RevenueReport'
import { mapState, mapActions } from 'vuex'
import { getLabelWithShortkey, printShortkeys, exportShortkeys } from '../../const/shortKeys'

export default {
  components: {
    Tabs,
    Header,
    DateRangePicker,
    TableFilters,
    ShowFiltersButton,
    MainReport,
    IncomeReport,
    RevenueReport
  },
  data: () => ({
    tableName: 'reports',
    currentTabValue: 'main',
    tabs: [
      { value: 'main', text: 'Ogólne', component: 'MainReport' },
      { value: 'income', text: 'Przychód dzienny', component: 'IncomeReport' },
      { value: 'revenue', text: 'Utarg dzienny', component: 'RevenueReport' }
    ],
    printShortkeys,
    exportShortkeys
  }),
  computed: {
    ...mapState({
      isLoading: state => state.core.isLoading
    }),
    reportsDate () {
      return this.$store.state[this.tableName].dateRange
    },
    activeTab () {
      return this.tabs.find(tab => tab.value === this.currentTabValue)
    },
    reportComponent () {
      return this.activeTab?.component
    },
    reportName () {
      return this.activeTab?.value
    }
  },
  watch: {
    reportsDate () {
      this.fetchReports(this.reportName)
    }
  },
  mounted () {
    this.fetchReports(this.reportName)
  },
  methods: {
    ...mapActions({
      fetchReports: 'reports/fetchReports',
      generateReport: 'reports/generateReport'
    }),
    getLabelWithShortkey,
    goTo (tab) {
      this.currentTabValue = tab.value
    },
    exportReport (exportType) {
      this.generateReport({ exportType, reportName: this.reportName })
    }
  }
}
</script>
