<template>
  <tr
    class="table__row relative"
    :class="{
      'table__row--selected': isSelected,
      'table__row--ws': item.fromWS
    }"
    @mouseenter="renderRowActions(true)"
    @mouseleave="renderRowActions(false)"
  >
    <td
      v-for="(column, index) in header"
      :key="column.name + index"
      class="table__column"
      :class="[
        {
          'table__column--checkbox-show':
            column.name === 'select' && isMultiselectActive
        },
        column.cellClass
      ]"
    >
      <template v-if="column.name === 'actions'">
        <div class="relative h-100 px-6">
          <TableColumnActions
            v-if="showActions"
            :table-name="tableName"
            :item="item"
            :static-actions="actions"
            :offset="actionsOffset"
          />
        </div>
      </template>
      <div v-else>
        <div
          v-for="row in rowsInCell(column)"
          :key="row.id"
          class="table__column--row"
        >
          <TableColumnSelectItem
            v-if="isMultiselectActive && column.name === 'select'"
            :disabled="isCoursePlanned"
            :item-id="row.id"
            :table-name="tableName"
          />

          <template v-else-if="isDefaultColumn(column.name)">
            <div
              :title="formatColumnValue(column, row, column.length)"
              :class="{
                'error--text font-weight-bold':
                  column.clientLabel && isBlocked()
              }"
            >
              {{ limitedColumnValue(column, row, column.length) }}
            </div>
          </template>

          <TableColumnActive
            v-else-if="column.name === 'active'"
            :value="get(row, column.value)"
            :table-name="tableName"
            :item="item"
          />

          <TableColumnMovement
            v-else-if="column.name === 'moveToRelatedCourse'"
            :course="item"
          />

          <div
            v-else-if="isCheckmarkColumn(column.name)"
            class="d-flex align-center h-100"
          >
            <img
              v-if="columnValue(column.value, row) !== '-'"
              src="@/assets/icons/checkmark.svg"
              class="checkmark"
              alt="tak"
            >
            <img
              v-else
              src="@/assets/icons/block-mark.svg"
              class="checkmark"
              alt="nie"
            >
          </div>

          <template v-else-if="column.name === 'volume'">
            <span>{{ columnValue(column.value, row) / 1000 }} m<sup>3</sup></span>
          </template>

          <template v-else-if="column.name === 'blocked'">
            <img
              v-if="columnValue(column.value, row) !== '-'"
              src="@/assets/icons/block-mark.svg"
              class="checkmark"
              alt="blocked"
            >
          </template>

          <span v-else-if="column.name === 'senderBusinessPlace'">
            {{ getSenderMpd(column.value) }}
          </span>

          <TableColumnCourseType
            v-else-if="column.name === 'courseType'"
            :course-type="columnValue(column.value, row)"
            :is-swap="isSwap()"
          />
          <TableColumnStatus
            v-else-if="column.name === 'courseStatus'"
            :status="columnValue(column.value, row)"
          />
          <TableColumnStatus
            v-else-if="column.name === 'cardStatus'"
            :status="columnValue(column.value, row)"
            :color="item.attentionStatus"
          />
          <TableColumnStatus
            v-else-if="column.name === 'origin'"
            :status="formatColumnValue(column, row)"
            :color="getOriginColor(column.value)"
          />
          <TableColumnDriverAlerts
            v-else-if="column.name === 'driverAlerts'"
            :drivers="columnValue(column.value, row)"
          />
          <TableColumnDate
            v-else-if="isDateColumn(column.name)"
            :done="courseIsDone(column.name)"
            :date="columnValue(column.value, row)"
            :text="column.text"
          />
          <TableColumnInfo
            v-else-if="infoColumns.includes(column.name)"
            :text="get(row, column.value)"
          />

          <div
            v-else-if="column.name === 'filename'"
            class="d-flex align-center"
          >
            <Icon
              name="attachment"
              size="medium"
              class="mr-2"
            />
            {{ limitedColumnValue(column, row) }}
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import TableColumnActions from './Columns/TableColumnActions'
import TableColumnDate from './Columns/TableColumnDate'
import TableColumnStatus from './Columns/TableColumnStatus'
import TableColumnCourseType from './Columns/TableColumnCourseType'
import TableColumnDriverAlerts from './Columns/TableColumnDriverAlerts'
import TableColumnSelectItem from './Columns/TableColumnSelectItem'
import TableColumnActive from './Columns/TableColumnActive'
import TableColumnMovement from './Columns/TableColumnMovement'
import TableColumnInfo from './Columns/TableColumnInfo'
import { getBlockerValue } from '../../utils'
import paramGetters from '../../utils/paramGetters'
import { formatValue } from '../../utils/typesEnum'
import get from 'lodash/get'

// TODO the best would be if headers types were specified in table config files so we don't have to iterate
const dateColumns = [
  'courseDeliveryDate',
  'courseDepartureDate',
  'courseDeliveryAt',
  'createdAt',
  'dueDate',
  'leaveDate',
  'pickupDate',
  'exportDate',
  'issueDate',
  'paymentDate',
  'depositedAt',
  'plannedTransportTime',
  'endDate',
  'startDate',
  'realTransportTime',
  'lastNoteDate'
]
const checkmarkColumns = [
  'bLicense',
  'cLicense',
  'ceLicense',
  'otherLicense',
  'active',
  'visibleForDriver',
  'reserved',
  'wasteCodeExtended',
  'obsolete',
  'pinned',
  'personalInvoice'
]
const infoColumns = [
  'info',
  'discountJustification',
  'lastNoteText',
  'notes',
  'wasteCodeExtendedDescription',
  'containerNotes'
]

const specialColumns = [
  ...dateColumns,
  ...checkmarkColumns,
  ...infoColumns,
  'select',
  'actions',
  'courseStatus',
  'courseType',
  'volume',
  'driverAlerts',
  'blocked',
  'active',
  'filename',
  'cardStatus',
  'origin',
  'moveToRelatedCourse'
]

export default {
  components: {
    TableColumnActions,
    TableColumnDate,
    TableColumnStatus,
    TableColumnCourseType,
    TableColumnSelectItem,
    TableColumnDriverAlerts,
    TableColumnActive,
    TableColumnMovement,
    TableColumnInfo
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    tableName: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: false
    },
    isMultiselectActive: {
      type: Boolean,
      default: false
    },
    header: {
      type: Array,
      required: true
    },
    actions: {
      type: Array,
      required: false
    },
    actionsOffset: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      dateColumns,
      checkmarkColumns,
      infoColumns,
      showActions: false
    }
  },
  computed: {
    isCoursePlanned() {
      return (
        this.tableName === 'courses' && this.item?.courseStatus !== 'Zaplanuj'
      )
    }
  },
  methods: {
    get,
    renderRowActions(showActions = false) {
      this.showActions = showActions
    },
    formatColumnValue(column, item = this.item) {
      const value = get(item, column.value)
      return formatValue(value, column.name)
    },
    limitedColumnValue(column, item = this.item, maxLength = 32) {
      const { value, name, paramGetter } = column
      const rawValue = get(item, value)
      let formattedValue = formatValue(rawValue, name)
      if (paramGetter) {
        const getValue = get(paramGetters, paramGetter, null) // find getter by label
        if (getValue) formattedValue = getValue(rawValue || item)
      }
      if (formattedValue.length > maxLength) {
        return formattedValue.substring(0, maxLength).trim() + '...'
      }
      return formattedValue
    },
    columnValue(path, item = this.item) {
      const value = get(item, path)
      return value || '-'
    },
    rowsInCell(column) {
      let values = [this.item]
      if (column.multipleValue) {
        values = this.item[column.multipleValue]
      } else if (column.swapValue && this.item.swapCourse) {
        values.push(this.item.swapCourse)
      }
      return values
    },
    courseIsDone(name) {
      const index = name === 'courseDeliveryDate' ? 0 : 1
      if (!this.item.courses || !this.item.courses[index]) {
        return false
      }
      return this.item.courses[index].status === 'Wykonany'
    },
    isDefaultColumn(name) {
      return !specialColumns.includes(name)
    },
    isCheckmarkColumn(name) {
      return this.checkmarkColumns.includes(name)
    },
    isDateColumn(name) {
      return this.dateColumns.includes(name)
    },
    isSwap() {
      return !!this.item.swapCourse
    },
    isBlocked() {
      return getBlockerValue(this.item, this.tableName)
    },
    getOriginColor(color) {
      const value = this.columnValue(color)
      const colors = {
        client_app: 'purple',
        desktop: 'green',
        template: 'blue'
      }
      return colors[value] || 'red'
    },
    getSenderMpd(value) {
      const { name, address } = value
      return `${name}
      ${address}`
    }
  }
}
</script>

<style lang="scss" scoped>
.checkmark {
  width: 24px;
}
</style>
