import DateTime from 'luxon/src/datetime'
import get from 'lodash/get'
import views from '../const/views'

function dateStringFormat (dateString, time) {
  const format = time ? 'd/MM/yyyy | HH:mm:ss' : 'd/MM/yyyy'
  return dateString === '-' ? '-' : DateTime.fromISO(dateString).toFormat(format)
}

const noApiManipulation = (tableName) => views[tableName]?.table.noApi

const ignoreDateRange = (tableName) => views[tableName]?.table.noDateRange

const getTableOptions = (tableName) => views[tableName]?.table

const mergeTableParameters = (oldParams, newParams) => {
  const actions = oldParams[oldParams.length - 1]
  oldParams = oldParams.filter((param) =>
    (param.name !== 'actions' && param.name !== 'price') || (param.name === 'price' && param.multiple))
  oldParams.push([...newParams, actions])
  return oldParams.flat()
}

const countCourses = (stats, courses) => {
  for (const course of courses) {
    if (course.swapCourse) stats.Wymiana.value++
    else stats[course.courseType].value++
  }
  return stats
}

const getCoursesStatistics = (items, callback = countCourses) => {
  const statistics = {
    Podstawienie: { text: 'Podstawienia:', value: 0 },
    Zabranie: { text: 'Zabrania:', value: 0 },
    Wymiana: { text: 'Wymiany:', value: 0 },
    Transport: { text: 'Transport:', value: 0 },
  }
  return callback(statistics, items)
}

const getMultipleTableParameters = (params, items) => {
  const parameters = []
  if (items.length) {
    params.map((param) => {
      items[0][param.multiple].map((el, index) => {
        parameters.push(
          {
            name: param.name,
            text: el[param.text],
            value: `${param.multiple}[${index}].${param.value}`,
            sortable: param.sortable,
            show: true
          }
        )
      })
    })
  }
  return parameters
}

const getTableDetails = (tableName) => views[tableName].details || {}

const getTableWsData = (tableName) => views[tableName]?.webSockets || {}

const setTableDetailsView = (tableName, emptyOrder, data) => {
  const details = {
    left: [],
    right: [],
    bottom: []
  }
  const sections = emptyOrder ? 'emptyOrderSections' : 'sections'
  getTableDetails(tableName)[sections]
    .filter(section => !section.condition || section.condition(data))
    .map((section) => {
      section.multiple = section.multiple ? getGroupedItemValue(section.multiple) : null
      if (section.fields) {
        section.fields.map(field => ({ ...field, value: getGroupedItemValue(field.value) }))
      }
      details[section.type].push(section)
    })
  return details
}

const getTableDetailsPreview = (tableName) => views[tableName].quickView

const getTableMaximumDateRange = (tableName) => views[tableName].table?.maximumDateRange || 92

const getTitleFormatted = (title, data) => {
  const regex = /[^{{]+(?=}\})/g
  const matches = title.match(regex)
  const values = matches?.map((item) => get(data, item, ''))
  matches?.map((item, index) => {
    title = title.replace('{{' + item.toString() + '}}', values[index] || '')
  })
  return title.replace(/;[^;]$/, '')
}

const getGroupedItemValue = (path, index = 0) => typeof path === 'string' ? path : path[index]

const getLocalItem = (name) => JSON.parse(localStorage.getItem(name))

const setLocalItem = (name, item) => localStorage.setItem(name, JSON.stringify(item))

const readJSON = (source) => JSON.parse(JSON.stringify(source))

const filterNullFields = (object) => {
  for (const prop in object) {
    if (object[prop] === null) delete object[prop]

    if (typeof object[prop] === 'object' && !Array.isArray(object[prop])) {
      object[prop] = filterNullFields(object[prop])
    }
  }
  return object
}

const parseFloatNumber = (number) => {
  if (typeof number === 'string' && number.includes(',')) {
    number = number.split(',').join('.')
  }
  return isNaN(+number) ? 0 : +number
}

const parseAsBasicUnit = (value, factor = 100) => {
  if (value) return Math.round(parseFloatNumber(value) * factor)
  return undefined
}

const declensionName = (length = 0, singularName, pluralNominativeName, pluralGenitiveName) => {
  let label = singularName

  if (length % 10 < 5 && length % 10 > 1 && (length % 100 > 14 || length % 100 < 12)) {
    label = pluralNominativeName
  } else if (length !== 1) {
    label = pluralGenitiveName
  }
  return `${length} ${label}`
}
function downloadFile (url, filename, target) {
  if (process.env.IS_ELECTRON) {
    window.ipcRenderer.send('download-item', {
      url,
      properties: { saveAs: true }
    })
  } else {
    const link = document.createElement('a')
    link.target = target
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

function openNewWindow ({ path, query, params, name }) {
  const { route: { fullPath } } = this.$router.resolve({ path, query: { full: true, ...query }, params, name })
  if (process.env.IS_ELECTRON) {
    window.open('', fullPath)
  } else {
    window.open(fullPath)
  }
}

const getBlockerValue = (item, tableName) => {
  const paths = {
    courses: 'order.client.blocked',
    clients: 'blocked',
    orders: 'client.blocked',
    orderSchedules: 'active',
    order: 'client.blocked'
  }
  if (tableName === 'orderSchedules') {
    return !get(item, paths[tableName])
  }
  return get(item, paths[tableName]) || false
}

const filterTransportPaymentTypes = (paymentTypes) => {
  return paymentTypes.filter((item) =>
    item !== 'Gotówka przy podstawieniu' && item !== 'Gotówka przy zabraniu')
}
const filterDebrisPaymentTypes = (paymentTypes) => {
  return paymentTypes.filter((item) => item !== 'Gotówka')
}

const getFieldMask = (fieldName) => {
  const masks = {
    phoneNumber: '###-###-###',
    postCode: '##-###',
    bankAccount: '## #### #### #### #### #### ####'
  }
  return masks[fieldName]
}

const formatChangedPhoneNumber = (phoneNumber) => {
  var match = phoneNumber.match(/^(\d{3})(\d{3})(\d{3})$/)
  if (match && match.length === 4) {
    return match[1] + '-' + match[2] + '-' + match[3]
  }
  return phoneNumber
}

const getDistanceBetweenTwoPoints = ({ baseLat, baseLng }, { destLat, destLng }) => {
  var R = 6371 // Radius of the earth in km
  var dLat = parseDegToRad(destLat - baseLat) // parseDegToRad below
  var dLon = parseDegToRad(destLng - baseLng)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(parseDegToRad(baseLat)) * Math.cos(parseDegToRad(destLat)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)

  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km
  return +d.toFixed(1)
}

const parseDegToRad = (deg) => {
  return deg * (Math.PI / 180)
}

const isFilterActive = (filterBy) => {
  if (Array.isArray(filterBy)) return filterBy.length
  return filterBy || typeof filterBy === 'boolean'
  // inactive filter is either [] or ''
}

const searchDebrisType = (item, queryText, itemText) => {
  const searchText = (itemText + item.codeParsed).toLowerCase()
  return searchText.indexOf(queryText.toLowerCase()) > -1
}

const getDebrisString = (debris) => {
  return debris.code ? `${debris.code} - ${debris.displayName}` : debris.displayName
}

function getNetValue (grossValue, taxValue = 8) {
  const value = parseFloatNumber(grossValue)
  if (value > 10000001) return 0
  let amount = value / (1 + taxValue / 100)
  amount = Math.round(amount * 100) / 100
  return amount || 0
}

function getGrossValue (netValue, taxValue = 8) {
  const value = parseFloatNumber(netValue)
  if (value > 10000001) return 0
  const amount = value + value * taxValue / 100
  return amount || 0
}

function getExecutiveMultiselectTableName (originTable) {
  if (originTable === 'clientOrders') return 'orders'
  if (originTable === 'clientInvoices') return 'invoices'
  return originTable
}

export {
  getTableDetails,
  setTableDetailsView,
  getTableDetailsPreview,
  getTableMaximumDateRange,
  getTitleFormatted,
  getTableOptions,
  getTableWsData,
  mergeTableParameters,
  getMultipleTableParameters,
  getGroupedItemValue,
  dateStringFormat,
  noApiManipulation,
  ignoreDateRange,
  getLocalItem,
  setLocalItem,
  readJSON,
  filterNullFields,
  parseFloatNumber,
  parseAsBasicUnit,
  declensionName,
  downloadFile,
  openNewWindow,
  getBlockerValue,
  filterTransportPaymentTypes,
  filterDebrisPaymentTypes,
  getFieldMask,
  getDistanceBetweenTwoPoints,
  isFilterActive,
  getCoursesStatistics,
  searchDebrisType,
  getDebrisString,
  formatChangedPhoneNumber,
  getNetValue,
  getGrossValue,
  getExecutiveMultiselectTableName
}
