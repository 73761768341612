<template>
  <div>
    <v-row>
      <v-col
        v-for="type in types"
        :key="type.label"
      >
        <v-card class="report-card">
          <div>
            <div
              class="report-card__count"
              v-text="getOrderTypeCount(type.label)"
            />
            <div
              class="report-card__label"
              v-text="type.label"
            />
          </div>
          <div class="report-card__icon-wrapper">
            <img
              :src="require(`../../assets/icons/${type.icon}_green.svg`)"
              :alt="type.label"
            >
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <DriversRoutesChart :data="statistics" />
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <DriversCoursesChart :data="statistics" />
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <CoursesCountChart :course-types="doughnutChartData" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DriversCoursesChart from './Charts/DriversCourses'
import DriversRoutesChart from './Charts/DriversRoutes'
import CoursesCountChart from './Charts/CoursesCount'
import { mapState } from 'vuex'

export default {
  components: {
    DriversCoursesChart,
    DriversRoutesChart,
    CoursesCountChart
  },
  data () {
    return {
      types: [
        { icon: 'substitution', label: 'Podstawienie' },
        { icon: 'take_away', label: 'Zabranie' },
        { icon: 'exchange', label: 'Wymiana' },
        { icon: 'aggregate_transport', label: 'Transport' },
        { icon: 'loop', label: 'Pętla' }
      ]
    }
  },
  computed: {
    ...mapState({
      statistics: state => state.reports.main
    }),
    doughnutChartData() {
      return this.statistics.courseTypesCount.data.filter(type => type.key !== 'Pętla')
    }
  },
  methods: {
    getOrderTypeCount (label) {
      const courseType = this.statistics.courseTypesCount.data.find(type => type.key === label)
      return courseType ? courseType.value : 0
    }
  }
}
</script>
