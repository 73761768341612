<template>
  <module-wrapper :title="title">
    <v-data-table
      ref="reportTable"
      class="report-table"
      :items="formattedItems"
      :headers="headers"
      :items-per-page="-1"
      hide-default-footer
      fixed-header
      @update:options="updateSortParams"
    >
      <template #footer>
        <div class="report-table__footer">
          <div>
            {{ positionDeclension }}
          </div>
          <div class="d-flex">
            <div
              v-for="(summary, index) in summaryColumns"
              :key="summary"
              class="report-table__sum"
              :ref="'reportTableSum'+ (index+1)"
            >
              {{ summarizeMoneyValues(summary) }}
            </div>
          </div>
        </div>
      </template>
    </v-data-table>
  </module-wrapper>
</template>

<script>
import ModuleWrapper from '../ReportModuleWrapper'
import { formatValue, mapArray } from '../../../utils/typesEnum'
import { declensionName } from '../../../utils'
import { mapActions } from 'vuex'

export default {
  components: {
    ModuleWrapper
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    report: {
      type: String,
      default: ''
    },
    tableName: {
      type: String,
      default: ''
    }
  },
  computed: {
    formattedItems () {
      return mapArray(this.items)
    },
    summaryColumns () {
      return this.headers.filter(header => header.summarize).map(header => header.value)
    },
    positionDeclension () {
      return declensionName(this.items.length, 'pozycja', 'pozycje', 'pozycji')
    }
  },
  watch: {
    items () {
      this.$nextTick(() => {
        this.setMoneyCellsWidth()
      })
    }
  },
  mounted () {
    this.setMoneyCellsWidth()
  },
  methods: {
    ...mapActions({
      storeTableOrder: 'reports/storeTableOrder'
    }),
    summarizeMoneyValues (fieldName) {
      const sum = this.items.reduce((acc, item) => acc + item[fieldName], 0)
      return formatValue(sum, 'price')
    },
    setMoneyCellsWidth () {
      if (this.items.length) {
        for (let i = this.summaryColumns.length; i > 0; i--) {
          const sumCellWidth = this.$refs['reportTableSum' + i][0].clientWidth
          const cells = this.$refs.reportTable.$el.querySelectorAll(`.reports-view tr td:nth-last-of-type(${i}), .reports-view tr th:nth-last-of-type(${i})`)
          cells.forEach(function (cell) { cell.style.width = sumCellWidth + 'px' })
        }
      }
    },
    updateSortParams (options) {
      const { tableName, report } = this
      const order = {
        field: options.sortBy[0] || 'sequence',
        direction: options.sortDesc[0] ? 'desc' : 'asc'
      }
      this.storeTableOrder({ report, tableName, order })
    }
  }
}
</script>
