const externalRules = {
  required: (value) => {
    if (value && value instanceof Array && value.length === 0) {
      return 'Wymagane'
    }
    return typeof value === 'number' ? true : (!!value || 'Wymagane')
  },
  postCode: (value) => {
    if (!value) return true
    return /^\d{2}-\d{3}$/.test(value) || 'Niepoprawny Kod pocztowy'
  },
  email: (value) => {
    if (!value) return true
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'Niepoprawny e-mail'
  },
  password: (value) => {
    if (!value) return true
    const pattern = /^.{6,}$/
    return pattern.test(value) || 'Hasło musi składać się z co najmniej 6 znaków'
  },
  notPastDate (value) {
    if (!value) return true
    const parsedValue = value.split('/').reverse().join('-') // value is in 'dd/mm/yyyy' format
    return parsedValue >= new Date().toISOString().substring(0, 10) || 'Podano przeszłą datę'
  },
  addressNumber: (value) => {
    if (!value) return true
    return /[\d]{1}/.test(value) || 'Podaj numer lokalu'
  },
  phoneNumber: (value) => {
    if (!value) return true
    return /^\d{3}-\d{3}-\d{3}$/.test(value) || 'Niepoprawny numer telefonu'
  },
  nipNumber: (value) => {
    if (!value) return true
    return /^\d{10}$/.test(value) || 'Nieprawidłowy numer NIP'
  },
  peselNumber: (value) => {
    if (!value) return true
    return /^\d{11}$/.test(value) || 'Nieprawidłowy numer PESEL'
  },
  bankAccount: (value) => {
    if (!value) return true
    return /^\d{2}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}/.test(value) || 'Nieprawidłowy numer konta'
  },
  number: (value) => {
    if (!value) return true
    return /^-?\d+([.,]{1}\d+)?$/.test(value) || 'Podaj liczbę'
  },
  integer: (value) => {
    if (!value) return true
    return /^\d+?$/.test(value) || 'Podaj liczbę całkowitą'
  },
  money: (value) => {
    if (!value) return true
    return /^\d+([.,]{1}\d{1,2})?$/.test(value) || 'Wprowadź poprawny format ( np. 1.23 )'
  },
  word: (value) => {
    if (!value) return true
    return /^\p{L}+$/gu.test(value) || 'Dozwolone są jedynie litery'
  },
  noSpaces: (value) => {
    return /^\S*$/.test(value) || 'Spacje nie są dozwolone'
  },
  debrisCode: (value) => {
    return /^[0-9 ]+/.test(value) || 'Przykładowy kod: "04 07 09*"'
  },

  match: (match) => (value) => value === match || 'Podane hasła różnią się',

  // those below must be pass as an object. E.g. {name: 'lowerThan', arg: 10}
  lowerThan: (amount) => (value) => {
    if (typeof value !== 'number') return true
    return value < amount || `Zbyt wysoka wartość (Maks. ${amount - 1})`
  },

  greaterThan: (amount) => (value) => {
    if (typeof value !== 'number') return true
    return value > amount || `Zbyt niska wartość (Min. ${amount + 1})`
  },

  maxLength: (max) => (value = '') => value.length <= max || `Maksymalna liczba znaków: ${max}`,

  minLength: (max) => (value = '') => value.length >= max || `Minimalna liczba znaków: ${max}`,

  objectEmpty: (obj) => Object.keys(obj).length !== 0 || 'Wymagane'

}

export default externalRules
