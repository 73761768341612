<template>
  <v-card>
    <v-card-title class="px-6 py-4 w-100 d-flex justify-space-between">
      <b>{{ title }}</b>
      <slot name="head" />
    </v-card-title>
    <v-divider />
    <div :class="[padding]">
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    padding: {
      type: String,
      default: ''
    }
  }
}
</script>
