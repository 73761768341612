import api from '../api/v1'
import { Client } from './../models'
import { downloadFile } from '../utils'

export default {
  namespaced: true,
  state: {
    processing: false,
    entity: new Client(),
    files: []
  },
  mutations: {
    SET_CLIENT_PROCESSING (state, status) {
      state.processing = status
    },
    SET_CLIENT_FILES (state, files) {
      state.files = files
    },
    SET_SINGLE_CLIENT (state, client) {
      state.entity = client
    }
  },
  actions: {
    setClientProcessing ({ commit }, status) {
      commit('SET_CLIENT_PROCESSING', status)
    },
    setSingleClient ({ commit }, client) {
      commit('SET_SINGLE_CLIENT', new Client(client))
    },
    getSingleClient ({ commit }, id) {
      commit('SET_CLIENT_PROCESSING', true)
      return api.getClient(id)
        .then((res) => {
          commit('SET_SINGLE_CLIENT', new Client(res.data))
          commit('SET_CLIENT_PROCESSING', false)
        })
    },
    clearSingleClient ({ commit }) {
      commit('SET_SINGLE_CLIENT', new Client())
      commit('SET_CLIENT_FILES', [])
    },
    setClientBlock ({ commit, dispatch }, { id, blockExplanation }) {
      commit('SET_CLIENT_PROCESSING', true)
      api.setClientBlock(id, blockExplanation)
        .then(() => {
          commit('SET_CLIENT_PROCESSING', false)
          dispatch('layout/closeDialog', null, { root: true })
        })
    },
    setClientUnblock ({ commit, dispatch }, id) {
      commit('SET_CLIENT_PROCESSING', true)
      api.setClientUnblock(id)
        .then(() => {
          commit('SET_CLIENT_PROCESSING', false)
          dispatch('layout/closeDialog', null, { root: true })
        })
    },
    fetchGusData ({ commit }, nip) {
      commit('SET_CLIENT_PROCESSING', true)
      return new Promise((resolve, reject) => {
        api.fetchGusData({ nip })
          .then((resp) => {
            resolve(resp.data)
          })
          .catch(() => {
            reject(new Error())
          })
          .finally(() => {
            commit('SET_CLIENT_PROCESSING', false)
          })
      })
    },
    getClientFiles ({ state, commit }) {
      commit('SET_CLIENT_PROCESSING', true)
      return api.getClientFiles(state.entity.id)
        .then((resp) => {
          commit('SET_CLIENT_FILES', resp.data.files)
        })
        .finally(() => {
          commit('SET_CLIENT_PROCESSING', false)
        })
    },
    uploadClientFile ({ commit, dispatch }, formData) {
      commit('SET_CLIENT_PROCESSING', true)
      return api.uploadClientFile(formData)
        .then(() => {
          dispatch('getClientFiles')
        })
        .finally(() => {
          commit('SET_CLIENT_PROCESSING', false)
        })
    },
    // eslint-disable-next-line no-empty-pattern
    downloadClientFile ({}, { item }) {
      const { url, filename } = item || {}
      downloadFile(url, filename, '_blank')
    },
    deleteClientFile ({ commit, dispatch }, id) {
      commit('SET_CLIENT_PROCESSING', true)
      api.deleteClientFile(id)
        .then(() => {
          dispatch('getClientFiles')
        })
        .finally(() => {
          commit('SET_CLIENT_PROCESSING', false)
        })
    },
    createProduct ({ commit }, product) {
      commit('SET_CLIENT_PROCESSING', true)
      return api.createProduct(product)
        .finally(() => {
          commit('SET_CLIENT_PROCESSING', false)
        })
    },
    editProduct ({ commit }, product) {
      commit('SET_CLIENT_PROCESSING', true)
      return api.editProduct(product)
        .finally(() => {
          commit('SET_CLIENT_PROCESSING', false)
        })
    },
    getClientsByQuery ({ commit }, query) {
      commit('SET_CLIENT_PROCESSING', true)
      const params = {
        itemsPerPage: 7,
        filters: { query }
      }
      return new Promise((resolve) => {
        api.getItems({ tableName: 'clients', params })
          .then((resp) => {
            resolve(resp.data.collection)
          })
          .finally(() => {
            commit('SET_CLIENT_PROCESSING', false)
          })
      })
    },
    handleClientAppAccess ({ commit, dispatch }, { id, email, passwordExists }) {
      commit('SET_CLIENT_PROCESSING', true)
      const accessMethod = passwordExists ? api.changeContactPassword : api.grantContactAccess
      return accessMethod(id, email)
        .then((resp) => {
          dispatch('snackbar/showSnackbar', {
            message: [resp.data.success]
          }, { root: true })
          dispatch('layout/closeDialog', null, { root: true })
        })
        .finally(() => {
          commit('SET_CLIENT_PROCESSING', false)
        })
    },
  }
}
