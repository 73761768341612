<template>
  <v-snackbar
    v-model="isSnackbarShown"
    color="rgb(56, 68, 87)"
    timeout="15000"
  >
    <div class="d-flex align-center justify-space-between">
      {{ message }}
      <v-btn
        plain
        text
        small
        color="primary"
        class="ml-2"
        @click="openTemplatesView"
      >
        Przejdź
      </v-btn>

      <v-btn
        text
        small
        color="error"
        @click="isSnackbarShown = false"
      >
        Zamknij
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import humps from 'humps'
import { declensionName } from '../../utils'

export default {
  data: () => ({
    isSnackbarShown: false,
    tasksCount: 0,
  }),
  channels: {
    TaskAlertChannel: {
      received (data) {
        const { tasks } = humps.camelizeKeys(data)
        this.tasksCount = tasks.length
        this.isSnackbarShown = true
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      token: state => state.auth.token,
    }),
    message () {
      const tasks = declensionName(this.tasksCount, 'zadanie', 'zadania', 'zadań')
      return `Masz dzisiaj ${tasks} do wykonania`
    }
  },
  mounted () {
    this.$cable.connection.connect(`${process.env.VUE_APP_WSS_BASE_URL}?Authorization=${this.token}`)
    this.$cable.subscribe({ channel: 'TaskAlertChannel' })
  },
  methods: {
    ...mapActions({
      setTableDate: 'tasks/setTableDate',
    }),
    openTemplatesView () {
      this.setTableDate({ date: [new Date().toISOString().substring(0, 10)] })
      this.$nextTick(() => {
        this.$router.push({ name: 'tasks' })
        this.isSnackbarShown = false
      })
    }
  }
}
</script>
