import axios from 'axios'
import humps from 'humps'
import qs from 'qs'
import store from '../store'

export const axiosApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/api/v1/`,
  headers: {
    common: {
      Authorization: `Bearer ${localStorage.getItem('EL-Auth-Token')}`
    }
  },
  paramsSerializer: (params) => qs.stringify(humps.decamelizeKeys(params), { arrayFormat: 'brackets' }),
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => data instanceof Blob ? data : humps.camelizeKeys(data)
  ],
  transformRequest: [
    data => data instanceof FormData ? data : humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest
  ]
})
export function axiosErrorInterceptor () {
  axiosApi.interceptors.response.use(undefined, (error) => {
    switch (error.response?.status) {
      case 401 :
        store.dispatch('auth/logout')
        store.dispatch('snackbar/showSnackbar', {
          message: ['Zaloguj się ponownie'],
          type: 'error'
        })
        break
      case 404 :
        store.dispatch('snackbar/showSnackbar', {
          message: error.response.data.errors || ['Nie znaleziono'],
          type: 'error'
        })
        break
      case 409:
        store.dispatch('snackbar/showSnackbar', {
          message: error.response.data.errors,
          type: 'error'
        })
        break
      case 422:
        store.dispatch('snackbar/showSnackbar', {
          message: error.response.data.errors,
          type: 'error'
        })
        break
      default:
        store.dispatch('snackbar/showSnackbar', {
          message: ['Coś poszło nie tak, spróbuj ponownie.'],
          type: 'error'
        })
    }
    return Promise.reject(error)
  })
}

export function authorizeAxios (token) {
  axiosApi.defaults.headers.common.Authorization = `Bearer ${token}`
}

export function deauthorizeAxios () {
  delete axiosApi.defaults.headers.common.Authorization
}
