import actions from '../actions'

export default {
  header: [
    { ...actions.addNewIncident, create: true }
  ],
  table: {
    isWebSocketHandled: true,
    maximumDateRange: 31,
    sorting: {
      sortBy: 'createdAt',
      sortDesc: true
    },
    filters: [
      { name: 'clientId', text: 'Klient', options: 'clients', filterBy: '', propPath: 'client.id' },
      { name: 'incidentSourceId', text: 'Kanał', options: 'incidentSources', filterBy: '', propPath: 'incidentSource.id' }
    ],
    parameters: [
      { name: 'id', text: 'Nr incydentu', value: 'id', show: true },
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'client', text: 'Klient', value: 'client.name', show: true },
      { name: 'phoneNumber', text: 'Telefon', value: 'phoneNumber', show: true },
      { name: 'incidentSource', text: 'Kanał', value: 'incidentSource.name', show: true },
      { name: 'info', text: 'Dodatkowe informacje', value: 'notes', show: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [
      { ...actions.deleteIncident, icon: 'usun' }
    ],
    menu: [
      { ...actions.deleteIncident }
    ]
  },
  details: {
    title: 'Incydent nr {{id}}',
    actions: [
      { ...actions.deleteIncident, redirect: true },
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        tableName: 'incidents',
        fields: [
          { name: 'orderId', text: 'Nr incydentu', value: 'id', type: 'inline', col: 3 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'inline', col: 3 },
          { name: 'client', text: 'Klient', value: 'client.name', type: 'inline', to: 'singleClient', idPath: 'client.id', col: 3 },
          { name: 'phoneNumber', text: 'Telefon', value: 'phoneNumber', type: 'inline', col: 3 },
          { name: 'incidentSource', text: 'Kanał', value: 'incidentSource.name', type: 'inline', col: 3 },
          { name: 'notes', text: 'Dodatkowe informacje', value: 'notes', type: 'textarea', col: 6 },
        ]
      },
      {
        name: 'messagesHistory',
        text: 'Historia wiadomości',
        multiple: 'messages',
        type: 'right',
        component: 'DetailsSectionMessagesHistory'
      }
    ]
  }
}
