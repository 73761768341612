// Never import this class directly into Vue components!
// maybe it'd worth to separate methods for collections and singles
import apiMessages from '../const/apiMessages'
import api from '../api/v1'
import { Items, PageOffset, Pagination } from '../models'
import { isFilterActive } from '../utils'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
// Items naming does not work for me but let's keep it for now.
// Also, it looks like this model is only used in base.js and items.js so maybe it'd be better to keep this object definition here?

const baseItemMatchesFiltersCriteria = (item, filters) => {
  return filters.every(filter => {
    const { filterBy, propPath } = filter
    const filterValue = filterBy.value ?? filterBy
    if (filterValue === 'Wymiana') return !!item.swapCourse // courseType in courses

    return Array.isArray(filterValue)
      ? filterValue.some(singleFilter => get(item, propPath) === (singleFilter.value ?? singleFilter))
      : get(item, propPath) === filterValue
  })
}
const today = new Date().toISOString().substring(0, 10)
export default class Base {
  // TODO: almost all method are using tableName as a parameter - in most cases it's enough to just use tableName from constructor params
  // so this should be refactored. Optionally we could add endpoint parameter in case of more complex api endpoint paths

  // itemMatchesFiltersCriteria is passed as argument to be easily overwritten in routes, orders and map modules
  constructor (tableName = '', itemMatchesFiltersCriteria = baseItemMatchesFiltersCriteria) {
    this.namespaced = true

    this.state = {
      ...new Items(),
      totalItemsCount: 0,
      pageOffset: new PageOffset(),
      // isProcessing might be used in various components like it was in course.js with reference to the courses/SET_PROCESSING module instead of course/SET_PROCESSING,
      // which was not straightforward. We should implement isProcessing also in other single modules to maintain consistency
      isProcessing: false,
      showFilters: false,
      selectedItems: [],
      isMultiselectActive: false,
      dateRange: [today]
    }
    this.mutations = {
      SET_ITEMS (state, { items }) {
        state.pageOffset = new PageOffset()
        state.items = items
      },
      SET_ITEMS_COUNT (state, count) {
        state.totalItemsCount = count
      },
      SET_NEXT_ITEMS_PAGE (state, { items }) {
        state.items = state.items.concat(items)
      },
      SET_SIMPLIFIED_ITEMS (state, simplifiedList) {
        state.simplifiedList = simplifiedList
      },
      SET_PROCESSING (state, status) {
        state.isProcessing = status
      },
      FORCE_TABLE_RERENDER (state) {
        state.items = [...state.items]
      },
      REMOVE_ITEMS (state, ids) {
        state.items = [...state.items].filter(item => !ids.includes(item.id))
      },
      TOGGLE_FILTERS_VISIBILITY (state) {
        state.showFilters = !state.showFilters
      },
      SET_TABLE_DATE (state, date) {
        state.dateRange = [...date].sort()
      },
      CREATE_ITEMS_WITH_OFFSET (state, records) {
        const { items, pageOffset: { excludedIds } } = state
        // New element always goes on top, regardless of current sorting
        state.items = [...records, ...items]
        state.totalItemsCount += records.length

        // if new element enters the list we store its id and send it with next page call
        // so that BE wold know not to pass it with the page that it appears on
        const newItemIds = records.map(record => record.id)
        state.pageOffset.excludedIds = [...newItemIds, ...excludedIds]
      },
      DELETE_ITEM_WITH_OFFSET (state, { deletedItemId }) {
        const { items, pageOffset } = state
        const filteredItems = [...items].filter((item) => deletedItemId !== item.id)
        if (filteredItems.length !== items.length) {
          state.items = filteredItems
          state.totalItemsCount -= 1
          // we pass this offset to BE in order not to skip next page 1st element
          // eg.: 1P1,2P1,3P1|1P2,2P2,3P2 -> del. 2P1 -> BE would pass 2nd page as 2P2,3P2,1P3
          // 1P2 is skipped as it is now part of 1st page || 1P2 -> 1st element of 2nd page
          state.pageOffset.excludedOffset = pageOffset.excludedOffset + 1
        }
      },
      UPDATE_ITEMS_WITH_OFFSET (state, records) {
        const updatedItemIds = records.map(record => record.id)
        state.items = [...state.items].map(item => {
          return updatedItemIds.includes(item.id) ? records.find(record => record.id === item.id) : item
        })
      },
      TOGGLE_ITEM_SELECTION (state, id) {
        if (state.selectedItems.includes(id)) {
          state.selectedItems = state.selectedItems.filter(itemId => itemId !== id)
        } else {
          state.selectedItems = [...state.selectedItems, id]
        }
      },
      FILTER_SELECTED_ITEMS (state) {
        state.selectedItems = state.selectedItems
          .filter(itemId => state.items.map(item => item.id).includes(itemId))
      },
      SELECT_ALL_ITEMS (state, items) {
        state.selectedItems = items.map(item => item.id)
      },
      UNSELECT_ALL_ITEMS (state) {
        state.selectedItems = []
      },
      TOGGLE_MULTISELECT_STATUS (state, isActive) {
        state.isMultiselectActive = isActive
      },
      SET_COUNTERS (state, counters) {
        state.counters = counters
      },
    }
    this.actions = {
      getSimplifiedItems ({ commit }, { params }) {
        return api.getSimplifiedList(tableName, params)
          .then((resp) => {
            commit('SET_SIMPLIFIED_ITEMS', resp.data)
          })
      },
      // TODO: use tableName from constructor method instead of params
      getItems ({ commit, rootGetters }, fetchData) {
        const { blockLoader, ...data } = fetchData || {}
        const params = data.params || rootGetters['tables/getTableParameters'](tableName).params
        if (!params) return

        if (!blockLoader) commit('SET_PROCESSING', true)
        return api.getItems({ tableName, params })
          .then((resp) => {
            const payload = ['debrisTypes', 'aggregateTypes'].includes(tableName) ? resp.data.debrisTypes : resp.data
            // for pagination backend returns collection: [...], in other cases it's flat array
            const items = Array.isArray(payload) ? payload : new Items(payload).items
            commit('SET_ITEMS', { items })
            const { itemsPerPage } = rootGetters['tables/getTableConfig'](tableName)?.pagination || {}
            const pagination = new Pagination(payload.pagination, itemsPerPage)
            const totalItemsCount = pagination?.itemsLength || items.length
            commit('SET_ITEMS_COUNT', totalItemsCount)
            commit('tables/SET_TABLE_PAGINATION', { tableName, pagination }, { root: true })
          })
          .finally(() => {
            commit('SET_PROCESSING', false)
          })
      },
      goNextItemsPage ({ state, commit, rootGetters }, tableName) {
        commit('SET_PROCESSING', true)
        const params = rootGetters['tables/getTableParameters'](tableName).params
        const { excludedIds, excludedOffset } = state.pageOffset
        params.page += 1
        params.excludedIds = excludedIds
        params.excludedOffset = excludedOffset
        api.getItems({ tableName, params })
          .then((resp) => {
            const { items } = new Items(resp.data)
            const pagination = new Pagination(resp.data.pagination)
            commit('SET_NEXT_ITEMS_PAGE', { items })
            commit('tables/SET_TABLE_PAGINATION', { tableName, pagination }, { root: true })
          })
          .finally(() => {
            commit('SET_PROCESSING', false)
          })
      },
      setTableDate ({ state, commit, dispatch }, { date, fetch }) {
        if (state.isMultiselectActive) commit('UNSELECT_ALL_ITEMS')
        dispatch('tables/clearTablePagination', tableName, { root: true })
        commit('SET_TABLE_DATE', date)
        if (fetch) dispatch('getItems')
      },
      // shouldn't those methods be in course.js instead of courses.js?
      // Then we could make collections.js and singles.js as base modules that can be extended instead of a single base / items file
      // We could also rename addNewItem methods to createItem, so we can use createCourse instead of addNewCourse
      addNewItem: debounce(function ({ commit, dispatch }, { params = {}, endpoint = '' }) {
        commit('SET_PROCESSING', true)
        return new Promise((resolve) => {
          api.addNewItem(endpoint || tableName, params).then((resp) => {
            resolve(resp.data)
            dispatch('snackbar/showSnackbar', {
              message: apiMessages(tableName, 'create')
            }, { root: true })
          })
            .finally(() => {
              commit('SET_PROCESSING', false)
            })
        })
      }, 300, { leading: true }),
      deleteItem: debounce(function ({ commit, dispatch }, params) {
        commit('SET_PROCESSING', true)

        return api.deleteItem(params).then(() => {
          commit('DELETE_ITEM_WITH_OFFSET', { deletedItemId: params.id })

          dispatch('snackbar/showSnackbar', {
            message: apiMessages(tableName, 'delete')
          }, { root: true })
        })
          .finally(() => {
            commit('SET_PROCESSING', false)
          })
      }, 300, { leading: true }),
      editItem: debounce(function ({ commit, dispatch }, data) {
        const { notWebSocketHandled, ...params } = data
        commit('SET_PROCESSING', true)
        return new Promise((resolve) => {
          api.updateItem(params)
            .then((resp) => {
              // TODO would be good to come up with better naming or
              // add hasWebSockets (boolean) / pageOffset (new PageOffset object) param in constructor?
              if (notWebSocketHandled) {
                commit('singleView/SET_SINGLE_VIEW_ENTITY', resp.data, { root: true })
              }
              resolve(resp.data)
              dispatch('snackbar/showSnackbar', {
                message: apiMessages(tableName, 'update')
              }, { root: true })
            })
            .finally(() => {
              commit('SET_PROCESSING', false)
            })
        })
      }, 300, { leading: true }),
      clearTableItems ({ commit }) {
        commit('SET_ITEMS', [])
      },
      clearSimplifiedList ({ commit }) {
        commit('SET_SIMPLIFIED_ITEMS', [])
      },
      updateItemsWS ({ rootState, getters, rootGetters, commit }, { actionType, records, deletedItemId }) {
        if (actionType === 'delete') return commit('DELETE_ITEM_WITH_OFFSET', { deletedItemId })

        const activeFilters = rootGetters['tables/getTableConfig'](tableName)?.filters
          ?.filter(filter => isFilterActive(filter.filterBy))

        const filteredRecords = records.filter(record => itemMatchesFiltersCriteria(record, activeFilters))

        const isSearchActive = !!rootState.tables[tableName].search
        if (actionType === 'create') {
          if (!isSearchActive) commit('CREATE_ITEMS_WITH_OFFSET', filteredRecords)
          return
        }

        if (actionType === 'update') {
          const deletedRecords = records.filter(record => !itemMatchesFiltersCriteria(record, activeFilters))
          const newRecords = []
          const updatedRecords = []
          filteredRecords.forEach(record => {
            if (getters.currentListIds.includes(record.id)) updatedRecords.push(record)
            else newRecords.push(record)
          })
          deletedRecords.forEach(record => { commit('DELETE_ITEM_WITH_OFFSET', { deletedItemId: record.id }) })
          if (newRecords.length && !isSearchActive) commit('CREATE_ITEMS_WITH_OFFSET', newRecords)
          if (updatedRecords.length) commit('UPDATE_ITEMS_WITH_OFFSET', updatedRecords)
        }
      },
      toggleItemSelection ({ commit }, id) {
        commit('TOGGLE_ITEM_SELECTION', id)
      },
      toggleFiltersVisibility ({ commit }) {
        commit('TOGGLE_FILTERS_VISIBILITY')
      },
      toggleMultiselectStatus ({ commit }, isActive) {
        commit('TOGGLE_MULTISELECT_STATUS', isActive)
        if (!isActive) commit('UNSELECT_ALL_ITEMS')
      },
      filterSelectedItems ({ commit }) {
        commit('FILTER_SELECTED_ITEMS')
      },
      unselectItem ({ commit }, id) {
        commit('UNSELECT_ITEM', id)
      },
      toggleAllItemsSelection ({ state, commit, rootState }, targetTableName) {
        if (state.selectedItems.length) {
          commit('UNSELECT_ALL_ITEMS')
        } else {
          const { items } = tableName === targetTableName ? state : rootState[targetTableName]
          commit('SELECT_ALL_ITEMS', items)
        }
      },
    }
    this.getters = {
      currentListIds: state => [...state.items].map(item => item.id)
    }
  }
}
