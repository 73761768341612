<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    >
      <v-btn
        class="px-4 ml-6"
        :class="{
          'button-active':
            isMultiselectActive && multiselectSubject === 'download'
        }"
        color="primary"
        outlined
        v-shortkey="multiselectShortkeys"
        @shortkey="toggleMultiselect('download')"
        @click="toggleMultiselect('download')"
      >
        {{ getLabelWithShortkey('Pobierz faktury', multiselectShortkeys.key) }}
      </v-btn>
      <v-btn
        class="px-4 ml-6"
        :class="{
          'button-active':
            isMultiselectActive && multiselectSubject === 'export'
        }"
        color="primary"
        outlined
        v-shortkey="exportShortkeys"
        @shortkey="toggleMultiselect('export')"
        @click="toggleMultiselect('export')"
      >
        {{ getLabelWithShortkey('Exportuj do csv', exportShortkeys.key) }}
      </v-btn>
    </Header>
    <div class="table-view-wrapper">
      <div>
        <Tabs
          v-if="configured"
          ref="tabs"
          :tabs="tabs"
          persistent
          @goTo="goTo"
        />
        <Table
          ref="table"
          :table-name="tableName"
          @selectRow="selectTableRow"
          @openDetails="openSingleInvoiceView"
          @contextMenu="openContextMenu"
          @configured="configured = true"
        >
          <template #tableTop>
            <FiltersBar
              ref="tableTop"
              :key="currentTab"
              :table-name="tableName"
              search-bar
              date-range-picker
              show-filters-button
              table-filters
            />
          </template>
          <template #stats>
            <TableStatisticsBar
              ref="statistics"
              :table-name="tableName"
            />
          </template>
        </Table>
      </div>
      <TableConfirmationBar
        v-if="selectedItems.length"
        :confirm-actions="confirmationBarActions"
        :table-name="tableName"
        ref="confirmation"
      />
    </div>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Table from '../../components/Table/Table'
import Tabs from '../../components/Layout/Tabs'
import FiltersBar from '../../components/Filters/FiltersBar'
import TableConfirmationBar from '../../components/Table/TableConfirmationBar'
import TableStatisticsBar from '../../components/Table/TableStatisticsBar'
import actions from '../../const/actions'
import { mapActions, mapState } from 'vuex'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import webSocketMixin from '../../mixins/webSocketMixin'
import {
  getLabelWithShortkey,
  multiselectShortkeys,
  exportShortkeys
} from '../../const/shortKeys'

const tabs = Object.freeze([
  {
    value: 'openInvoices',
    text: 'Faktury otwarte',
    filters: [
      { name: 'paid', filterBy: false, hide: true },
      { name: 'settlementType', filterBy: '', hide: false },
      { name: 'batchInvoices', filterBy: '', hide: true }
    ]
  },
  {
    value: 'closedInvoices',
    text: 'Faktury zamknięte',
    filters: [
      { name: 'paid', filterBy: true, hide: true },
      { name: 'settlementType', filterBy: '', hide: false },
      { name: 'batchInvoices', filterBy: '', hide: true }
    ]
  },
  {
    value: 'invoices',
    text: 'Faktura',
    filters: [
      { name: 'paid', filterBy: '', hide: true },
      { name: 'settlementType', filterBy: 'Faktura', hide: true },
      { name: 'batchInvoices', filterBy: '', hide: true }
    ]
  },
  {
    value: 'nameInvoices',
    text: 'Faktura imienna',
    filters: [
      { name: 'paid', filterBy: '', hide: true },
      { name: 'settlementType', filterBy: 'Faktura imienna', hide: true },
      { name: 'batchInvoices', filterBy: '', hide: true }
    ]
  },
  {
    value: 'receipt',
    text: 'Paragon',
    filters: [
      { name: 'paid', filterBy: '', hide: true },
      { name: 'settlementType', filterBy: 'Paragon', hide: true },
      { name: 'batchInvoices', filterBy: '', hide: true }
    ]
  },
  {
    value: 'batchInvoices',
    text: 'Sprzedaż zbiorcza',
    filters: [
      { name: 'paid', filterBy: '', hide: true },
      { name: 'settlementType', filterBy: '', hide: false },
      { name: 'batchInvoices', filterBy: true, hide: true }
    ]
  }
])

export default {
  components: {
    Header,
    Table,
    Tabs,
    FiltersBar,
    TableConfirmationBar,
    TableStatisticsBar
  },
  mixins: [defaultTableEventsMixin, webSocketMixin],
  data: () => ({
    tableName: 'invoices',
    currentTab: 'openInvoices',
    multiselectSubject: 'download',
    actions,
    tabs,
    multiselectShortkeys,
    exportShortkeys,
    configured: false
  }),
  channels: {
    InvoiceIndexChannel: {
      received(data) {
        this.captureChanges(data)
      }
    }
  },
  computed: {
    ...mapState({
      invoice: state => state.invoice.entity,
      selectedItems: state => state.invoices.selectedItems,
      isMultiselectActive: state => state.invoices.isMultiselectActive
    }),
    confirmationBarActions() {
      if (this.multiselectSubject === 'download') {
        return [actions.downloadMultipleInvoices]
      } else if (this.multiselectSubject === 'export') {
        return [actions.exportInvoicesToCsv]
      }
      return []
    }
  },
  watch: {
    selectedItems(newValue, oldValue) {
      if (
        newValue.length !== oldValue.length &&
        (newValue.length === 0 || oldValue.length === 0)
      ) {
        this.$refs.table.resizeTable()
      }
    }
  },
  mounted() {
    this.$store.subscribeAction(action => {
      if (action.type === 'invoices/getItems') this.getInvoicesCounters()
    })
    this.subscribeSocket('InvoiceIndexChannel')
  },
  beforeDestroy() {
    this.unsubscribeSocket('InvoiceIndexChannel')
  },
  methods: {
    ...mapActions({
      toggleMultiselectStatus: 'invoices/toggleMultiselectStatus',
      setSingleInvoice: 'invoice/setSingleInvoice',
      getInvoicesCounters: 'invoices/getCounters',
      setTableFilters: 'tables/setTableFilters'
    }),
    getLabelWithShortkey,
    toggleMultiselect(subject) {
      const prevSubject = this.multiselectSubject
      if (subject !== this.multiselectSubject) {
        this.multiselectSubject = subject
      }
      if (!this.isMultiselectActive || subject === prevSubject) {
        this.toggleMultiselectStatus(!this.isMultiselectActive)
      }
    },
    selectTableRow(data) {
      this.setSingleInvoice(data)
    },
    openSingleInvoiceView({ id }) {
      this.$router.push({ name: 'singleInvoice', params: { id } })
    },
    goTo(tab) {
      this.currentTab = tab.value
      this.setTableFilters({ filters: tab.filters, tableName: this.tableName })
    }
  }
}
</script>
