<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Table
      ref="table"
      :key="tableName"
      :table-name="tableName"
      disable-pagination
      @openDetails="openTableDetails"
      @contextMenu="openContextMenu"
    >
      <template #tableTop>
        <div
          class="filters"
          ref="tableTop"
        >
          <div class="filters__inputs">
            <SearchBar :table-name="tableName" />
          </div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../../components/Layout/Header'
import Table from '../../../components/Table/Table'
import SearchBar from '../../../components/Filters/SearchBar'
import defaultTableEventsMixin from '../../../mixins/defaultTableEventsMixin'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    Header,
    Table,
    SearchBar,
  },
  mixins: [defaultTableEventsMixin],
  data: () => ({
    tableName: 'containerTypes',
  }),
  computed: {
    ...mapState({
      departmentId: state => state.core.department.id
    }),
  },
  beforeRouteEnter (to, from, next) {
    // Containers and ContainerTypes views require global containerTypes
    if (from.name !== 'containers') { next(vm => vm.getContainerTypes()) } else next()
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'containers') this.getContainerTypes({ params: { departmentId: this.departmentId } })
    next()
  },
  methods: {
    ...mapActions({
      getContainerTypes: 'containerTypes/getItems'
    }),
  }
}
</script>
