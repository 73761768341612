<template>
  <div class="d-flex">
    <div
      v-for="button in actions"
      :key="button.name"
      class="ml-5"
    >
      <v-btn
        :small="small"
        class="px-4"
        :class="{'pl-2' : button.create, 'base-hover': outlined}"
        :color="button.color || 'primary'"
        :outlined="outlined(button)"
        :disabled="isDialogAlreadyOpen(button.target)"
        v-shortkey="button.shortkey"
        @shortkey.native="checkActionAvailability(button)"
        @click="performAction(button)"
      >
        <Icon
          v-if="button.create"
          name="add"
          size="big"
          :color="iconColor(button.target)"
          class="mr-4"
        />
        {{ getButtonLabel(button) }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { getLabelWithShortkey } from '../../const/shortKeys'
import quickActionMixin from '../../mixins/quickActionsMixin.vue'

export default {
  props: {
    actions: {
      type: Array,
      default: () => []
    },
    small: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [quickActionMixin], // triggerAction, isDialogAlreadyOpen
  methods: {
    getButtonLabel ({ text, shortkey }) {
      return getLabelWithShortkey(text, shortkey?.key)
    },
    outlined (button) {
      return !button.filled && !button.create
    },
    iconColor (target) {
      return this.isDialogAlreadyOpen(target) ? 'gray' : '#37C87A'
    },
    checkActionAvailability (button) {
      if (!this.isDialogAlreadyOpen(button.target)) this.performAction(button)
    },
    performAction (action) {
      this.triggerAction({ action, item: this.item })
    }
  }
}
</script>
