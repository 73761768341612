import { authorizeAxios, deauthorizeAxios } from '@/api/axios'
import axiosApi from '@/api/v1/index'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('EL-Auth-Token') || '',
    user: JSON.parse(localStorage.getItem('EL-Auth-User')) || null,
    logoutRoute: null,
  },
  mutations: {
    SET_USER_DATA(state, user) {
      state.user = user
      localStorage.setItem('EL-Auth-User', JSON.stringify(user))
    },

    SET_TOKEN(state, token) {
      state.token = token
      localStorage.setItem('EL-Auth-Token', token)
    },

    CLEAR_SESSION_DATA(state) {
      state.token = ''
      state.user = {}
      localStorage.removeItem('EL-Auth-Token')
      localStorage.removeItem('EL-Auth-User')
    },

    SET_LOGOUT_ROUTE(state, path) {
      state.logoutRoute = path
    },
  },
  actions: {
    login({ state, commit, dispatch }, userCredentials) {
      dispatch('core/setLoading', true, { root: true })

      axiosApi
        .login(userCredentials)
        .then(resp => {
          commit('SET_TOKEN', resp.data.token)
          commit('SET_USER_DATA', resp.data.user)
          authorizeAxios(resp.data.token)
          dispatch('core/initApp', null, { root: true }).then(() => {
            if (state.logoutRoute) {
              router.push({ name: state.logoutRoute })
              commit('SET_LOGOUT_ROUTE', null)
            } else {
              router.push({ name: 'courses' })
            }
          })
        })
        .catch(() => {
          commit('CLEAR_SESSION_DATA')
        })
        .finally(() => {
          dispatch('core/setLoading', false, { root: true })
        })
    },

    changeLogoutRoute({ commit }, path) {
      commit('SET_LOGOUT_ROUTE', path)
    },

    logout({ commit, dispatch }) {
      commit('CLEAR_SESSION_DATA')
      commit('core/SET_APP_LOADED_STATE', false, { root: true })
      dispatch('driversOccupancy/setOccupancyVisibility', null, { root: true })
      this.$cable.connection.disconnect('')
      deauthorizeAxios()
      router.push({ name: 'login' })
    },
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token
    },
    getFullName(state) {
      const { firstName, lastName } = state.user || {}
      return `${firstName} ${lastName}`
    },
    getInitials(state) {
      const { firstName, lastName } = state.user || {}
      return `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`
    },
  },
}
