import api from '../api/v1/index'
import debounce from 'lodash/debounce'

export default {
  namespaced: true,
  state: {
    isActiveModal: false,
    incomingPhoneNumber: null,
  },
  mutations: {
    SET_INCOMING_PHONE_NUMBER (state, phoneNumber) {
      state.incomingPhoneNumber = phoneNumber
    },
    SET_MODAL_ACTIVITY (state, isActive) {
      state.isActiveModal = isActive
    },
  },
  actions: {
    setIncomingPhoneNumber ({ commit }, phoneNumber) {
      commit('SET_INCOMING_PHONE_NUMBER', phoneNumber)
    },
    setModalActivity ({ commit }, isActive) {
      commit('SET_MODAL_ACTIVITY', isActive)
    },
    searchForClients: debounce(function ({ commit }, phoneNumber) {
      api.notifyCall({ phoneNumber })
      commit('SET_INCOMING_PHONE_NUMBER', phoneNumber)
    }, 500),
  }
}
