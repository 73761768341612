<template>
  <v-card
    class="d-flex flex-column"
    width="300"
    elevation="0"
  >
    <v-card-text class="pb-0">
      <v-row>
        <v-col class="py-0">
          <div class="font-weight-bold">
            Zlecenie nr {{ item.order.refNumber }}
          </div>
          <div class="pt-1 pb-2">
            {{ item.order.client.name }}
          </div>
        </v-col>
        <v-col
          cols="auto"
          class="py-0"
        >
          <div class="d-flex align-center">
            <Icon
              name="container"
              size="small"
              class="mr-1"
            />
            {{ item.order.containerWithType }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <div class="d-flex align-center">
            <Icon
              name="location"
              size="small"
              class="mr-1"
            />
            {{ item.order.address.formattedAddress }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <div class="d-flex align-center py-1">
            <Icon
              name="date_small"
              size="small"
              class="mr-1"
            />
            {{ dateStringFormat(item.dueDate) }}
          </div>
          <div class="d-flex align-center py-1">
            <Icon
              name="payment_status"
              size="small"
              class="mr-1"
            />
            {{ item.order.payment ? item.order.payment.paymentStatus : '-' }}
          </div>
        </v-col>
        <v-col class="py-0">
          <div class="d-flex align-center py-1">
            <Icon
              name="hourse_range"
              size="small"
              class="mr-1"
            />
            {{ timeSlotToString(item.timeSlot) }}
          </div>
          <div class="d-flex align-center py-1">
            <Icon
              name="assign_a_driver_small"
              size="small"
              class="mr-1"
            />
            {{ item.driver ? item.driver.fullName : '-' }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-spacer />
    <v-card-actions>
      <v-btn
        v-if="isCancellableCourse && !isAddedToPlanning"
        text
        @click="cancelCourse"
      >
        Cofnij kurs
      </v-btn>
      <v-btn
        v-if="!isCancellableCourse && isAddedToPlanning && !isRouteCompleted"
        text
        @click="removeCourse"
      >
        Usuń kurs z trasy
      </v-btn>
      <v-btn
        v-if="isCourseToPlan && !isAddedToPlanning"
        color="primary"
        text
        @click="addCourse"
      >
        {{ labelAddingCourse }}
      </v-btn>
      <v-btn
        v-if="!isCourseToPlan && !isAddedToPlanning"
        color="primary"
        text
        @click="showRoute"
      >
        Pokaż szczegóły
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { dateStringFormat } from '../../../utils'
import { isCancellableCourseStatus, isCourseStatusToPlan } from '../../../utils/recordStatuses'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      sidebar: state => state.layout.sidebar,
      route: state => state.route.entity
    }),
    labelAddingCourse () {
      const label = {
        Zabranie: 'Zabierz kontener',
        Wymiana: 'Wymień kontener',
        default: 'Podstaw kontener'
      }
      return label[this.item.courseType] || label.default
    },
    isCancellableCourse () {
      return isCancellableCourseStatus(this.item.courseStatus)
    },
    isAddedToPlanning () {
      return this.route.courseIds.includes(this.item.id)
    },
    isCourseToPlan () {
      return isCourseStatusToPlan(this.item.courseStatus)
    },
    isRouteCompleted () {
      return this.route.routeStatus && this.route.routeStatus === 'Zrealizowana'
    },
    isEditMode () {
      return this.route.size && this.sidebar.isEditMode
    }
  },
  methods: {
    ...mapActions({
      getSingleRoute: 'route/getSingleRoute',
      startRoutePlaning: 'route/startRoutePlaning',
      addNextStopToRoutePlaning: 'route/addNextStopToRoutePlaning',
      removeStopFromRoutePlaning: 'route/removeStopFromRoutePlaning',
      cancelSingleCourse: 'route/cancelSingleCourse',
      displayEditModeMessage: 'route/displayEditModeMessage',
      setSidebar: 'layout/setSidebar',
    }),
    dateStringFormat,
    timeSlotToString (value) {
      return !value ? '-' : value[0] + ' - ' + value[1]
    },
    showRoute () {
      if (this.isEditMode) {
        this.displayEditModeMessage()
      } else {
        this.getSingleRoute(this.item.routeId)
          .then(() => {
            this.setSidebar({ size: 1 })
          })
      }
      this.$emit('hideWindow')
    },
    addCourse () {
      if (!this.sidebar.size || this.isRouteCompleted) {
        this.startRoutePlaning(this.item)
      } else {
        this.addNextStopToRoutePlaning(this.item)
      }
      this.$emit('hideWindow')
    },
    removeCourse () {
      this.removeStopFromRoutePlaning(this.item.id)
      this.$emit('hideWindow')
    },
    cancelCourse () {
      this.cancelSingleCourse(this.item.id)
      this.$emit('hideWindow')
    }
  }
}
</script>
