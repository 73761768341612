import actions from '../../actions'
import orders from './orders'

export default {
  header: [
    { ...actions.addNewOrder, text: 'Nowe zlecenie', create: true }
  ],
  table: {
    isWebSocketHandled: true,
    maximumDateRange: 92,
    specialFlag: {
      done: true,
      endpoint: 'orders'
    },
    sorting: {
      sortBy: 'refNumber',
      sortDesc: false
    },
    filters: orders.table.filters,
    parameters: orders.table.parameters,
    actions: [
      { ...actions.generateInvoice, icon: 'wygeneruj_fakture' }
    ],
    menu: [
      { ...actions.generateInvoice }
    ]
  },
  quickView: {
    title: 'Zlecenie nr {{refNumber}}',
    text: 'Informacje ogólne',
    fields: orders.quickView.fields,
    actions: [
      { ...actions.generateInvoice }
    ]
  },
  details: orders.details
}
