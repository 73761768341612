<template>
  <div class="details-courses my-4">
    <template v-if="list.length">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="details__row--multiple"
      >
        <v-container fluid>
          <v-row>
            <v-col
              v-for="field in detail.fields"
              :key="field.name"
              cols="12"
              sm="12"
              :md="field.col"
              class="py-1"
            >
              <DialogInput
                :field="field"
                :multiple="item"
                :detail="detail"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
    <div
      v-else
      class="px-6 details-attachments__name"
    >
      Brak elementów do wyświetlenia
    </div>
  </div>
</template>

<script>
import DialogInput from '../../Forms/Inputs/DialogInput'

export default {
  components: {
    DialogInput
  },
  props: {
    detail: {
      type: Object,
      required: true
    },
    list: {
      type: Array,
      required: true
    }
  },
}
</script>
