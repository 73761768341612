var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-details"},[_c('div',{ref:"header",staticClass:"detail-section__header contact-details__header"},[_vm._v(" Osoby kontaktowe "),(_vm.actions.length)?_c('ActionButtons',{attrs:{"actions":_vm.actions,"item":_vm.client}}):_vm._e()],1),_c('v-divider'),_c('v-data-table',{staticClass:"contacts-table",style:(_vm.tableStyles),attrs:{"headers":_vm.headers,"items":_vm.contacts,"items-per-page":-1,"fixed-header":"","hide-default-footer":"","expanded":_vm.expandedContacts,"show-expand":"","item-key":"id"},on:{"update:expanded":function($event){_vm.expandedContacts=$event},"click:row":function (item, slot) { return _vm.expandRow(item, slot); },"contextmenu:row":function($event){$event.preventDefault();return (function (event, row) { return _vm.handleContextMenu(event, row.item); }).apply(null, arguments)}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"expanded-table br-0",attrs:{"headers":[{value:'formattedAddress', text: 'Obsługiwane adresy', sortable: false}],"items":item.addresses,"items-per-page":-1,"fixed-header":"","hide-default-footer":""}})],1)]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.addresses && item.addresses.length)?_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()],1)]}},{key:"header.data-table-expand",fn:function(){return [_c('th',[_c('v-switch',{staticClass:"ma-0",attrs:{"input-value":!!_vm.expandedContacts.length,"hide-details":"","dense":"","title":"Przełącz wszystkie"},on:{"change":_vm.toggleAllContacts}})],1)]},proxy:true},{key:"item.passwordExists",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex align-center"},[(item.passwordExists)?[_c('img',{staticClass:"mr-4",attrs:{"width":"24","src":require("@/assets/icons/checkmark.svg"),"alt":"tak"}}),_c('v-btn',{attrs:{"outlined":"","x-small":"","color":"blue"},on:{"click":function($event){return _vm.modifyCredentials(item, true)}}},[_vm._v(" Zresetuj hasło ")])]:[_c('img',{staticClass:"mr-4",attrs:{"width":"24","src":require("@/assets/icons/block-mark.svg"),"alt":"nie"}}),_c('v-btn',{attrs:{"outlined":"","x-small":"","color":"green"},on:{"click":function($event){return _vm.modifyCredentials(item)}}},[_vm._v(" Nadaj dostęp ")])]],2)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }