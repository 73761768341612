const isCancellableCourseStatus = (status) => {
  const courseStatuses = ['Zaplanowany', 'Wysłany', 'Rozpoczęty']
  return courseStatuses.includes(status)
}
const isCourseStatusToPlan = (status) => {
  return status === 'Zaplanuj'
}

const courseIconType = (type) => {
  const icons = {
    Podstawienie: 'substitution',
    Zabranie: 'take_away',
    Transport: 'transport',
    Wymiana: 'exchange'
  }
  return icons[type] || icons.Podstawienie
}

const statusColor = {
  Zaplanuj: '#F86262',
  Wysłany: '#5695DF',
  Zaplanowany: '#FA9B56',
  Wykonany: '#2CB56B',
  Rozpoczęty: '#A370DD',
  default: '#2CB56B'
}

const statusColorName = {
  Zaplanuj: 'red',
  Wysłany: 'blue',
  Zaplanowany: 'orange',
  Wykonany: 'green',
  Rozpoczęty: 'purple'
}

const scheduleStatus = (status) => status ? 'UnblockedSchedule' : 'BlockedSchedule'
const clientStatus = (status) => status ? 'BlockedClient' : 'UnblockedClient'
const anyContainerValue = (item) => {
  const { anyContainer } = item.swapCourse ? item.swapCourse : item
  return anyContainer ? 'isAnyContainer' : 'notAnyContainer'
}

export {
  isCancellableCourseStatus,
  isCourseStatusToPlan,
  courseIconType,
  statusColor,
  statusColorName,
  scheduleStatus,
  clientStatus,
  anyContainerValue
}
