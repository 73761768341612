<!-- SAMPLE FILE -->
<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <div class="table-view-wrapper">
      <div>
        <Tabs
          v-if="configured"
          ref="tabs"
          :tabs="tabs"
          persistent
          @goTo="goTo"
        />
        <Table
          ref="table"
          :table-name="tableName"
          @selectRow="selectTableRow"
          @openDetails="openSingleClientOrder"
          @contextMenu="openContextMenu"
          @configured="configured = true"
        >
          <template #tableTop>
            <FiltersBar
              ref="tableTop"
              :key="currentTab"
              :table-name="tableName"
              search-bar
              date-range-picker
              show-filters-button
              table-filters
            />
          </template>
          <template #stats>
            <TableStatisticsBar
              ref="statistics"
              :table-name="tableName"
            />
          </template>
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Table from '../../components/Table/Table'
import Tabs from '../../components/Layout/Tabs'
import FiltersBar from '../../components/Filters/FiltersBar'
import TableStatisticsBar from '../../components/Table/TableStatisticsBar'
import actions from '../../const/actions'
import { mapActions } from 'vuex'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import { getLabelWithShortkey, multiselectShortkeys } from '../../const/shortKeys'

const tabs = Object.freeze([
  {
    value: 'openInvoices',
    text: 'Wszystkie zamówienia',
    filters: []
  },
  {
    value: 'closedInvoices',
    text: 'Rozliczone zamówienia',
    filters: []
  },
  {
    value: 'invoices',
    text: 'Nierozliczone zamówienia',
    filters: []
  },
  {
    value: 'nameInvoices',
    text: 'Historia zamówień',
    filters: []
  },
])

export default {
  components: {
    Header,
    Table,
    Tabs,
    FiltersBar,
    TableStatisticsBar,
  },
  mixins: [defaultTableEventsMixin],
  data: () => ({
    tableName: 'clientOrders',
    currentTab: 'openInvoices',
    actions,
    tabs,
    multiselectShortkeys,
    configured: false,
  }),
  mounted () {
    // this.$store.subscribeAction((action) => {
    //   if (action.type === 'clientOrders/getItems') this.getClientOrdersCounters()
    // })
  },
  methods: {
    ...mapActions({
      // setSingleClientOrder: 'clientOrder/setSingleClientOrder',
      // getClientOrdersCounters: 'clientOrders/getCounters',
      setTableFilters: 'tables/setTableFilters',
    }),
    getLabelWithShortkey,
    selectTableRow (data) {
      // this.setSingleClientOrder(data)
      console.info('selectTableRow', data)
    },
    openSingleClientOrder ({ id }) {
      // this.$router.push({ name: 'singleClientOrder', params: { id } })
      console.info('openSingleClientOrder', id)
    },
    goTo (tab) {
      this.currentTab = tab.value
      this.setTableFilters({ filters: tab.filters, tableName: this.tableName })
    }
  }
}
</script>
