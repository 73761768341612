// SAMPLE FILE
// import actions from '../actions'
const debrisName = (type) => `${type.code} - ${type.name}`

export default {
  header: [],
  table: {
    maximumDateRange: 366,
    isWebSocketHandled: true,
    sorting: {
      sortBy: 'createdAt',
      sortDesc: true
    },
    specialFlag: {
      endpoint: 'clientAppOrders'
    },
    filters: [
      { name: 'debrisTypeIds', text: 'Typ odpadu', options: 'debrisTypes', nameGetter: debrisName, filterBy: [], multiple: true, propPath: 'debrisType.id' },
      { name: 'containerTypeIds', text: 'Typ kontenera', options: 'containerTypes', filterBy: [], multiple: true, propPath: 'containerType.id' },
    ],
    parameters: [
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'orderType', text: 'Typ zlecenia', value: 'orderType', show: true },
      { name: 'leaveDate', text: 'Data podstawienia', value: 'leaveDate', show: true },
      { name: 'pickupDate', text: 'Data zabrania', value: 'pickupDate', show: true },
      { name: 'address', text: 'Adres', value: 'address.formattedAddress', show: true },
      { name: 'containerTypeId', text: 'Typ kontenera', value: 'containerType.name', show: true },
      { name: 'debrisTypeId', text: 'Typ odpadu', value: 'debrisType.displayName', show: true },
      { name: 'clientName', text: 'Klient', value: 'contact.client.name', clientLabel: true, show: true },
      { name: 'contactPhoneNumber', text: 'Numer kontaktowy', value: 'contact.phoneNumber', show: true },
      { name: 'contactName', text: 'Osoba kontaktowa', value: 'contact', paramGetter: 'getFullName', show: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [],
    menu: []
  },
  details: {
    title: 'Zamówienie',
    actions: [],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        fields: [
          { name: 'client', text: 'Klient', value: 'client.name', type: 'textField', col: 6 },
          { name: 'clientPhoneNumber', text: 'Numer telefonu', value: 'client.phoneNumber', type: 'textField', col: 3 },
          { name: 'addres', text: 'Adres zlecenia', value: 'address.formattedAddress', type: 'textField', col: 3 },
          { name: 'orderType', text: 'Typ zlecenia', value: 'orderType', type: 'textField', col: 3 },
          { name: 'containerType', text: 'Typ kontenera', value: 'containerType.name', type: 'textField', col: 3 },
          { name: 'debrisTypeName', text: 'Typ odpadu', value: 'debrisType.displayName', type: 'textField', col: 3 },
          { name: 'debrisTypeCode', text: 'Kod odpadu', value: 'debrisType.code', type: 'textField', col: 3 },
          { name: 'leaveDate', text: 'Data podstawienia', value: 'leaveDate', type: 'textField', col: 3 },
          { name: 'pickupDate', text: 'Data zabrania', value: 'pickupDate', type: 'textField', col: 3 },
          { name: 'contactName', text: 'Osoba kontaktowa', value: 'contact.name', type: 'textField', col: 3 },
          { name: 'contactPhoneNumber', text: 'Numer kontaktowy', value: 'contact.phoneNumber', type: 'textField', col: 3 },
        ]
      },
    ]
  }
}
