const searchShortkeys = {
  key: ['f1'],
  ctrlf: ['ctrl', 'f'],
  cmdf: ['meta', 'f'],
}

const defaultActionShortkeys = {
  key: ['f2'],
}

const printShortkeys = {
  key: ['f2'],
  crtlp: ['ctrl', 'p'],
  cmdp: ['meta', 'p']
}

const toggleAllShortkeys = {
  crtlp: ['ctrl', 'a'],
  cmdp: ['meta', 'a']
}

const downloadShortkeys = {
  key: ['f2'],
  crtlp: ['ctrl', 'd'],
  cmdp: ['meta', 'd']
}

const createShortkeys = {
  key: ['f3']
}

const newPickupShortkeys = {
  key: ['f4']
}
const extraCreateShortkeys = {
  key: ['f5']
}

const exportShortkeys = {
  key: ['f6'],
  crtle: ['ctrl', 'e'],
  cmde: ['meta', 'e']
}

const checkPriceShortkeys = {
  key: ['f7']
}

const multiselectShortkeys = {
  key: ['f8'],
}

const toggleFiltersShortkeys = {
  key: ['f9'],
}

const paymentShortkeys = {
  key: ['f11'],
}

const blockShortkeys = {
  key: ['f11'],
  crtlb: ['ctrl', 'b'],
  cmdb: ['meta', 'b']
}

const deleteShortkeys = {
  key: ['f12'],
  ctrldel: ['ctrl', 'del'],
  cmddel: ['meta', 'del']
}

const logoutShortkeys = {
  cmdl: ['meta', 'l'],
  ctrll: ['ctrl', 'l']
}

const toggleButtonsShortkeys = {
  ctrll: ['ctrl', 'arrowleft'],
  cmdl: ['meta', 'arrowleft'],
  ctrlr: ['ctrl', 'arrowright'],
  cmdr: ['meta', 'arrowright'],
}

const navigateTabsShortkeys = {
  shiftr: ['shift', 'arrowright'],
  shiftl: ['shift', 'arrowleft']
}

const navigateMenuShortkeys = {
  shiftup: ['shift', 'arrowup'],
  shiftdown: ['shift', 'arrowdown']
}

const navigateTableShortkeys = {
  up: ['arrowup'],
  down: ['arrowdown'],
  right: ['arrowright'],
  left: ['arrowleft'],
  enter: ['enter'],
  esc: ['esc']
}

const selectActiveClientShortkeys = {
  up: ['arrowup'],
  down: ['arrowdown'],
  enter: ['enter'],
}

const parseShortkey = (shortkey) => {
  return shortkey?.join('+')?.toUpperCase() || ''
}

const getLabelWithShortkey = (label, shortkey) => {
  return `${label} ${parseShortkey(shortkey)}`
}

const getSearchShortkeysPlaceholder = (target = '') => {
  const { key, ctrlf } = searchShortkeys
  return `Szukaj ${target} [${parseShortkey(key)}, ${parseShortkey(ctrlf)}]`
}

const shortkeysNavigateBoundaries = (key, currentIndex, options, prevShortkeys, nextShortkeys) => {
  if (nextShortkeys.includes(key)) {
    return currentIndex + 1 < options.length ? currentIndex + 1 : 0
  } else if (prevShortkeys.includes(key)) {
    return currentIndex - 1 >= 0 ? currentIndex - 1 : options.length - 1
  }
}

export {
  searchShortkeys,
  defaultActionShortkeys,
  printShortkeys,
  downloadShortkeys,
  toggleAllShortkeys,
  createShortkeys,
  newPickupShortkeys,
  extraCreateShortkeys,
  exportShortkeys,
  checkPriceShortkeys,
  multiselectShortkeys,
  toggleFiltersShortkeys,
  paymentShortkeys,
  blockShortkeys,
  deleteShortkeys,
  logoutShortkeys,
  toggleButtonsShortkeys,
  navigateTabsShortkeys,
  navigateMenuShortkeys,
  navigateTableShortkeys,
  selectActiveClientShortkeys,
  getLabelWithShortkey,
  getSearchShortkeysPlaceholder,
  shortkeysNavigateBoundaries,
}
