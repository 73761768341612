<template>
  <div>
    <div class="py-4">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="field in detail.fields"
            :key="field.name"
            sm="12"
            :md="field.col"
            class="py-1"
          >
            <DialogInput
              :field="field"
              :data="data"
              :detail="detail"
            />
          </v-col>
          <v-col cols="12">
            <ActionButtons
              class="ml-n2"
              :actions="paymentActions"
              :item="data"
              small
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import DialogInput from '../../Forms/Inputs/DialogInput'
import ActionButtons from '../../Elements/ActionButtons.vue'
import actions from '../../../const/actions'

export default {
  components: {
    DialogInput,
    ActionButtons,
  },
  props: {
    detail: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    paymentActions() {
      return this.data.payment.payByLinkToken ? [
        { name: 'resendPaymentLink', component: 'GeneratePaymentLink', target: 'dialog', text: 'Wyślij link ponownie', mode: 'resend' },
        { name: 'regeneratePaymentLink', component: 'GeneratePaymentLink', target: 'dialog', text: 'Wygeneruj ponownie', mode: 'regenerate' }
      ] : [{ ...actions.generatePaymentLink, shortkey: null }]
    }
  },
}
</script>
