var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GmapMarker',{attrs:{"position":{lat: +_vm.base.lat, lng: +_vm.base.lng},"icon":{ url: require('@/assets/icons/map-pins/base.svg')}},on:{"click":function($event){return _vm.toggleWindow($event, _vm.department, 'department')}}}),_vm._l((_vm.drivers),function(driver,index){return _c('GmapMarker',{key:index+'driver',attrs:{"position":{lat: +driver.lastPosLat, lng: +driver.lastPosLng},"icon":{ url: require(("@/assets/icons/map-pins/driver_" + (driver.available ? 'available' : 'unavailable') + ".svg"))}},on:{"click":function($event){return _vm.toggleWindow($event, driver, 'driver')}}})}),_vm._l((_vm.containers),function(container,index){return _c('GmapMarker',{key:index+'container',attrs:{"position":{ lat: +container.order.address.spreadLocation.lat, lng: +container.order.address.spreadLocation.lng},"icon":{
      path:'M70.6,5.1v19.3c0,2.2-1.8,4.1-4.1,4.1H40.5L37,34.5c-0.6,1-2,1-2.6,0l-3.5-6.1H4.7c-2.2,0-4.1-1.8-4.1-4.1V5.1c0-2.2,1.8-4.1,4.1-4.1h61.8C68.8,1.1,70.6,2.9,70.6,5.1z',
      scale:1,
      fillColor:_vm.getContainerIconColor(container.daysStanding),
      fillOpacity: 1,
      strokeWeight: 0.5,
      strokeColor: 'white',
      anchor: {x:38, y:28}
    },"label":{
      text: container.refNumber,
      color: 'white',
      fontWeight: 'bold',
      fontSize: '15px',
      className: 'container-label'
    }},on:{"click":function($event){return _vm.toggleWindow($event, container, 'container')}}})}),_vm._l((_vm.courses),function(course,index){return [(_vm.standing(course))?_c('GmapMarker',{key:index+'course',attrs:{"position":{lat: +course.order.address.spreadLocation.lat, lng: +course.order.address.spreadLocation.lng},"icon":{ url: require(("@/assets/icons/map-pins/simple/" + (_vm.getCourseIconName(course)) + ".svg"))},"label":{
        text: course.containerIdleDays && course.containerIdleDays.toString(),
        color: '#F86262',
        fontWeight: 'bold',
        fontSize: '11px',
        className:'idle-days-counter'
      }},on:{"click":function($event){return _vm.toggleWindow($event, course, 'course')}}}):_c('GmapMarker',{key:index+'course',attrs:{"position":{lat: +course.order.address.spreadLocation.lat, lng: +course.order.address.spreadLocation.lng},"icon":{ url: require(("@/assets/icons/map-pins/simple/" + (_vm.getCourseIconName(course)) + ".svg"))}},on:{"click":function($event){return _vm.toggleWindow($event, course, 'course')}}})]}),(_vm.windowData && _vm.showWindow)?_c('MarkerWrapper',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.toggleWindow),expression:"toggleWindow"}],key:_vm.windowPosition.x+_vm.windowPosition.y,ref:"markerWindowRef",attrs:{"marker":_vm.windowDataPosition,"z-index":1000,"offset-top":_vm.windowOffsetTop,"offset-left":_vm.windowOffsetLeft}},[_c('div',{attrs:{"id":"marker-window"}},[(_vm.windowType === 'course')?_c('CourseWindow',{attrs:{"item":_vm.windowData},on:{"hideWindow":_vm.toggleWindow}}):(_vm.windowType === 'container')?_c('ContainerWindow',{attrs:{"container":_vm.windowData},on:{"hideWindow":_vm.toggleWindow}}):(_vm.windowType === 'driver')?_c('DriverWindow',{attrs:{"driver":_vm.windowData},on:{"hideWindow":_vm.toggleWindow}}):_c('BaseWindow',{attrs:{"department":_vm.windowData}}),_c('div',{staticClass:"marker-window__arrow",class:_vm.windowClass})],1)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }