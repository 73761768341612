export default {
  header: [],
  table: {
    maximumDateRange: 92,
    sorting: {
      sortBy: 'id',
      sortDesc: false
    },
    filters: [],
    parameters: [],
    actions: [],
    menu: []
  },
  details: {
    title: '',
    actions: [],
    sections: []
  }
}
