<template>
  <div class="pb-2">
    <div class="details-history px-4">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="details-history__row mx-3 py-3"
      >
        <div
          class="details-history__content"
          :class="{ 'error--text': item.critical }"
        >
          <b>{{ item.changeType }}:</b>
          <br>
          <span
            v-html="
              item.invoice && item.changeType === 'Dodanie faktury'
                ? getInvoiceReference(item)
                : item.text
            "
          />
          <div class="details-history__meta mt-2">
            {{ getOperationInfo(item) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateStringFormat } from '../../../utils'

export default {
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  methods: {
    getOperationInfo(item) {
      const { createdAt, changedBy, changedByType } = item
      return (
        dateStringFormat(createdAt, true) +
        ' | ' +
        changedBy +
        ' ' +
        (changedByType ? `(${changedByType})` : '')
      )
    },
    getInvoiceReference(item) {
      const { id, invoiceNumber } = item.invoice
      const anchor = `<a href='/invoices/${id}'>${invoiceNumber}</a>`
      return item.text.replace(invoiceNumber, anchor)
    }
  },
}
</script>
