<template>
  <div
    id="login"
    class="login"
  >
    <v-container fill-height>
      <v-row class="fill-height">
        <v-col class="login__col login__col--left hidden-md-and-down">
          <img
            class="login__logo"
            src="@/assets/images/logo.png"
          >
          <v-spacer />
          <img
            class="login__img"
            src="@/assets/images/login-pic.svg"
          >
        </v-col>
        <v-col class="login__col login__col--right">
          <v-form
            ref="loginForm"
            class="custom-form"
            v-model="isFormValid"
            @submit.prevent="login(loginForm)"
          >
            <v-row>
              <v-col cols="12">
                <div class="text-h5 font-weight-bold mb-6">
                  Zaloguj się
                </div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Login"
                  v-model.trim="loginForm.login"
                  :rules="[rules.required]"
                  placeholder="Wpisz swój login"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Hasło"
                  type="password"
                  v-model.trim="loginForm.password"
                  :rules="[rules.required]"
                  placeholder="Wpisz swoje hasło"
                />
              </v-col>
              <v-row
                justify="space-between"
                align="center"
                v-if="!$isLogisticsApp"
              >
                <v-col
                  md="4"
                  class="ml-2"
                >
                  <v-checkbox
                    v-model="loginForm.rememberMe"
                    label="Zapamiętaj mnie"
                  />
                </v-col>
                <v-col md="4">
                  <div class="text-subtitle-2 text-right mr-2">
                    <!-- TODO: dodac linka potem przy zrobionym widoku do przypominania hasła -->
                    <a href="#">Przypomnij hasło?</a>
                  </div>
                </v-col>
              </v-row>
              <v-col
                cols="12"
                class="d-flex justify-end"
              >
                <v-btn
                  v-if="!$isLogisticsApp"
                  block
                  color="primary"
                  type="submit"
                  class="form-action"
                >
                  Zaloguj się
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  type="submit"
                  class="form-action"
                >
                  Zaloguj się
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rules from '@/utils/validators'

export default {
  data() {
    return {
      rules,
      isFormValid: true,
      loginForm: {
        login: process.env.VUE_APP_LOGIN || null,
        password: process.env.VUE_APP_PASSWORD || null,
        rememberMe: null,
      },
    }
  },
  methods: {
    ...mapActions({
      login: function(dispatch, payload) {
        const module = this.$isLogisticsApp ? 'auth' : 'authClient'
        return dispatch(`${module}/login`, payload)
      },
    }),
  },
}
</script>
