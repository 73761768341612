<template>
  <v-app :key="token">
    <svg-sprite />

    <v-navigation-drawer
      v-if="showSidebar"
      app
      permanent
      dark
      color="#384457"
      width="202"
      :mini-variant="sidebarSmall"
      mini-variant-width="80"
      class="main-menu"
    >
      <Menu />

      <template #prepend>
        <SessionDetails
          :mini="sidebarSmall"
          @toggle="sidebarSmall = !sidebarSmall"
        />
      </template>

      <template #append>
        <LogoutSection
          v-if="isLoadedApp"
          :mini="sidebarSmall"
        />
      </template>
    </v-navigation-drawer>

    <v-main
      v-if="!isLoggedIn || isLoadedApp"
      :key="token"
    >
      <router-view :key="department && department.name" />
    </v-main>

    <ContextMenu />
    <Dialog v-if="$isLogisticsApp && isDialogActive" />
    <TheSnackbar />
    <ClientSearchDialog v-if="isLoadedApp && $isLogisticsApp" />
    <DriversOccupancy v-if="isLoadedApp && $isLogisticsApp" />
    <OrderTemplateSnackbar v-if="isLoadedApp && $isLogisticsApp" />
    <TasksSnackbar v-if="isLoadedApp && $isLogisticsApp" />

    <v-overlay
      :value="isLoading"
      color="#f7f9fc"
    />

    <v-progress-linear
      :active="isLoading"
      :indeterminate="isLoading"
      absolute
      bottom
      height="6"
    />
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SessionDetails from './components/Layout/Sidebar/SessionDetails.vue'
import LogoutSection from './components/Layout/Sidebar/LogoutSection.vue'
import ContextMenu from './components/Layout/ContextMenu'
import DriversOccupancy from './components/Layout/DriversOccupancy'
import ClientSearchDialog from './components/Layout/ClientSearchDialog'
import OrderTemplateSnackbar from './components/Layout/OrderTemplateSnackbar'
import TasksSnackbar from './components/Layout/TasksSnackbar'
import Menu from './components/Layout/Menu'
import Dialog from './components/Layout/Dialog'
import SvgSprite from './components/Elements/Icons/SvgSprite'
import TheSnackbar from './components/Layout/TheSnackbar'

export default {
  name: 'App',
  components: {
    SessionDetails,
    LogoutSection,
    DriversOccupancy,
    Menu,
    ContextMenu,
    Dialog,
    TheSnackbar,
    SvgSprite,
    ClientSearchDialog,
    OrderTemplateSnackbar,
    TasksSnackbar,
  },
  data() {
    return {
      sidebarSmall: false,
    }
  },
  computed: {
    ...mapState({
      isLoadedApp: state => state.core.isLoadedApp,
      isLoading: state => state.core.isLoading,
      department: state => state.core.department,
      token: state => state.auth.token,
      isFullViewModeEnabled: state => state.core.fullViewMode,
      isDialogActive: state => state.layout.dialog.active,
    }),
    showSidebar() {
      return !this.isFullViewModeEnabled && this.isLoggedIn && this.isLoadedApp
    },
    isLoggedIn() {
      const module = this.$isLogisticsApp ? 'auth' : 'authClient'
      return this.$store.getters[`${module}/isLoggedIn`]
    },
  },
  created() {
    if (this.$route.query.full) this.setFullViewMode()
    if (this.isLoggedIn) this.initApp()
  },
  methods: {
    ...mapActions({
      initApp: 'core/initApp',
      setFullViewMode: 'core/setFullViewMode',
    }),
  },
}
</script>

<style lang="scss">
@import 'src/assets/styles/index.scss';
</style>
