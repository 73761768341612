import views from '../const/views'
import { clientStatus, scheduleStatus, anyContainerValue } from './recordStatuses'

const getContextMenu = (tableName) => views[tableName].table.menu

const getHeaderButtons = (tableName) => views[tableName].header

const getQuickActions = (tableName) => views[tableName].table.actions

const getSidebarActions = (tableName) => views[tableName].quickView ? views[tableName].quickView.actions : null

const inactiveActions = (action, blockerValue) => {
  // object below includes actions that are disabled depending on blocker value
  // object key should match blockerValue
  const disabledActions = {
    Zaplanuj: ['cancelCourse', 'startCourse', 'finishCourse', 'renewCourse'],
    Zaplanowany: ['editDueDate', 'startCourse', 'deleteCourse', 'renewCourse'],
    Wysłany: ['editDueDate', 'sendCourse', 'deleteCourse', 'sendRouteToDriver', 'renewCourse'],
    Rozpoczęty: ['editDueDate', 'sendCourse', 'startCourse', 'deleteCourse', 'editOrder', 'renewCourse'],
    Wykonany: ['cancelCourse', 'assignContainer', 'assignOrder', 'unassignOrder', 'messageDriver', 'finishCourse', 'editDueDate', 'sendCourse', 'startCourse', 'deleteCourse', 'editOrder'],
    OrderUndeletable: ['deleteOrder'],
    BlockedClient: ['blockClient'],
    UnblockedClient: ['unblockClient'],
    BlockedSchedule: ['blockOrderSchedule'],
    UnblockedSchedule: ['unblockOrderSchedule'],
    notAnyContainer: ['assignOrder'],
    isAnyContainer: ['showCourseHistory', 'unassignOrder', 'finishCourse'],
    Zabranie: ['assignContainer', 'copyOrder'],
    Wymiana: ['copyOrder'],
    Transport: ['generateKpoCard', 'unassignOrder', 'assignContainer', 'pickupContainer', 'swapContainer'],
    Substitution: ['generateKpoCard', 'unassignOrder'],
    clientNotKpoEligible: ['generateKpoCard'],
    containerUnpickable: ['swapContainer', 'pickupContainer'],
    bdoCardAssigned: ['assignKpoCardToOrder', 'generateKpoCard'],
    bdoCardUnssigned: ['unassignKpoCard'],
    allButGenerationConfirmed: ['rejectBdoCard', 'confirmBdoCard'],
    allButTakeoverConfirmed: ['confirmTransport'],
    allButConfirmed: ['generateKpoConfirmation'],
    unableToRevise: ['reviseKpoCard'],
    transportTimeUpdateNotAllowed: ['updateTransportTime'],
    taskDone: ['completeTask'],
    taskNotDone: ['revertTask'],
    courseWithoutAddress: ['editCourseAddress'],
    containerNotReserved: ['findAssignedCourses'],
    orderTemplateTransformed: ['newOrderFromTemplate'],
    notReceipt: ['generateInvoice'],
    notInvoice: ['correctInvoice'],
    invoiceAlreadyCreated: ['generateInvoice'],
    courseWithoutOrder: ['finishCourse'],
    isCorrection: ['correctInvoice'],
    notHaveOrder: ['pickupContainer', 'swapContainer', 'navigateToClient', 'updateContainerNotes'],
    isOrderPaid: ['generatePaymentLink'],
    isInvoiced: ['generatePaymentLink'],
    isLinkGenerated: ['generatePaymentLink'],
  }
  // if blocker value array includes action return true
  return disabledActions[blockerValue]?.includes(action.name)
}

const filterInactiveActions = (actions, record, tableName) => {
  const blockers = actionBlockers(record, tableName)
  return blockers?.length
    // filter actions that any of it's blockers returns true
    ? actions?.filter(action => !blockers.some(blockerValue => inactiveActions(action, blockerValue)))
    : actions
}

const disableInactiveActions = (actions, record, tableName) => {
  const blockers = actionBlockers(record, tableName)
  actions = actions?.map(action => ({
    ...action,
    // set disabled to true if any blocker returns true
    disabled: blockers?.some(blockerValue => inactiveActions(action, blockerValue))
  }))

  return actions?.sort((a, b) => a.disabled - b.disabled)
}

const actionBlockers = (item, tableName) => {
  const isOrderClientBlocked = clientStatus(item.client?.blocked)
  const isOrderDeletable = !item.deletable && 'OrderUndeletable'
  const isSwap = item.swapCourse && 'Wymiana'
  const isSubstitution = item.courseType === 'Podstawienie' && !item.swapCourse && 'Substitution'
  const isPickable = !item?.order?.containerPickable && 'containerUnpickable'
  const hasBdoCard = (item.order?.bdoNumber && item.order?.bdoNumber !== '-') || (item?.swapCourse?.order?.bdoNumber && item?.swapCourse.order?.bdoNumber !== '-') ? 'bdoCardAssigned' : 'bdoCardUnssigned'
  const isClientNotKpoEligible = (item.order?.client?.bdoType !== 'Prywatny' ||
    item.order?.client?.clientType !== 'Osoba fizyczna') && 'clientNotKpoEligible'
  const allButGenerationConfirmed = item.cardStatus !== 'Potwierdzenie wygenerowane' && 'allButGenerationConfirmed'
  const allButTakeoverConfirmed = item.cardStatus !== 'Potwierdzenie przejęcia' && 'allButTakeoverConfirmed'
  const allButConfirmed = item.cardStatus !== 'Zatwierdzona' && 'allButConfirmed'
  const unableToRevise = (item.cardStatus !== 'Odrzucona' &&
    !item.senderName?.replaceAll('-', '').toLowerCase().includes('ekologis')) && 'unableToRevise'
  const isTaskDone = item.done ? 'taskDone' : 'taskNotDone'
  const courseWithoutAddress = !item.order?.address?.id && 'courseWithoutAddress'
  const isContainerNotReserved = !item.reserved && 'containerNotReserved'
  const transportTimeUpdateNotAllowed = (item.cardStatus !== 'Zatwierdzona' ||
  item.client?.bdoType !== 'Upoważnienie KPO') && 'transportTimeUpdateNotAllowed'
  const isOrderTemplateTransformed = item.transformed && 'orderTemplateTransformed'
  const isNotReceipt = item.settlementType !== 'Paragon' && 'notReceipt'
  const isNotInvoice = item.settlementType !== 'Faktura' && 'notInvoice'
  const isInvoiceCreated = !!item.invoice && 'invoiceAlreadyCreated'
  const courseWithoutOrder = !!item.invoice && 'invoiceAlreadyCreated'
  const isCorrection = item.invoiceNumber?.split('/')?.shift() === 'FVK' && 'isCorrection'
  const notHaveOrder = !item.order && 'notHaveOrder'
  const isOrderPaid = ['Zapłacone', 'Zapłacone online'].includes(item.payment?.paymentStatus) && 'isOrderPaid'
  const isInvoiced = !item.withoutDocuments && 'isInvoiced'
  const isLinkGenerated = !!item.payment?.payByLinkToken && 'isLinkGenerated'

  const blockerValue = {
    bdoCards: [allButGenerationConfirmed, allButTakeoverConfirmed, allButConfirmed, transportTimeUpdateNotAllowed, unableToRevise], // todo this may require update because of new tabs
    clientAppOrders: [isOrderTemplateTransformed],
    clients: [clientStatus(item.blocked)],
    containers: [isContainerNotReserved, notHaveOrder],
    courses: [item.courseStatus, item.courseType, anyContainerValue(item), isSwap, isSubstitution, isPickable, hasBdoCard, isClientNotKpoEligible, courseWithoutAddress, courseWithoutOrder],
    invoices: [isNotReceipt, isNotInvoice, isInvoiceCreated, isCorrection],
    order: [isOrderClientBlocked],
    orders: [isOrderClientBlocked, isOrderDeletable, isOrderPaid, isInvoiced, isLinkGenerated],
    orderSchedules: [scheduleStatus(item.active)],
    ordersCompleted: [isOrderClientBlocked],
    orderTemplates: [isOrderTemplateTransformed],
    routes: [item.stops ? item.stops[0].course.courseStatus : ''],
    tasks: [isTaskDone],
  }
  return blockerValue[tableName] || null
}

export {
  getQuickActions,
  getSidebarActions,
  getContextMenu,
  getHeaderButtons,
  filterInactiveActions,
  disableInactiveActions
}
