import Base from './base'
import api from '../api/v1'

const base = new Base('containers')

const state = {
  counters: {}
}
const mutations = {
  SET_COUNTERS (state, counters) {
    state.counters = counters
  },
}
const actions = {
  getCounters ({ commit, rootGetters }) {
    commit('SET_PROCESSING', true)
    const params = rootGetters['tables/getTableParameters']('containers').params
    api.getContainersCounters(params)
      .then((resp) => {
        commit('SET_COUNTERS', resp.data)
      })
      .finally(() => {
        commit('SET_PROCESSING', false)
      })
  },
}

const getters = {
  getStatistics: (state) => {
    const results = {}
    for (const [key, value] of Object.entries(state.counters)) {
      results[key] = {
        text: `${key}:`,
        value
      }
    }
    return results
  }
}

base.state = {
  ...base.state,
  ...state
}
base.mutations = {
  ...base.mutations,
  ...mutations
}
base.actions = {
  ...base.actions,
  ...actions
}
base.getters = {
  ...base.getters,
  ...getters
}

export default base
