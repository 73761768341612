<template>
  <div class="details details-preview">
    <DetailsToolbar
      :title="getPreviewTitle"
      height="80px"
      is-preview
      @closeDetails="closeSidebar"
    />
    <Tabs
      v-if="hasSwapCourse"
      :tabs="tabs"
      @goTo="goTo"
      class="px-2 details-tabs"
    />
    <div class="pb-2">
      <div class="detail-section__wrapper mb-5 mx-2">
        <div class="detail-section__header">
          {{ tableDetails.text }}
          <div
            v-if="isBlocked"
            class="block-status ml-2"
          >
            Blokada
          </div>
        </div>
        <div class="detail-section__content">
          <div>
            <v-row class="pt-2">
              <v-col
                v-for="field in tableDetails.fields"
                :key="field.name"
                cols="12"
                sm="12"
                md="6"
                class="py-0"
              >
                <div class="input--select">
                  <v-textarea
                    :label="field.text"
                    :title="value(field)"
                    readonly
                    :value="value(field)"
                    hide-details
                    auto-grow
                    no-resize
                    rows="1"
                    class="mt-0"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <v-footer
      absolute
      tile
      color="white"
      width="100%"
      elevation="0"
      class="px-6"
      height="80px"
    >
      <v-spacer />
      <ActionButtons
        :actions="actions"
        :item="data"
      />
    </v-footer>
  </div>
</template>

<script>
import { getTableDetailsPreview, getGroupedItemValue, getTitleFormatted, getBlockerValue } from '../../utils'
import { priceValues, formatValue } from '../../utils/typesEnum'
import { mapActions } from 'vuex'
import get from 'lodash/get'
import ActionButtons from '../Elements/ActionButtons'
import DetailsToolbar from './Sections/DetailsToolbar'
import Tabs from '../Layout/Tabs'

export default {
  components: {
    Tabs,
    ActionButtons,
    DetailsToolbar
  },
  props: {
    tableName: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: false
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    tab: 0,
    tabs: [
      { value: 0, text: 'Podstawienie' },
      { value: 1, text: 'Zabranie' }
    ]
  }),
  computed: {
    tableDetails () {
      return getTableDetailsPreview(this.tableName)
    },
    hasSwapCourse () {
      if (!this.data) return false
      const { swapCourse, courses } = this.data
      return swapCourse || (courses?.length > 1)
    },
    getPreviewTitle () {
      return getTitleFormatted(this.tableDetails.title, this.data)
    },
    isBlocked () {
      return getBlockerValue(this.data, this.tableName)
    }
  },
  methods: {
    ...mapActions({
      closeSidebar: 'layout/closeSidebar'
    }),
    value (field) {
      const path = getGroupedItemValue(field.value, this.tab)
      const value = get(this.data, path, '-')
      return priceValues.includes(field.name) ? formatValue(value, 'price') : value
    },
    goTo (tab) {
      this.tab = tab.value
    }
  }
}
</script>

<style lang="scss" scoped>
.details-preview .input--select::v-deep .v-input .v-input__slot  {
  font-size: 13px;
  line-height:18px;
  padding-bottom:0;
  margin:0;
  textarea {
    padding-top: 0;
  }
}
</style>
