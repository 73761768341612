<template>
  <div class="details details--full alerts">
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
      class="pl-4"
    />
    <div class="px-4">
      <v-row>
        <v-col
          class=""
          cols="12"
          md="8"
        >
          <div class="detail-section__wrapper mb-5">
            <v-form
              class="d-flex flex-row pa-2"
              @submit.prevent="sendAlert"
            >
              <v-text-field
                class="pa-0 alert-text"
                v-model="text"
                placeholder="Wpisz treść komunikatu"
                outlined
                hide-details
                clearable
              />
              <v-btn
                :disabled="!selectedDrivers.length || !text.length"
                color="primary"
                class="base-hover ml-2"
                v-shortkey="{enter:['enter']}"
                @shortkey.native="sendAlert"
                @click="sendAlert"
              >
                Wyślij wiadomość
              </v-btn>
            </v-form>
          </div>
          <div class="detail-section__wrapper mb-5">
            <div class="detail-section__header">
              Komunikaty
            </div>
            <div class="detail-section__content">
              <Table
                class="alerts-table"
                disable-personalization
                disable-pagination
                :table-name="tableName"
              />
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <div class="detail-section__wrapper mb-5">
            <div class="detail-section__header">
              Odbiorcy komunikatu
            </div>
            <div class="detail-section__content">
              <div class="px-4 detail-section__buttons">
                <v-btn
                  text
                  color="primary"
                  class="btn--text"
                  @click="selectAllDrivers"
                >
                  Zaznacz wszystkich
                </v-btn>
                <v-btn
                  text
                  class="btn--text mr-1"
                  :disabled="!selectedDrivers.length"
                  @click="unselectAllDrivers"
                >
                  Odznacz wszystkich
                </v-btn>
              </div>
              <div class="px-4 py-2 alerts__drivers-wrapper">
                <v-btn
                  v-for="driver in drivers"
                  :key="driver.id"
                  text
                  block
                  small
                  class="pl-1 py-0 alerts__drivers"
                  @click="selectDriver(driver.id)"
                >
                  <Icon
                    v-if="selectedDrivers.includes(driver.id)"
                    name="checkbox_active"
                    color="#2CB56B"
                    size="medium"
                  />
                  <Icon
                    v-else
                    size="medium"
                    name="checkbox_inactive"
                  />
                  <div class="ml-3 text-subtitle-2">
                    {{ driver.firstName }} {{ driver.lastName }}
                  </div>
                  <v-spacer />
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Table from '../../components/Table/Table'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Header,
    Table
  },
  data: () => ({
    tableName: 'alerts',
    selectedDrivers: [],
    text: ''
  }),
  computed: {
    ...mapState({
      drivers: state => state.drivers.items
    })
  },
  mounted () {
    this.getDrivers()
  },
  methods: {
    ...mapActions({
      addNewAlert: 'alerts/addNewItem',
      getAlerts: 'alerts/getItems',
      getDrivers: 'drivers/getItems'
    }),
    selectDriver (id) {
      if (this.selectedDrivers.includes(id)) {
        this.selectedDrivers = this.selectedDrivers.filter((item) => item !== id)
      } else {
        this.selectedDrivers.push(id)
      }
    },
    selectAllDrivers () {
      this.selectedDrivers = this.drivers.map(driver => driver.id)
    },
    unselectAllDrivers () {
      this.selectedDrivers = []
    },
    sendAlert () {
      if (this.text && this.selectedDrivers.length) {
        const params = {
          text: this.text,
          driverIds: this.selectedDrivers
        }
        this.addNewAlert({ params })
          .then(() => {
            this.getAlerts()
          })
        this.text = ''
        this.unselectAllDrivers()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-text::v-deep {
  .v-input__append-inner {
    margin-top: 9px !important;
    opacity: 1 !important;
  }
}
.alerts-table::v-deep table {
  .table__column {
    max-width: 400px;
    white-space: normal;
    height: auto;
    min-height: 36px;

  }
  .table__column--row {
    height: 100%;
    padding: 8px 0;
    text-overflow: clip;
    white-space: normal;
    line-height:18px;
  }
}
</style>
