import Base from './base'
import api from '../api/v1'
import get from 'lodash/get'
import { downloadFile } from '../utils'
import { decamelize } from 'humps'
import debounce from 'lodash/debounce'
import apiMessages from '../const/apiMessages'

const orderMatchesFiltersCriteria = (item, filters) => {
  return filters.every(filter => {
    const { filterBy, propPath, checkCourseType } = filter
    const filterValue = filterBy.value ?? filterBy
    if (checkCourseType) {
      if (filterValue === 'Wymiana') return item.swap
      else return item.courses.some(course => course.courseType === filterValue)
    }
    return Array.isArray(filterValue)
      ? filterValue.some(singleFilter => get(item, propPath) === (singleFilter.value ?? singleFilter))
      : get(item, propPath) === filterValue
  })
}

const orders = (tableName) => {
  const base = new Base(tableName, orderMatchesFiltersCriteria)

  const actions = {
    exportOrdersSpreadsheet ({ state, commit, rootState, rootGetters }) {
      commit('SET_PROCESSING', true)
      const orderIds = state.selectedItems

      const orderHeaders = rootState.tables[tableName].parameters
        .filter(header => header.show && !['select', 'actions'].includes(header.name))
        .map(header => decamelize(header.name))

      const { order, filters, departmentId } = rootGetters['tables/getTableParameters'](tableName).params

      api.exportOrdersSpreadsheet({
        departmentId,
        orderHeaders,
        orderIds,
        order,
        filters
      }).then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'text/csv' }))
        downloadFile(url, `Zlecenia ${state.dateRange}`)
        commit('UNSELECT_ALL_ITEMS')
      })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    editItem: debounce(function ({ commit, dispatch }, data) {
      commit('SET_PROCESSING', true)
      return new Promise((resolve) => {
        api.updateItem(data)
          .then((resp) => {
            const response = resp.data?.success || resp.data?.errors
            const message = tableName === 'orders' && response ? response : apiMessages(tableName, 'update')
            const messageType = resp.data?.errors ? 'error' : 'success'
            resolve(resp.data)
            dispatch('snackbar/showSnackbar', {
              message,
              type: messageType
            }, { root: true })
          })
          .finally(() => {
            commit('SET_PROCESSING', false)
          })
      })
    }, 300, { leading: true }),
    sendPaymentLink({ commit }, data) {
      commit('SET_PROCESSING', true)
      return api.sendPaymentLink(data)
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    }
  }

  base.actions = {
    ...base.actions,
    ...actions
  }

  return base
}

export default orders
