<template>
  <hsc-window-style-white>
    <hsc-window
      title="Notatka do połączenia"
      position-hint="-60 / -30"
      :close-button="true"
      :is-open.sync="showModal"
      class="floating-popup"
      :style="`z-index: ${dialogZIndex}`"
      @move-start="updateDialogIndex"
    >
      <v-card
        min-width="400px"
        color="white"
      >
        <v-card-text class="custom-form pt-6">
          <v-textarea
            v-model="notes"
            outlined
            :rows="3"
            auto-grow
            label="Notatki"
            hide-details
            placeholder="Wpisz notatki do rozmowy"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="base-hover ml-auto"
            @click="addCallEntry"
          >
            Stwórz wpis
          </v-btn>
        </v-card-actions>
      </v-card>
    </hsc-window>
  </hsc-window-style-white>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    showModal: false,
    notes: null,
    dialogZIndex: 10,
  }),
  mounted () {
    this.showModal = !!this.$route.query.callStart
  },
  watch: {
    showModal (isShown) {
      if (isShown) this.updateDialogIndex()
    }
  },
  methods: {
    ...mapActions({
      createCallEntry: 'callEntry/createCallEntry',
      updateDialogIndex: function (dispatch) {
        this.dialogZIndex = this.$store.state.layout.dialogTopIndex
        return dispatch('layout/updateDialogIndex')
      },
    }),
    addCallEntry () {
      this.createCallEntry(this.notes)
        .then(() => {
          this.showModal = false
        })
    }
  }
}
</script>
