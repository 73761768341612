<template>
  <div class="details__row py-4">
    <v-row>
      <v-data-table
        :headers="detail.fields"
        :items="invoiceItems"
        item-key="id"
        hide-default-footer
        :items-per-page="500"
        width="100%"
        class="w-100 my-n4"
      />
    </v-row>
  </div>
</template>

<script>
import { mapArray } from '../../../utils/typesEnum'
export default {
  props: {
    detail: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: false
    }
  },
  computed: {
    invoiceItems () {
      return this.data ? mapArray(this.data.invoiceItems) : []
    }
  }
}
</script>
