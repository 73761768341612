import { bdoTabsSharedFilters, bdoTabsSharedParameters } from './sharedParams'
import cloneDeep from 'lodash/cloneDeep'

export default {
  header: [],
  table: {
    noDateRange: true,
    isWebSocketHandled: true,
    sorting: {
      sortBy: 'order.refNumber',
      sortDesc: false
    },
    specialFlag: {
      endpoint: 'bdoCards/revisionIndex',
    },
    filters: [
      { name: 'cardStatuses', text: 'Status karty', filterBy: ['Odrzucona', 'Potwierdzenie wygenerowane'], propPath: 'cardStatus', options: 'revisionBdoCardStatuses', multiple: true, default: ['Odrzucona', 'Potwierdzenie wygenerowane'], hide: true },
      ...cloneDeep(bdoTabsSharedFilters),
    ],
    parameters: [
      ...cloneDeep(bdoTabsSharedParameters),
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false },
    ],
    actions: [],
    menu: []
  },
  details: {}
}
