import { bdoTabsSharedFilters, bdoTabsSharedParameters } from './sharedParams'
import cloneDeep from 'lodash/cloneDeep'

export default {
  header: [],
  table: {
    maximumDateRange: 1,
    noDateRange: true,
    isWebSocketHandled: true,
    specialFlag: {
      endpoint: 'bdoCards',
      forAssignment: true,
    },
    sorting: {
      sortBy: 'order.refNumber',
      sortDesc: false
    },
    filters: [
      { name: 'cardStatuses', text: 'Status karty', filterBy: ['Zatwierdzona', 'Potwierdzenie wygenerowane'], propPath: 'cardStatus', options: 'assignmentBdoCardStatuses', default: ['Zatwierdzona', 'Potwierdzenie wygenerowane'], multiple: true, hide: true },
      ...cloneDeep(bdoTabsSharedFilters),
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false },
    ],
    parameters: [
      ...cloneDeep(bdoTabsSharedParameters),
      { name: 'senderName', text: 'Przekazujący', value: 'senderName', show: true },
      { name: 'receiverName', text: 'Przyjmujący', value: 'receiverName', show: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [],
    menu: []
  },
  details: {}
}
