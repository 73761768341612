import actions from '../../actions'

export default {
  header: [
    { ...actions.addNewInvoiceItemTemplate, create: true }
  ],
  table: {
    noApi: true,
    sorting: {
      sortBy: 'id',
      sortDesc: true
    },
    parameters: [
      { name: 'id', text: 'Nr usługi', value: 'id', show: true },
      { name: 'name', text: 'Nazwa usługi', value: 'name', show: true },
      { name: 'netValue', text: 'Wartość netto', value: 'netValue', show: true },
      { name: 'vatPercentage', text: 'Stawka vat', value: 'vatPercentage', show: true },
      { name: 'actions', text: '', value: '', show: true },
    ],
    actions: [
      { ...actions.deleteInvoiceItemTemplate, icon: 'usun' }
    ],
    menu: [
      { ...actions.deleteInvoiceItemTemplate }
    ]
  },
  details: {
    title: '{{name}}',
    actions: [
      { ...actions.deleteInvoiceItemTemplate, redirect: true }
    ],
    sections: [
      {
        name: 'invoiceItemTemplates',
        text: 'Informacje ogólne',
        type: 'left',
        fields: [
          { name: 'id', text: 'Nr usługi', value: 'id', col: 3 },
          { name: 'name', text: 'Nazwa usługi', value: 'name', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'netValue', text: 'Wartość netto', value: 'netValue', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'vatPercentage', text: 'Stawka vat', value: 'vatPercentage', type: 'textField', col: 3, editable: true, rules: ['required'] },
        ]
      }
    ]
  }
}
