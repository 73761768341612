import { Table } from '../models'

export const logisticsTables = {
  addresses: new Table(),
  aggregatePrices: new Table(),
  aggregateTypes: new Table(),
  alerts: new Table(),
  assigningBdoCards: new Table(),
  bdoCards: new Table(),
  businessPlaces: new Table(),
  callEntries: {
    ...new Table(),
    filters: [{ name: 'clientId', filterBy: '' }]
  },
  clientAppOrders: new Table(),
  clientBDO: new Table(),
  clientInvoices: new Table(),
  clientOrders: new Table(),
  clients: new Table(),
  clientsReports: new Table(),
  codDeposits: new Table(),
  communes: new Table(),
  containers: new Table(),
  containersMap: new Table(),
  coursesMap: new Table(),
  courses: new Table(),
  containersHistory: new Table(),
  debrisPrices: new Table(),
  drivers: new Table(),
  driversLocation: new Table(),
  incidents: new Table(),
  invoiceItemTemplates: new Table(),
  invoices: new Table(),
  middlemen: {
    // middlemen is faked clients list with filter isMiddleman: true, for selects and filters
    ...new Table(),
    specialFlag: { endpoint: 'clients' },
    sorting: { sortBy: 'name', sortDesc: true },
    parameters: [{ name: 'name', value: 'name' }],
    filters: [{ name: 'isMiddleman', filterBy: true, initial: true, required: true, default: true }],
  },
  orders: new Table(),
  ordersCompleted: new Table(),
  orderSchedules: new Table(),
  orderTemplates: new Table(),
  prospects: new Table(),
  reports: {
    ...new Table(),
    filters: [
      { name: 'clientId', text: 'Klient', options: 'clients', filterBy: '' },
      { name: 'driverId', text: 'Kierowca', options: 'drivers', filterBy: '' },
      { name: 'containerTypeId', text: 'Typ kontenera', options: 'containerTypes', filterBy: '' },
      { name: 'patronId', text: 'Opiekun', options: 'patrons', filterBy: '' },
    ]
  },
  revisionIndex: new Table(),
  routes: new Table(),
  routesCompleted: new Table(),
  tasks: new Table(),
  terminals: new Table(),
  transportPrices: new Table(),
  users: new Table(),
  vehicles: new Table(),
}

export const clientTables = {
  clientOrders: new Table(),
}

export const sharedTables = {
  containerTypes: new Table(),
  debrisTypes: new Table(),
  departments: new Table(),
}
