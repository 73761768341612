<script>
import { mapActions, mapState } from 'vuex'
import humps from 'humps'

export default {
  data: () => ({
    activeChannels: []
  }),
  computed: {
    ...mapState({
      departmentId: state => state.core.department.id,
      token: state => state.auth.token
    })
  },
  mounted () {
    this.$cable.connection.connect(`${process.env.VUE_APP_WSS_BASE_URL}?Authorization=${this.token}`)
  },
  methods: {
    ...mapActions({
      closeSidebar: 'layout/closeSidebar',
      setSingleOrder: 'order/setSingleOrder',
      setSwapOrder: 'order/setSwapOrder',
      clearSingleOrder: 'order/clearSingleOrder',
      clearSwapOrder: 'order/clearSwapOrder',
      setSingleCourse: 'course/setSingleCourse',
      setSingleIncident: 'incident/setSingleIncident',
      clearSingleIncident: 'incident/clearSingleIncident',
      setSingleInvoice: 'invoice/setSingleInvoice',
      clearSingleInvoice: 'invoice/clearSingleInvoice',
      setSingleClient: 'client/setSingleClient',
      clearSingleClient: 'client/clearSingleClient',
      setSingleSchedule: 'orderSchedule/setSingleSchedule',
      clearSingleSchedule: 'orderSchedule/clearSingleSchedule',
      setSingleBdoCard: 'bdoCard/setSingleBdoCard',
      clearSingleBdoCard: 'bdoCard/clearSingleBdoCard',
      setSingleOrderTemplate: 'orderTemplate/setSingleOrderTemplate',
      clearSingleOrderTemplate: 'orderTemplate/clearSingleOrderTemplate',
      setSingleClientAppOrder: 'clientAppOrder/setSingleClientAppOrder',
      clearSingleClientAppOrder: 'clientAppOrder/clearSingleClientAppOrder',
    }),
    subscribeSocket (channel, { orderId, clientId, orderScheduleId, incidentId, invoiceId, bdoCardId, orderTemplateId, clientAppOrderId, dueDate } = {}) {
      if (!this.activeChannels.find((item) => item === channel)) {
        this.activeChannels.push(channel)
        const params = {
          channel,
          departmentId: this.departmentId,
          orderId,
          clientId,
          orderScheduleId,
          incidentId,
          invoiceId,
          bdoCardId,
          orderTemplateId,
          clientAppOrderId,
          dueDate
        }
        this.$cable.subscribe(humps.decamelizeKeys(params))
      }
    },
    unsubscribeSocket (channel) {
      if (this.activeChannels.find((item) => item === channel)) {
        this.$cable.unsubscribe(channel)
        this.activeChannels = this.activeChannels.filter((item) => item !== channel)
      }
    },
    unsubscribeAllSockets () {
      this.activeChannels.map((channel) => {
        this.$cable.unsubscribe(channel)
      })
      this.activeChannels = []
    },
    captureChanges (data, tableName = this.tableName) { // data -> actionType, records, deletedItemId (underscored)
      data.records = data?.records?.map(record => {
        const recordData = record.data || record
        return { ...recordData, fromWS: true }
      }) || []
      this.$store.dispatch(`${tableName}/updateItemsWS`, humps.camelizeKeys(data))
    },
    captureSingleChange (data) {
      const { records, actionType, deletedItemId } = humps.camelizeKeys(data)
      if (actionType === 'update') {
        records.map((record) => {
          if (this.isRecordId(this.order, record.id)) this.setSingleOrder(record.data)
          if (this.isRecordId(this.swapOrder, record.id)) this.setSwapOrder(record.data)
          if (this.isRecordId(this.orderSchedule, record.id)) this.setSingleSchedule(record.data)
          if (this.isRecordId(this.client, record.id)) this.setSingleClient(record.data)
          if (this.isRecordId(this.incident, record.id)) this.setSingleIncident(record.data)
          if (this.isRecordId(this.invoice, record.id)) this.setSingleInvoice(record.data)
          if (this.isRecordId(this.bdoCard, record.id)) this.setSingleBdoCard(record.data)
          if (this.isRecordId(this.orderTemplate, record.id)) this.setSingleOrderTemplate(record.data)
          if (this.isRecordId(this.clientAppOrder, record.id)) this.setSingleClientAppOrder(record.data)
        })
      }
      if (actionType === 'delete') {
        if (this.isRecordId(this.order, deletedItemId, true)) this.clearSingleOrder()
        if (this.isRecordId(this.swapOrder, deletedItemId, true)) this.clearSwapOrder()
        if (this.isRecordId(this.orderSchedule, deletedItemId, true)) this.clearSingleSchedule()
        if (this.isRecordId(this.client, deletedItemId, true)) this.clearSingleClient()
        if (this.isRecordId(this.incident, deletedItemId, true)) this.clearSingleIncident()
        if (this.isRecordId(this.invoice, deletedItemId, true)) this.clearSingleInvoice()
        if (this.isRecordId(this.bdoCard, deletedItemId, true)) this.clearSingleBdoCard()
        if (this.isRecordId(this.orderTemplate, deletedItemId, true)) this.clearSingleOrderTemplate()
        if (this.isRecordId(this.clientAppOrder, deletedItemId, true)) this.clearSingleClientAppOrder()
      }
    },
    isRecordId (record, id, isDeleted) {
      if (record?.id && record.id === id) {
        if (isDeleted) this.closeSidebar()
        return true
      }
      return false
    }
  }
}
</script>
