function filtersDebrisTypeSearch(item, queryText, itemText) {
  const codeParsed = item.name
    ?.split('-')
    ?.shift()
    ?.replace(/\s/g, '')
  const searchText = (itemText + codeParsed).toLowerCase()
  return searchText.indexOf(queryText.toLowerCase()) > -1
}
const debrisName = type => `${type.code} - ${type.displayName}`
const communeName = commune => `${commune.name} (${commune.communeType})`

export const bdoTabsSharedFilters = [
  { name: 'clientId', text: 'Klient', options: 'clients', filterBy: '', propPath: 'client.id' },
  { name: 'cardType', text: 'Typ karty', options: 'bdoCardTypes', filterBy: '', propPath: 'cardType' },
  { name: 'reportedDebrisTypeId', text: 'Raportowany typ odpadu', options: 'debrisTypes', nameGetter: debrisName, specialFilter: filtersDebrisTypeSearch, filterBy: '', propPath: 'reportedDebrisType.id' },
  { name: 'revisedDebrisTypeId', text: 'Typ odpadu (przed korektą)', options: 'debrisTypes', nameGetter: debrisName, specialFilter: filtersDebrisTypeSearch, filterBy: '', propPath: 'revisedDebrisType.id' },
  { name: 'receiverBusinessPlaceId', text: 'MPD przyjmującego', options: 'receiverBusinessPlaces', filterBy: '', propPath: 'receiver.businessPlace.id', disabled: true },
  { name: 'senderBusinessPlaceId', text: 'MPD przekazującego', options: 'senderBusinessPlaces', filterBy: '', propPath: 'sender.businessPlace.id', disabled: true },
  { name: 'communeId', text: 'Gmina', options: 'communes', filterBy: '', propPath: 'commune.id', nameGetter: communeName, disabled: true },
  { name: 'wasteGenerating', text: 'Wytwarzanie odpadów', filterBy: '', options: '', falseValue: '', propPath: 'wasteGenerating', switch: true }
]

export const bdoTabsSharedParameters = [
  { name: 'cardNumber', text: 'Nr karty', value: 'cardNumber', show: true, sortable: true },
  { name: 'revisionNumber', text: 'Nr korekty', value: 'revisionNumber', show: true },
  { name: 'cardType', text: 'Typ karty', value: 'cardType', show: true, sortable: false },
  { name: 'cardStatus', text: 'Status karty', value: 'cardStatus', show: true },
  { name: 'refNumber', text: 'Nr zlecenia', value: 'order.refNumber', show: true },
  { name: 'orderType', text: 'Typ zlecenia', value: 'orderType', show: true },
  { name: 'debrisTypeCode', text: 'Kod odpadu', value: 'reportedDebrisType.code', show: true },
  { name: 'debrisTypeName', text: 'Typ odpadu', value: 'reportedDebrisType.displayName', show: true },
  { name: 'revisedDebrisTypeCode', text: 'Kod odpadu (przed korektą)', value: 'revisedDebrisType.code', sortable: false, show: true },
  { name: 'revisedDebrisTypeName', text: 'Typ odpadu (przed korektą)', value: 'revisedDebrisType.displayName', sortable: false, show: true },
  { name: 'wasteCodeExtended', text: 'kod ex', value: 'wasteCodeExtended', show: true },
  { name: 'wasteCodeExtendedDescription', text: 'Ex info', value: 'wasteCodeExtendedDescription', show: true },
  { name: 'reportedWeight', text: 'Masa odpadów [T]', value: 'reportedWeight', show: true },
  { name: 'revisedWeight', text: 'Masa odpadów [T] (przed korektą)', value: 'revisedWeight', show: true },
  { name: 'ordeReportedWeight', text: 'Raportowana waga [T]', value: 'order.reportedWeight', show: true, sortable: false },
  { name: 'client', text: 'Klient', value: 'client.name', clientLabel: true, show: true },
  { name: 'commune', text: 'Gmina', value: 'commune.name', show: true },
  { name: 'communeType', text: 'Typ gminy', value: 'commune.communeType', show: true },
  { name: 'vehicleRegNumber', text: 'Nr rejestracyjny', value: 'vehicleRegNumber', show: true },
  { name: 'plannedTransportTime', text: 'Planowany czas', value: 'plannedTransportTime', show: true },
  { name: 'realTransportTime', text: 'Rzeczywisty czas', value: 'realTransportTime', show: true },
  { name: 'approvedAt', text: 'Czas zatwierdzenia', value: 'approvedAt', show: true },
  { name: 'confirmationGeneratedAt', text: 'Czas wygenerowania potwierdzenia', value: 'confirmationGeneratedAt', show: true },
  { name: 'obsolete', text: 'Przeterminowana', value: 'obsolete', show: true },
  { name: 'pinned', text: 'Przypięta', value: 'course', show: true }
]
