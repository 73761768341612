<script>
import { disableInactiveActions, filterInactiveActions, getContextMenu, getSidebarActions } from '../utils/quickActions'
import { getTableDetails } from '../utils'
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      sidebar: state => state.layout.sidebar,
    }),
    availableActions () {
      const actions = this.sidebar.size === 1
        ? getSidebarActions(this.tableName) : getTableDetails(this.tableName).actions
      if (!this.chosenItem) return actions
      const blockerName = (this.sidebar.size === 2 && this.tableName === 'courses') ? 'orders' : this.tableName
      return filterInactiveActions(actions, this.chosenItem, blockerName)
    }
  },
  methods: {
    ...mapActions({
      setContextMenu: 'layout/setContextMenu',
      setSingleViewEntity: 'singleView/setSingleViewEntity',
    }),
    openTableDetails (item) {
      this.setSingleViewEntity({ item, tableName: this.tableName }) // todo remove once singleGets will be ready (from BE)
      this.$router.push({ name: 'defaultSingleView', params: { tableName: this.tableName, id: item.id } })
    },
    openContextMenu ({ item, position }) {
      let actions = getContextMenu(this.tableName)
      actions = disableInactiveActions(actions, item, this.tableName)
      this.setContextMenu({ actions, item, position })
    }
  }
}
</script>
