<template>
  <v-form ref="form">
    <v-row>
      <v-col cols="6">
        <v-menu
          v-model="showPicker"
          v-bind="attributes.menu"
          :disabled="readonly"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="formattedDate"
              class="py-0 pr-0 input--date-picker"
              :rules="showPicker ? [] : [rules.required]"
              :label="label"
              placeholder="-"
              v-bind="{...attrs, ...attributes.textField}"
              v-on="on"
            >
              <template #prepend-inner>
                <Icon
                  name="date"
                  class="mr-2"
                  size="small"
                />
              </template>
            </v-text-field>
          </template>

          <v-date-picker
            v-model="date"
            v-bind="attributes.datePicker"
            @input="normalizeDateWithTime(true)"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="3"
        class="d-flex"
      >
        <v-autocomplete
          outlined
          :items="getOptions(24)"
          label="Czas"
          placeholder="00"
          v-model="hour"
          :rules="[rules.required]"
          class="pa-0 select-small timeslot-before"
          @input="normalizeDateWithTime()"
          append-icon="mdi-chevron-down"
          :readonly="readonly"
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          outlined
          :items="getOptions(59,5)"
          placeholder="00"
          v-model="minute"
          :rules="[rules.required]"
          class="py-0 pl-0 select-small"
          @input="normalizeDateWithTime()"
          append-icon="mdi-chevron-down"
          :readonly="readonly"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { dateStringFormat } from '../../../utils'
import attributes from '../../../const/datePickerAttrributes'
import DateTime from 'luxon/src/datetime'
import rules from '../../../utils/validators'

const formatTime = (time) => time < 10 ? '0' + time : time.toString()

export default {
  props: {
    dateWithTime: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    defaultTime: {
      type: String, // required "05:30"
      required: false
    },
  },
  data: () => ({
    attributes,
    rules,
    tab: 0,
    showPicker: false,
    displayedDate: null,
    date: null,
    hour: null,
    minute: null
  }),
  computed: {
    time () {
      const { hour, minute } = this
      return hour && minute ? `${hour}:${minute}` : '00:00'
    },
    formattedDate () {
      return this.date ? dateStringFormat(this.date) : ''
    }
  },
  mounted () {
    this.date = this.dateWithTime.substring(0, 10)
    this.roundTime(this.dateWithTime)
  },
  methods: {
    normalizeDateWithTime (isDateUpdated) {
      const { date, defaultTime } = this
      if (isDateUpdated && defaultTime) {
        if (date === DateTime.now().toISODate()) {
          this.roundTime()
        } else if (typeof defaultTime === 'string') {
          const [hour, minute] = defaultTime.split(':')
          this.hour = hour
          this.minute = minute
        }
      }
      if (date) {
        const newDate = DateTime.fromISO(new Date(`${date} ${this.time}`).toISOString()).toString()
        this.$emit('changeTime', newDate)
      }
    },
    getOptions (max, step = 1) {
      const optionsArray = []
      for (let i = 0; i <= max; i += step) {
        optionsArray.push(formatTime(i))
      }
      return optionsArray
    },
    roundTime (rawDate) {
      const date = rawDate ? new Date(rawDate) : new Date()

      let hour = date.getHours()
      let minute = Math.ceil(date.getMinutes() / 5) * 5

      if (minute === 60) {
        minute = 0
        hour += 1
      }
      this.hour = formatTime(hour)
      this.minute = formatTime(minute)
    }

  }
}
</script>
