import isLogisticsApp from '../../const/isLogisticsApp'
import { clientOrders as logisticsAppClientOrders, clientBDO, clientInvoices } from './clientTables'
import aggregatePrices from './administration/pricing/aggregatePrices'
import aggregateTypes from './administration/warehouse/aggregateTypes'
import alerts from './alerts'
import assigningBdoCards from './bdoCards/assigningBdoCards'
import bdoCards from './bdoCards/bdoCards'
import businessPlaces from './administration/warehouse/businessPlaces'
import clientAppOrders from './clientAppOrders'
import clientAppClientOrders from './clientOrders'
import clients from './administration/clients'
import clientsReports from './clientsReports'
import codDeposits from './codDeposits'
import communes from './administration/warehouse/communes'
import containers from './containers/containers'
import containersHistory from './containers/containersHistory'
import containersMap from './map/containersMap'
import containerTypes from './containers/containerTypes'
import courses from './courses/courses'
import coursesMap from './map/coursesMap'
import debrisPrices from './administration/pricing/debrisPrices'
import debrisTypes from './administration/warehouse/debrisTypes'
import departments from './administration/warehouse/departments'
import drivers from './administration/warehouse/drivers'
import driversLocation from './map/driversLocation'
import incidents from './incidents'
import invoiceItemTemplates from './administration/invoiceItemTemplates'
import invoices from './invoices'
import orders from './orders/orders'
import orderSchedules from './orders/orderSchedules'
import ordersCompleted from './orders/ordersCompleted'
import orderTemplates from './orderTemplates'
import prospects from './prospects'
import reports from './reports'
import revisionIndex from './bdoCards/revisionIndex'
import routes from './courses/routes'
import routesCompleted from './courses/routesCompleted'
import tasks from './tasks'
import terminals from './administration/warehouse/terminals'
import transportPrices from './administration/pricing/transportPrices'
import users from './administration/users'
import vehicles from './administration/warehouse/vehicles'

const logisticsConfigs = {
  aggregatePrices,
  aggregateTypes,
  alerts,
  assigningBdoCards,
  bdoCards,
  businessPlaces,
  clientAppOrders,
  clientBDO,
  clientInvoices,
  clientOrders: logisticsAppClientOrders,
  clients,
  clientsReports,
  codDeposits,
  communes,
  containers,
  containersHistory,
  containersMap,
  courses,
  coursesMap,
  debrisPrices,
  drivers,
  driversLocation,
  incidents,
  invoiceItemTemplates,
  invoices,
  orders,
  orderSchedules,
  ordersCompleted,
  orderTemplates,
  prospects,
  reports,
  revisionIndex,
  routes,
  routesCompleted,
  tasks,
  terminals,
  transportPrices,
  users,
  vehicles
}

const clientConfigs = {
  clientOrders: clientAppClientOrders
}

const sharedConfigs = {
  containerTypes,
  debrisTypes,
  departments
}

export default {
  ...(isLogisticsApp ? logisticsConfigs : clientConfigs),
  ...sharedConfigs
}
