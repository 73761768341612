<template>
  <div class="contact-details">
    <div class="detail-section__header contact-details__header">
      Dokumenty

      <ActionButtons
        v-if="actions.length"
        :actions="actions"
        :item="client"
      />
    </div>

    <v-divider />

    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="documents"
      class="details-table"
      :items-per-page="-1"
    >
      <template #item="{ item }">
        <TableRow
          table-name="clients"
          class="details-table-row"
          :item="item"
          :header="headers"
          :actions="rowActions"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ActionButtons from '../../Elements/ActionButtons'
import { clientDocuments } from '../../../const/views/administration/clients'
import TableRow from '../../Table/TableRow'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    TableRow,
    ActionButtons
  },
  props: {
    client: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    ...clientDocuments // { headers, rowActions, actions }
  }),
  computed: {
    ...mapState({
      documents: state => state.client.files
    })
  },
  mounted () {
    this.getClientFiles()
  },
  methods: {
    ...mapActions({
      getClientFiles: 'client/getClientFiles'
    })
  }
}
</script>
