import Base from './base'
import api from '../api/v1'
import get from 'lodash/get'

const cardMatchesFiltersCriteria = (item, filters) => {
  return filters.every(filter => {
    const { filterBy, propPath } = filter
    const filterValue = filterBy.value ?? filterBy

    return Array.isArray(filterValue)
      ? filterValue.some(singleFilter => get(item, propPath) === (singleFilter.value ?? singleFilter))
      : get(item, propPath) === filterValue
  })
}

const actions = {
  refreshBdoCards: ({ commit }) => {
    commit('SET_PROCESSING', true)
    return api.refreshBdoCards()
      .finally(() => {
        commit('SET_PROCESSING', false)
      })
  },
}

const module = new Base('bdoCards', cardMatchesFiltersCriteria)
module.actions = {
  ...module.actions,
  ...actions
}
export default module
