<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Tabs
      ref="tabs"
      :tabs="tabs"
      persistent
      @goTo="goTo"
    />
    <Table
      ref="table"
      :key="tableName"
      :table-name="tableName"
      :custom-search="searchAllFields"
      disable-pagination
      @openDetails="(item) => !noDetails && openTableDetails(item)"
      @contextMenu="openContextMenu"
    >
      <template #tableTop>
        <div
          class="filters"
          ref="tableTop"
        >
          <div class="filters__inputs">
            <SearchBar :table-name="tableName" />
          </div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../../components/Layout/Header'
import Tabs from '../../../components/Layout/Tabs'
import Table from '../../../components/Table/Table'
import SearchBar from '../../../components/Filters/SearchBar'
import defaultTableEventsMixin from '../../../mixins/defaultTableEventsMixin'
import tabs from '../../../helpers/warehouseTabs'

export default {
  components: {
    Header,
    Tabs,
    Table,
    SearchBar,
  },
  mixins: [defaultTableEventsMixin],
  data: () => ({
    tableName: 'departments',
    tabs
  }),
  computed: {
    noDetails () {
      return !!this.tabs.find(tab => tab.value === this.tableName)?.noDetails
    }
  },
  methods: {
    goTo (tab) {
      this.tableName = tab.value
    },
    searchAllFields (value, search, item) {
      const getObjectValues = (object) => {
        let result = []
        for (const prop in object) {
          if (typeof object[prop] === 'object' && !Array.isArray(object[prop])) {
            result = [...result, ...getObjectValues(object[prop])]
          } else {
            result.push(object[prop])
          }
        }
        return result
      }

      return getObjectValues(item).some(prop => String(prop).toLowerCase().includes(search.toLowerCase()))
    }
  }
}
</script>
