import { getLocalItem, setLocalItem, noApiManipulation, ignoreDateRange, getTableOptions, mergeTableParameters, isFilterActive } from '../utils'
import findIndex from 'lodash/findIndex'
import { decamelize } from 'humps'
import { Pagination } from '../models'
import { logisticsTables, clientTables, sharedTables } from '../const/tablesState'
import isLogisticsApp from '../const/isLogisticsApp'

export default {
  namespaced: true,
  state: {
    ...(isLogisticsApp ? logisticsTables : clientTables),
    ...sharedTables
  },
  mutations: {
    SET_TABLE_CONFIG (state, tableName) {
      if (!state[tableName].parameters.length) {
        const table = getTableOptions(tableName)
        const savedTable = getLocalItem(tableName)
        state[tableName].parameters = savedTable ? savedTable.parameters : table.parameters || []
        state[tableName].filters = savedTable ? savedTable.filters : table.filters || []
        state[tableName].specialFlag = table.specialFlag || ''
        state[tableName].sorting = savedTable ? savedTable.sorting : table.sorting
      }
    },
    APPEND_TABLE_CONFIG (state, params) {
      const { tableName, parameters } = params
      state[tableName].parameters = parameters
        ? mergeTableParameters(state[tableName].parameters, parameters)
        : state[tableName].parameters
    },
    SET_TABLE_SEARCH (state, { search, tableName }) {
      state[tableName].search = search
    },
    SET_TABLE_SORTING (state, { sorting, tableName }) {
      state[tableName].sorting = sorting
      setLocalItem(tableName, state[tableName])
    },
    SET_TABLE_FILTERS_STATUS (state, { status, tableName }) {
      state[tableName].filtersEnabled = status
    },
    SET_TABLE_FILTERS (state, { filters, tableName }) {
      filters.forEach((filter) => {
        const index = findIndex(state[tableName].filters, ['name', filter.name])
        state[tableName].filters[index].filterBy = filter.filterBy
        function checkFilterParam (filterName) {
          if (Object.keys(filter).includes(filterName)) {
            state[tableName].filters[index][filterName] = filter[filterName]
          }
        }
        checkFilterParam('hide')
        checkFilterParam('disabled')
      })
      setLocalItem(tableName, state[tableName])
    },
    SET_TABLE_PERSONALIZATION (state, { parameters, tableName }) {
      const newParameters = []
      parameters.forEach((item) => {
        const index = findIndex(state[tableName].parameters, ['name', item.name])
        const parameter = state[tableName].parameters[index]
        parameter.show = item.show
        newParameters.push(parameter)
      })
      state[tableName].parameters = newParameters
      setLocalItem(tableName, state[tableName])
    },
    SET_TABLE_PAGINATION (state, { pagination, tableName }) {
      if (state[tableName]?.pagination) {
        state[tableName].pagination = pagination ? { ...state[tableName].pagination, ...pagination } : {}
      }
    }
  },
  actions: {
    setTableConfig ({ commit, dispatch, getters }, { tableName, disableFetch }) {
      dispatch('clearTablePagination', tableName)
      commit('SET_TABLE_CONFIG', tableName)
      // It's still strange we are dispatching fetch action here, especially when method is called "setTableConfig"...
      // I wonder if we actuallu need to do all those actions in table store instead of dispatching actions directly from the table component
      // or keep everything in model modules like courses, orders etc.
      // do we even need vuex for those actions?
      if (!disableFetch) {
        const params = getters.getTableParameters(tableName)
        return new Promise((resolve) => {
          dispatch(`${tableName}/getItems`, params, { root: true })
            .then(() => { resolve() })
        })
      }
    },
    callForNewItems ({ dispatch, getters }, tableName) {
      const params = getters.getTableParameters(tableName)
      return dispatch(`${tableName}/getItems`, params, { root: true })
    },
    appendTableConfig ({ commit }, parameters) {
      commit('APPEND_TABLE_CONFIG', parameters)
    },
    setTableSearch ({ commit, dispatch }, { search, tableName }) {
      dispatch('clearTablePagination', tableName)
      commit('SET_TABLE_SEARCH', { search, tableName })
      if (!noApiManipulation(tableName)) dispatch('callForNewItems', tableName)
    },
    setTableSorting ({ commit, dispatch }, { sorting, tableName }) {
      dispatch('clearTablePagination', tableName)
      commit('SET_TABLE_SORTING', { sorting, tableName })
      if (!noApiManipulation(tableName)) dispatch('callForNewItems', tableName)
    },
    setTablePagination ({ commit, dispatch }, { pagination, tableName, disableFetch }) {
      commit('SET_TABLE_PAGINATION', { pagination, tableName })
      if (!disableFetch) dispatch('callForNewItems', tableName)
    },
    setTableFilters ({ commit, dispatch, rootState }, { filters, tableName, disableFetch }) {
      if (rootState.invoices?.isMultiselectActive) {
        commit('invoices/UNSELECT_ALL_ITEMS', tableName, { root: true })
      }
      if (rootState.courses?.isMultiselectActive) {
        commit('courses/UNSELECT_ALL_ITEMS', tableName, { root: true })
      }
      dispatch('clearTablePagination', tableName)
      commit('SET_TABLE_FILTERS', { filters, tableName })
      if (!disableFetch) dispatch('callForNewItems', tableName)
    },
    setTableFiltersStatus ({ commit, dispatch }, { status, tableName, noApi }) {
      dispatch('clearTablePagination', tableName)
      commit('SET_TABLE_FILTERS_STATUS', { status, tableName })
      if (!noApi) {
        return new Promise((resolve) => {
          dispatch(`${tableName}/getItems`, null, { root: true })
            .then(() => { resolve() })
        })
      }
    },
    setTablePersonalization ({ commit }, { parameters, tableName }) {
      commit('SET_TABLE_PERSONALIZATION', { parameters, tableName })
    },
    clearTablePagination ({ commit, getters }, tableName) {
      const { itemsPerPage } = getters.getTableConfig(tableName)?.pagination || {}
      commit('SET_TABLE_PAGINATION', { tableName, pagination: new Pagination({}, itemsPerPage) })
    }
  },
  getters: {
    getTableConfig: (state) => (tableName) => state[tableName],
    getFilterValue: (state) => (tableName, filterName) => {
      const filterBy = state[tableName].filters.find(filter => filter.name === filterName)?.filterBy
      return filterBy?.value || filterBy
    },
    isAnyFilterSelected: (state) => (tableName) => {
      return state[tableName]?.filters
        ?.filter(filter => !filter.hide)
        ?.some(filter => isFilterActive(filter.filterBy))
    },
    getTableParameters: (state, getters, rootState) => (tableName) => {
      const config = getters.getTableConfig(tableName)

      if (config) {
        const { specialFlag, pagination, search, filters, sorting } = config
        const activeFilters = {}

        if (search) activeFilters.query = search

        if (filters) {
          const setFilters = (filter) => {
            const { filterBy, name, default: defaultValue } = filter
            if (isFilterActive(filterBy)) {
              const filterValue = filterBy.value ?? filterBy
              if (Array.isArray(filterBy)) {
                activeFilters[name] = filterValue.map(filter => filter.value ?? filter)
              } else {
                activeFilters[name] = filterValue
              }
            } else if (defaultValue) {
              activeFilters[name] = defaultValue
            }
          }

          if (config.filtersEnabled) {
            filters.forEach((filter) => {
              const { filterBy, name, options } = filter

              setFilters(filter)

              if (Array.isArray(filterBy) &&
              filterBy.length === 0 &&
              typeof options[0]?.value === 'boolean'
              ) activeFilters[name] = [null]
            })
          } else {
            filters.forEach(filter => {
              if (filter.hide) {
                setFilters(filter)
              } else if (filter.required) { // required filter requires default value
                activeFilters[filter.name] = filter.default
              }
            })
          }
        }

        const specialFlagKeys = typeof specialFlag === 'object' ? Object.keys(specialFlag) : null
        if (specialFlagKeys?.length) {
          specialFlagKeys.map((key) => {
            if (filters?.length) {
              filters.map((item) => {
                if (key === item.name) specialFlag[key] = item.filterBy
              })
            }
          })
        }

        let order
        if (sorting) {
          const field = config.parameters.find((item) => item.value === sorting.sortBy)
          order = {
            field: field ? decamelize(field.name) : 'ref_number',
            direction: sorting.sortDesc ? 'desc' : 'asc',
            date: field ? field.isDateField : false,
            ...sorting
          }
        }

        // daterange is all lower case because of BE nomenclature
        const daterange = ignoreDateRange(tableName) ? undefined
          : rootState[tableName].dateRange
        return {
          tableName,
          params: {
            departmentId: rootState.core.department.id,
            ...specialFlag,
            filters: {
              ...activeFilters,
              daterange
            },
            order,
            ...pagination
          }
        }
      }
      return {}
    },
    getTableSearchQuery: (state) => (tableName) => state[tableName].search,
  }
}
