class History {
  constructor(history = {}) {
    this.content = history.content
    this.date = history.date
    this.id = history.id
    this.user = history.user
  }
}

class File {
  constructor(file = {}) {
    this.filename = file.filename
    this.fileType = file.fileType
    this.id = file.id
    this.size = file.size
    this.timestamp = file.timestamp
    this.url = file.url
  }
}

class Order {
  constructor(order = {}) {
    this.address = order.address || {
      formattedAddress: null,
      notes: null,
      department: { name: null }
    }
    this.aggregateAmount = order?.aggregateAmount
    this.aggregateAmount = order?.aggregateAmount
    this.aggregateType = order?.aggregateType
    this.bdoCardId = order.bdoCardId || null
    this.bdoNumber = order.bdoNumber
    this.bdoStatus = order.bdoStatus
    this.bdoFile = order.bdoFile || new File()
    this.client = order.client || {
      name: null,
      email: null,
      phoneNumber: null,
      bdoNumber: null
    }
    this.container = order.container || {
      id: null,
      refNumberWithType: null
    }
    this.containerNotes = order.containerNotes
    this.containerType = order.containerType || {
      name: null
    }
    this.containerWithType = order.containerWithType
    this.courses = order.courses || []
    this.createdAt = order.createdAt
    this.customServices = order.customServices

    this.debrisType = order.debrisType || {
      name: null,
      code: null
    }
    this.driver = order.driver || {
      fullName: null
    }
    this.driverNotes = order?.driverNotes
    this.dueDate = order.dueDate
    this.files = order.files || [new File()]
    this.history = order.history || [new History()]
    this.id = order.id
    this.invoices = order?.invoices
    this.leaveDate = order.leaveDate
    this.middleman = order.middleman
    this.origin = order?.origin
    this.parsedData = order.parsedData
    this.payer = order.payer
    this.payment = order.payment || {
      debrisGrossValue: null,
      debrisNetValue: null,
      discount: null,
      discountJustification: null,
      leftToPay: null,
      paidValue: null,
      paymentDueDays: null,
      paymentStatus: null,
      paymentType: null,
      payByLinkSessionId: null,
      payByLinkToken: null,
      settlementType: null,
      total: null,
      transportGrossValue: null,
      transportNetValue: null,
      vatPercentage: null,
    }
    this.phoneNumber = order.phoneNumber
    this.pickupDate = order.pickupDate
    this.refNumber = order.refNumber
    this.reportedDebrisType = order?.reportedDebrisType
    this.reportedWeight = order?.reportedWeight
    this.reports = order.reports
    this.swapCourse = order.swapCourse || null
    this.swapOrderId = order.swapOrderId
    this.swapOrderRefNumber = order.swapOrderRefNumber
    this.timeSlot = order.timeSlot
    this.userNotes = order?.userNotes
    this.withoutDocuments = order?.withoutDocuments
  }
}

class SimplifiedOrder {
  constructor(order = {}) {
    this.address = {
      discount: order.addressDiscount
    }
    this.container = {
      containerType: {
        id: order.containerContainerTypeId
      }
    }
    this.containerType = {
      id: order.containerTypeId
    }
    this.course = {
      timeSlot: order.timeSlot
    }
    this.clientId = order.clientId
    this.debrisType = {
      id: order.debrisTypeId
    }
    this.done = order.done
    this.driverNotes = order.driverNotes
    this.id = order.id
    this.parsedData = order.parsedData
    this.payment = {
      debrisNetValue: order.debrisNetValue,
      debrisGrossValue: order.debrisGrossValue,
      paymentType: order.paymentType,
      paymentDueDays: order.paymentDueDays,
      settlementType: order.settlementType,
      discount: order.paymentDiscount,
      vatPercentage: order.vatPercentage,
      discountJustification: order.discountJustification,
    }
    this.phoneNumber = order.phoneNumber
    this.refNumber = order.refNumber
    this.userNotes = order.userNotes
  }
}

class NewOrder {
  constructor(order = {}) {
    this.course = {
      courseType: order?.courses?.[0]?.courseType || 'Podstawienie',
      dueDate: order?.courses?.[0]?.dueDate || new Date().toISOString().substr(0, 10),
      fromTime: order?.courses?.[0]?.timeSlot?.[0] || '8:00',
      toTime: order?.courses?.[0]?.timeSlot?.[1] || '18:00',
      userNotes: order?.courses?.[0]?.userNotes || null
    }
    this.order = {
      addressId: order?.address?.id || null,
      aggregateAmount: order?.aggregateAmount || 5,
      clientId: order?.client?.id || null,
      containerTypeId: order?.containerType?.id || null,
      debrisTypeId: order?.debrisType?.id || null,
      driverNotes: order?.driverNotes,
      middlemanId: order?.middlemanId,
      phoneNumber: order?.phoneNumber,
      quantity: 1,
      userNotes: order?.userNotes,
    }
    this.payment = {
      debrisGrossValue: order?.payment?.debrisGrossValue / 100 || null,
      debrisNetValue: order?.payment?.debrisNetValue / 100 || null,
      discount: order?.payment?.discount / 100 || null,
      discountJustification: order?.payment?.discountJustification || null,
      paymentDueDays: order.payment?.paymentDueDays || null,
      paymentType: order?.payment?.paymentType || 'Gotówka przy podstawieniu',
      settlementType: order?.payment?.settlementType || 'Faktura',
      transportGrossValue: order?.payment?.transportGrossValue / 100 || null,
      transportNetValue: order?.payment?.transportNetValue / 100 || null,
      vatPercentage: order?.payment?.vatPercentage || 8,
    }
  }
}

class PageOffset {
  constructor() {
    this.excludedIds = []
    this.excludedOffset = 0
  }
}

class Pagination {
  constructor(pagination = {}, itemsPerPage) {
    this.itemsLength = pagination.itemsLength || 0
    this.itemsPerPage = itemsPerPage || 150
    this.page = pagination.page || 1
    this.pageCount = pagination.pageCount || 1
  }
}

// Rename Items to Collection
class Items {
  constructor(payload = {}) {
    this.items = payload.items || payload.collection || []
    this.simplifiedList = payload.simplifiedList || []
  }
}

class Table {
  constructor() {
    this.filters = []
    this.filtersEnabled = true
    this.pagination = new Pagination()
    this.parameters = []
    this.search = ''
    this.sorting = {}
    this.specialFlag = {}
  }
}

class Client {
  constructor(client = {}) {
    this.active = client.active || true
    this.addresses = client.addresses || []
    this.aggregateType = client.aggregateType
    this.bdoNumber = client.bdoNumber
    this.bdoType = client.bdoType
    this.blockages = client.blockages || []
    this.blocked = client.blocked || false
    this.blockExplanation = client.blockExplanation
    this.clientType = client.clientType || 'Osoba fizyczna'
    this.contacts = client.contacts || []
    this.controlName = client.controlName
    this.createdAt = client.createdAt
    this.email = client.email
    this.id = client.id
    this.invoiceAddress = client.invoiceAddress
    this.invoiceBuyerName = client.invoiceBuyerName
    this.invoiceCity = client.invoiceCity
    this.invoiceNip = client.invoiceNip
    this.invoicePhoneNumber = client.invoicePhoneNumber
    this.invoicePostalCode = client.invoicePostalCode
    this.isMiddleman = client.isMiddleman || false
    this.middleman = client.middleman
    this.name = client.name
    this.notes = client.notes
    this.patron = client.patron
    this.phoneNumber = client.phoneNumber
    this.purchaseLimit = client.purchaseLimit
    this.purchaseLimitRemaining = client.purchaseLimitRemaining
    this.settlementType = client.settlementType
    this.settlementType = client.settlementType || 'Faktura imienna'
    this.signatureRequired = client.signatureRequired || false
  }
}

class Address {
  constructor(address = {}) {
    this.address = {
      formattedAddress: address.formattedAddress || null,
      lat: address.location?.lat || null,
      lng: address.location?.lng || null
    }
    this.communeId = address.commune?.id || null
    this.contactName = address.contactName || null
    this.departmentId = address.department?.id || null
    this.discount = address.discount || 0
    this.discountJustification = address.discountJustification || null
    this.email = address.email || null
    this.name = address.name || null
    this.notes = address.notes || null
    this.paymentDueDays = address.paymentDueDays || null
    this.paymentType = address.paymentType || null
    this.phoneNumber = address.phoneNumber || null
    this.products = address.products || []
    this.remarks = address.remarks || null
  }
}
class Invoice {
  constructor(invoice = {}) {
    this.address = invoice.address
    this.bill = invoice.bill
    this.buyerName = invoice.buyerName
    this.city = invoice.city
    this.correctionInvoices = invoice.correctionInvoices
    this.createdAt = invoice.createdAt
    this.exportDate = invoice.exportDate
    this.grossValue = invoice.grossValue
    this.id = invoice.id
    this.invoice = invoice.invoice
    this.invoiceConnectionType = invoice.invoiceConnectionType
    this.invoiceItems = invoice.invoiceItems || []
    this.invoiceNumber = invoice.invoiceNumber
    this.invoicePdf = invoice.invoicePdf
    this.issueDate = invoice.issueDate
    this.mainInvoice = invoice.mainInvoice
    this.netValue = invoice.netValue
    this.nip = invoice.nip
    this.notes = invoice.notes
    this.optimaSynchronization = invoice.optimaSynchronization || false
    this.orders = invoice.orders
    this.paidValue = invoice.paidValue || 0
    this.paymentDate = invoice.paymentDate
    this.paymentDueDate = invoice.paymentDueDate
    this.paymentStatus = invoice.paymentStatus
    this.paymentType = invoice.paymentType
    this.postalCode = invoice.postalCode
    this.saleDate = invoice.saleDate
    this.settlementType = invoice.settlementType
  }
}

class Incident {
  constructor(incident = {}) {
    this.client = new Client(incident.client)
    this.createdAt = incident.createdAt
    this.id = incident.id
    this.incidentSource = incident.incidentSource
    this.latestMessageReportedAt = incident.latestMessageReportedAt
    this.messages = incident.messages || []
    this.notes = incident.notes
    this.phoneNumber = incident.phoneNumber
  }
}

class Schedule {
  constructor(schedule = {}) {
    this.active = schedule.active === undefined ? false : schedule.active
    this.address = schedule.address || []
    this.client = new Client(schedule.client)
    this.containerType = schedule.containerType
    this.createdAt = schedule.createdAt
    this.debrisType = schedule.debrisType
    this.discount = schedule.discount
    this.discountJustification = schedule.discountJustification
    this.formattedDays = schedule.formattedDays || []
    this.friday = schedule.friday || false
    this.id = schedule.id
    this.monday = schedule.monday || false
    this.nextRealisationDate = schedule.nextRealisationDate
    this.paymentType = schedule.paymentType
    this.phoneNumber = schedule.phoneNumber
    this.saturday = schedule.saturday || false
    this.settlementType = schedule.settlementType || 'Faktura'
    this.thursday = schedule.thursday || false
    this.timeSlot = schedule.timeSlot
    this.tuesday = schedule.tuesday || false
    this.wednesday = schedule.wednesday || false
  }
}

class Swap {
  constructor(order = {}) {
    this.order = {
      addressId: order.address?.id || null,
      anyContainer: false,
      containerTypeId: order.containerType?.id || null,
      debrisTypeId: order.debrisType?.id || null,
      driverNotes: order.driverNotes || null,
      orderId: order.id || null,
      phoneNumber: order.phoneNumber || null,
      pickupContainerTypeId: null,
      quantity: 1,
      scheduleDays: null,
      userNotes: order.userNotes || null,
      assignedContainerTypeId: order?.container?.id || null
    }
    this.course = {
      dueDate: new Date().toISOString().substr(0, 10),
      fromTime: order.courses?.[0]?.timeSlot[0] || '8:00',
      leaveNotes: order.courses?.[0]?.userNotes || null,
      toTime: order.courses?.[0]?.timeSlot[1] || '18:00',
      userNotes: order.courses?.[0]?.userNotes || null,
    }
    this.payment = {
      debrisGrossValue: order.payment?.debrisGrossValue / 100 || null,
      debrisNetValue: order.payment?.debrisNetValue / 100 || null,
      discount: order.payment?.discount / 100 || null,
      discountJustification: order.payment?.discountJustification || null,
      paymentDueDays: order.payment?.paymentDueDays || null,
      paymentType: order.payment?.paymentType || 'Gotówka',
      settlementType: order.payment?.settlementType || 'Faktura',
      vatPercentage: order.payment?.vatPercentage || 8,
    }
  }
}

class Pickup {
  constructor(order = { courses: [] }) {
    this.course = {
      dueDate: new Date().toISOString().substr(0, 10),
      fromTime: order.courses?.[0]?.timeSlot[0] || '8:00',
      toTime: order.courses?.[0]?.timeSlot[1] || '18:00',
      userNotes: order.courses?.[0]?.userNotes || null
    }
    this.order = {
      addressId: order.address?.id || null,
      anyContainer: false,
      orderId: order.id || null,
      phoneNumber: order.phoneNumber || null,
      pickupContainerTypeId: null,
      quantity: 1,
      containerTypeId: order.containerType?.id || null,
      assignedContainerTypeId: order?.container?.id || null
    }
  }
}

export {
  Order,
  NewOrder,
  Items,
  PageOffset,
  Pagination,
  Table,
  Client,
  Address,
  Schedule,
  Incident,
  Invoice,
  SimplifiedOrder,
  Swap,
  Pickup
}
